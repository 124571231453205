<template>
    <v-app id="inspire">
    
    <vue-header-admin></vue-header-admin>
    <v-main>
        <v-container fluid class="px-6 py-6" >
            <v-row
                align="center"
                style="margin-bottom: 80px!important;"
                justify="center"
              >
                <v-col cols="12" md="12">
                  <v-card class="card-shadow border-radius-xl mt-2" id="basic">
                    <v-card-text style="margin-bottom: -30px;">
                      <v-row>
                        <v-col
                          cols="12"
                          md="12"
                        >
                        <h5 class="text-h4 font-weight-bold text-typo">Slideshow <span v-if="company.id">({{ company.project_name }})</span></h5>
                        </v-col>
                        <v-col
                          cols="12"
                        >
                        <!-- <div class="pb-5"><h3 class="pb-2.5 text-xl sm:text-2xl leading-8 font-light text-gray-400">
                          Tell us about your slideshow.
                        </h3> <p class="text-sm leading-6 font-light text-gray-500 mt-3">
                          Use the fields below to add or edit information for your slideshow.
                        </p></div> -->
                      </v-col>
                        <v-row style="padding:10px">
                        <v-col
                          cols="12"
                        >
                        <v-tabs
                            v-model="tab"
                            center-active
                            :vertical="$vuetify.breakpoint.mobile"
                            color="rgb(76, 175, 80)"
        
                            background-color="#FEF0DF"
                            >
                            <v-tab
                                :key="1"
                            >
                            Slideshow Detail
                            </v-tab>
                            <v-tab
                                :key="2"
                            >
                            Slideshow Files
                            </v-tab>
                            <v-tab
                                :key="3"
                            >
                            Conversions
                            </v-tab>
                            </v-tabs>
                            <v-tabs-items v-model="tab">
                            <v-tab-item :key="1" >
                            <v-row class="mt-3">
                              <v-col
                              cols="12"
                              md="6"
                              >
                              <h3 style="color:#DBA45F"><v-icon class="material-icons-round me-sm-1" style="color:#DBA45F" size="20">
                      movie
                    </v-icon>{{company.project_name }}</h3>
                    <v-divider class="mt-3 mb-3"></v-divider>
                    <v-row>
                      <v-col cols="12" md="4">
                      <p class="text-sm leading-6 font-light text-gray-500 mt-3"> Project Deadline </p>
                      </v-col>
                      <v-col cols="12" md="4">
                      <p class="text-sm leading-6 font-light text-gray-500 mt-3">{{ company.deadline ? company.deadline : "Not Specified" }}</p>
                      </v-col>
                      <v-col cols="12" md="12">
                        <v-divider style="margin-top: -15px;"></v-divider>
                      </v-col>
                      <v-col cols="12" md="4" style="margin-top: -15px;">
                      <p class="text-sm leading-6 font-light text-gray-500 mt-3"> Slideshow Title </p>
                      </v-col>
                      <v-col cols="12" md="4" style="margin-top: -15px;">
                      <p class="text-sm leading-6 font-light text-gray-500 mt-3"> {{company.title}} </p>
                      </v-col>
                      <v-col cols="12" md="12" >
                        <v-divider style="margin-top: -15px;"></v-divider>
                      </v-col>
                      <v-col cols="12" md="4" style="margin-top: -15px;">
                      <p class="text-sm leading-6 font-light text-gray-500 mt-3"> Slideshow Subtitle </p>
                      </v-col>
                      <v-col cols="12" md="4" style="margin-top: -15px;">
                      <p class="text-sm leading-6 font-light text-gray-500 mt-3"> {{company.sub_title ? company.sub_title : 'Blank'}} </p>
                      </v-col>
                      <v-col cols="12" md="12">
                        <v-divider style="margin-top: -15px;"></v-divider>
                      </v-col>
                      <v-col cols="12" md="4" style="margin-top: -15px;">
                      <p class="text-sm leading-6 font-light text-gray-500 mt-3"> Slideshow Closing text </p>
                      </v-col>
                      <v-col cols="12" md="4" style="margin-top: -15px;">
                      <p class="text-sm leading-6 font-light text-gray-500 mt-3"> {{company.closing_text ? company.closing_text : 'Blank'}} </p>
                      </v-col>
                      <v-col cols="12" md="12">
                        <v-divider style="margin-top: -15px;"></v-divider>
                      </v-col>
                      <v-col cols="12" md="4" style="margin-top: -15px;">
                      <p class="text-sm leading-6 font-light text-gray-500 mt-3"><v-icon class="material-icons-round me-sm-1" style="color:#DBA45F" size="20">
                      image
                    </v-icon> Slideshow Starter Pack </p>
                      </v-col>
                      <v-col cols="12" md="4" style="margin-top: -15px;">
                      <p class="text-sm leading-6 font-light text-gray-500 mt-3"> {{getCount('file') }} <span style="color: #d1d5db;margin-left:15px">  {{starter_qty}} included</span></p>
                      </v-col>
                      <v-col cols="12" md="4" style="margin-top: -15px;">
                      <p class="text-sm leading-6 font-light text-gray-500 mt-3">${{starter_price}}</p>
                      </v-col>
                      <v-col cols="12" md="12">
                        <v-divider style="margin-top: -15px;"></v-divider>
                      </v-col>
                      <v-col cols="12" md="4" style="margin-top: -15px;">
                      <p class="text-sm leading-6 font-light text-gray-500 mt-3"><v-icon class="material-icons-round me-sm-1" style="color:#DBA45F" size="20">
                        music_note
                    </v-icon> Songs </p>
                      </v-col>
                      <v-col cols="12" md="4" style="margin-top: -15px;">
                      <p class="text-sm leading-6 font-light text-gray-500 mt-3"> {{getCount('audio') }} <span style="color: #d1d5db;margin-left:15px">  ${{song_qty}} per song</span> </p>
                      </v-col>
                      <v-col cols="12" md="4" style="margin-top: -15px;">
                      <p class="text-sm leading-6 font-light text-gray-500 mt-3">${{song_price}}</p>
                      </v-col>
                      <v-col cols="12" md="12">
                        <v-divider style="margin-top: -15px;"></v-divider>
                      </v-col>
                      <v-col cols="12" md="6" style="margin-top: -15px;">
                      <h3 style="font-family: inherit;">Total </h3>
                      </v-col>
                      <v-col cols="12" md="6" style="margin-top: -15px;">
                      <h3 style="font-family: inherit;">${{song_price + starter_price}}</h3>
                      </v-col>


                      <v-col cols="12" md="6" style="">
                      <h3 style="font-family: inherit;">Current Status </h3>
                      </v-col>
                      <v-col cols="12" md="6" style="margin-top: -5px;">
                      <h3 style="font-family: inherit;"><v-select
                            outlined
                            v-model="company.status"
                            :items="['Edit','Submitted','Rejected','Completed']"
                            ></v-select></h3>
                      </v-col>
                      
                    </v-row>
                              </v-col>
                              <v-col
                                cols="12"
                                md="12"
                                class="mt-3"
                                >
    
                            <v-card-actions >
                              <v-row>
                                <v-col>
                                  <v-btn
                                    color="grey"
                                    large
                                    @click="$router.push('/admin/slideshows')"
                                    class="
                                      font-weight-bolder
                                      btn-default
                                      mb-5
                                      px-8
                                      mr-3
                                      ms-auto
                                    "
                                    
                                  >
                                   Back
                                  </v-btn>
                                  <v-btn
                                    color="#4CAF50"
                                    large
                                    @click="saveProfile(1)"
                                    class="
                                      font-weight-bolder
                                      btn-default
                                      mb-5
                                      mr-2
                                      px-8
                                      ms-auto
                                    "
                                    
                                  >
                                    Save
                                  </v-btn>
                                  <p class="text-sm leading-6 font-light text-gray-500 mt-3"> After submitting your slideshow, your files will be sent to our team and we'll begin working on your slideshow.</br> No payment is due until after you've previewed and approved your slideshow.  </p>
                                  </v-col>
                                  </v-row>
                                  </v-card-actions>
                                  </v-col>
                              </v-row>
                              
                            </v-tab-item>
                            <v-tab-item :key="2">
                                <v-row class="mt-3">
                              <v-col
                              cols="12"
                              md="12"
                              >
                              <v-btn
                                    color="grey"
                                    large
                                    outlined
                                    style="text-transform: none;"
                                    @click="dialogDetail = true"
                                    class="
                                      font-weight-bolder
                                      btn-default
                                      mb-5
                                      mr-2
                                      px-8
                                      ms-auto
                                    "
                                    
                                  >
                                   Add Files
                                  </v-btn>
                                  <v-btn
                                    color="grey"
                                    outlined
                                    style="text-transform: none;"
                                    large
                                    @click="dialogFileAudio= true"
                                    class="
                                      font-weight-bolder
                                      btn-default
                                      mb-5
                                      px-8
                                      ms-auto
                                    "
                                    
                                  >
                                   Add Song
                                  </v-btn>
                          
                              </v-col>
                              <v-col
                              cols="12"
                              md="12"
                              >
                              <v-divider></v-divider>
                              <div style="min-height: 4px;">
                              <v-progress-linear
                              class="mt-5"
                                v-model="value"
                                color="green lighten-2"
                                :active="show"
                                :indeterminate="query"
                                :query="true"
                              ></v-progress-linear>
                              </div>
                              <draggable v-model="company.files" class="v-tabs__container row mt-2" v-if="company.files.length > 0" @change="sortChange">
                                      <v-col
                                        v-for="(file,n ) in company.files"
                                        :key="n"
                                        class="d-flex child-flex"
                                        cols="12"
                                        md="2"
                                      >
                                      <v-card color="#E2ECF7" width="100">
                                        <v-img
                                          :src="file.file_extension == 'png' || file.file_extension == 'jpg' || file.file_extension == 'jpeg' || file.file_extension == 'gif' || file.file_extension == 'webp' ? image_url+'slideshow/'+file.file_name : file.type =='audio' ?  require('@/assets/img/default-audio.png') :  require('@/assets/img/default-video.png')"
                                          :lazy-src="file.file_extension == 'png' || file.file_extension == 'jpg' || file.file_extension == 'jpeg' || file.file_extension == 'gif' || file.file_extension == 'webp' ? image_url+'slideshow/'+file.file_name : file.type =='audio' ?  require('@/assets/img/default-audio.png') :  require('@/assets/img/default-video.png')"
                                          aspect-ratio="1"
                                          :style="'width: 100%;'"
                                          
                                          class="grey lighten-2 white--text align-end float-right"
                                        >
                                          <template v-slot:placeholder>
                                            <v-row
                                              class="fill-height ma-0"
                                              align="center"
                                              justify="center"
                                            >
                                              <v-progress-circular
                                                indeterminate
                                                color="grey lighten-5"
                                              ></v-progress-circular>
                                            </v-row>
                                          </template>
                                          <v-card-text style="color:#344767 !important;text-align: center;margin-bottom: -18px;font-family: inherit;" v-if="file.type == 'audio'">
                                            <b>{{ file.song_title }}</b>
                                          </v-card-text>
                                          <v-card-text style="color:white">
                                            {{n+1}}
                                          </v-card-text>
                                        </v-img>
                                        <v-card-actions>
                  <v-btn icon x-small @click="dialogPreviewFile = true,selectedFile = file">
                    <v-icon class="material-icons-round me-sm-1" size="20">
                      visibility
                    </v-icon>
                  </v-btn>
                  <v-btn icon x-small @click="downloadFile(file)" v-if="file.type !='audio'" >
                    <v-icon class="material-icons-round me-sm-1" size="20">
                      download
                    </v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
    
                  <v-btn v-if="file.file_extension == 'png' || file.file_extension == 'jpg' || file.file_extension == 'jpeg' || file.file_extension == 'gif' || file.file_extension == 'webp'" icon x-small @click="selectedFile = file,convertUrlToFile(image_url+'slideshow/'+selectedFile.file_name,selectedFile.file_extension)">
                    <v-icon class="material-icons-round me-sm-1" size="20">
                      edit
                    </v-icon>
                  </v-btn>
                  <v-btn v-if="file.type =='audio'" icon x-small @click="file_audio = file, dialogFileAudio = true;">
                    <v-icon class="material-icons-round me-sm-1" size="20">
                      edit
                    </v-icon>
                  </v-btn>
                  <v-btn icon x-small @click="removeItem(file.id)">
                    <v-icon class="material-icons-round me-sm-1" size="20">
                      delete
                    </v-icon>
                  </v-btn>
                  
    
                </v-card-actions>
                </v-card>
                                      </v-col>
                                    
                                </draggable>
                                <v-divider class="mt-5" v-if="company.files.length > 0"></v-divider >
                            </v-col>
                            <v-col
                                cols="12"
                                md="12"
                                >
    
                            <v-card-actions class="justify-center ">
                              <v-row>
                                <v-col>
                                  
                                  
                                  </v-col>
                                </v-row>
                            </v-card-actions>
                                </v-col>
                              </v-row>
                              </v-tab-item>
                              <v-tab-item :key="3">

                                <!-- <v-row class="no-gutters elevation-4">
                                    <v-col
                                    cols="12" sm="3"
                                    class="flex-grow-1 flex-shrink-0"
                                    style="border-right: 1px solid #0000001f;"
                                    >
                                    <v-responsive
                                        class="overflow-y-auto fill-height"
                                        height="500"
                                    >
                                        <v-list subheader>
                                        <v-list-item-group v-model="activeChat">
                                            <template v-for="(item, index) in parents">
                                            <v-list-item
                                                :key="`parent${index}`"
                                                :value="item.id"
                                            >
                                                <v-list-item-avatar color="grey lighten-1 white--text">
                                                <v-icon>
                                                    chat_bubble
                                                </v-icon>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                <v-list-item-title v-text="item.title" />
                                                <v-list-item-subtitle v-text="'hi'" />
                                                </v-list-item-content>
                                                <v-list-item-icon>
                                                <v-icon :color="item.active ? 'deep-purple accent-4' : 'grey'">
                                                    chat_bubble
                                                </v-icon>
                                                </v-list-item-icon>
                                            </v-list-item>
                                            <v-divider
                                                :key="`chatDivider${index}`"
                                                class="my-0"
                                            />
                                            </template>
                                        </v-list-item-group>
                                        </v-list>
                                    </v-responsive>
                                    </v-col>
                                    <v-col
                                    cols="auto"
                                    class="flex-grow-1 flex-shrink-0"
                                    >
                                    <v-responsive
                                        class="overflow-y-hidden fill-height"
                                        height="500"
                                    >
                                        <v-card
                                        flat
                                        class="d-flex flex-column fill-height"
                                        >
                                        <v-card-title>
                                            john doe
                                        </v-card-title>
                                        <v-card-text class="flex-grow-1 overflow-y-auto">
                                            <template v-for="(msg, i) in conversations">
                                            <div
                                                :class="{ 'd-flex flex-row-reverse': msg.user_id != company.client_id }"
                                            >
                                               
                                            <v-card
                                            :color="msg.user_id != company.client_id ? 'blue' : ''"
                                                dark
                                                max-width="400"
                                            >
                                                <v-card-title>
                                                <v-icon
                                                    large
                                                    left
                                                >
                                                    mdi-twitter
                                                </v-icon>
                                                <span class="text-h6 font-weight-light">{{msg.subject}}</span>
                                                </v-card-title>

                                                <v-card-text class="text-h5 font-weight-bold">
                                                {{msg.body}}
                                                </v-card-text>
                                                <v-card-actions style="display: block;text-align:right">
                                                    <sub class="float-right"
                                  style="font-size: .7rem;">{{ getDateTime(msg.created_at) }}</sub>
                                                    </v-card-actions>
                                                </v-card>
                                                
                                            </div>
                                            </template>
                                        </v-card-text>
                                        <v-card-text class="flex-shrink-1">
                                            <v-text-field
                                            v-model="messageForm.content"
                                            label="type_a_message"
                                            type="text"
                                            no-details
                                            outlined
                                            append-outer-icon="fa fa-times"
                                            @keyup.enter="messages.push(messageForm)"
                                            @click:append-outer="messages.push(messageForm)"
                                            hide-details
                                            dence
                                            />
                                        </v-card-text>
                                        </v-card>
                                    </v-responsive>
                                    </v-col>
                                </v-row> -->



                                <v-row class="mt-3">
                              <v-col
                              cols="12"
                              md="12"
                              >


                              <div style="height:500px; overflow:scroll" v-if="conversations.length != 0">
                        <!-- <v-timeline
                              align-top
                              class="mt-5"
                              
                              :dense="$vuetify.breakpoint.smAndDown"
                            >
                            <v-timeline-item
                                    v-for="item in conversations"
                                    :key="item"
                                    small
                                  >
                                    <template v-slot:icon>
                                        <v-icon class="material-icons-round" v-if="item.conversation_type =='email'">
                              mail
                            </v-icon><v-icon class="material-icons-round" v-if="item.conversation_type =='sms'">
                              sms
                            </v-icon>
                                    </template>
                                    <template v-slot:opposite>
                                      <span><strong>{{item.first_name}} {{ item.last_name }}</strong></span>
                                      <p style="font-size : 11px;">{{ getDateTime(item.created_at) }}</p>
                                    </template>
                                    <v-card class="elevation-2">
                                      <v-card-title class="text-h5">
                                        {{item.conversation_type =='sms' ? 'SMS' : item.subject}}
                                      </v-card-title>
                                      <v-card-text>{{item.body}}</v-card-text>
                                    </v-card>
                                  </v-timeline-item>
                                </v-timeline> -->
                                <v-responsive
                                        class="overflow-y-hidden fill-height"
                                        height="500"
                                    >
                                        <v-card
                                        flat
                                        class="d-flex flex-column fill-height"
                                        >
                                        <v-card-text class="flex-grow-1 overflow-y-auto">
                                            <template v-for="(msg, i) in conversations">
                                            <div
                                            style="margin-top:10px;" 
                                                :class="msg.user_id == company.client_id ? 'd-flex flex-row-reverse' : 'd-flex' "
                                            >
                                               
                                            <v-card
                                            :color="msg.user_id != company.client_id ? 'blue' : '#555555'"
                                                dark
                                                max-width="400"
                                            >
                                                <v-card-title>
                                                <!-- <v-icon
                                                    large
                                                    left
                                                >
                                                    mdi-twitter
                                                </v-icon> -->
                                                <span class="text-h6 font-weight-light">{{msg.subject}}</span>
                                                </v-card-title>

                                                <v-card-text class="text-h5 font-weight-bold">
                                                {{msg.body}}
                                                </v-card-text>
                                                <v-card-actions style="display: block;text-align:right">
                                                    <sub class="float-right mb-5"
                                  style="font-size: .7rem;">{{ getDateTime(msg.created_at) }}</sub>
                                                    </v-card-actions>
                                                </v-card>
                                                
                                            </div>
                                            </template>
                                        </v-card-text>
                                        </v-card>
                                    </v-responsive>
                     
                      </div>
                      <div v-else>
                        <div class="text-h6 text-typo font-weight-bold" style="padding:50px;text-align:center!important;"> You're starting a new conversation</div>
                      </div>
                    
                          <div class="mt-5">
                            <v-text-field
                        label="Subject"
                        v-model="conversation.subject"
                        filled

                      ></v-text-field>
                      <v-textarea
                                    filled
                                    v-model="conversation.body"
                                     rows="9"
                                     class="mt-5"
                                     placeholder="Body"
                                  ></v-textarea>
                                  
                                                  <v-btn
                                                    depressed
                                                    :loading="loadingChat"
                                                    style="text-transform: none;"
                                                    color="#FFD221"
                                                    class="
                                                      font-weight-normal
                                                      text-capitalize
                                                      ms-auto
                                                      btn-primary
                                                      float-right
                                                      py-3
                                                      px-6
                                                      ms-3
                                                    "
                                                    @click="sendConversation('email')"
                                                  >
                                                    SEND
                                                  </v-btn>
                                                </div>
                                                
                              </v-col>
                              </v-row>
                              </v-tab-item>
                           </v-tabs-items>
                        </v-col>
                        </v-row>
                        
    
                        
                      </v-row>
                    </v-card-text>
                    <!-- <v-card-actions class="justify-center ">
                      <v-btn
                        tile  dark
                        style="height: 49px;width:95px;"
                        color="primary"
                        class="mb-10"
                        @click="saveProfile"
                      >Save</v-btn>
                    </v-card-actions> -->
                  </v-card>
                </v-col>
              </v-row>
        </v-container>
        <v-dialog
              persistent
            v-model="dialogDetail"
            width="700"
          >
      
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                Upload Files
              </v-card-title>
      
              <v-card-text style="margin-top:30px;"> 
                
        <v-card
          @drop.prevent="onDrop($event)"
          @dragover.prevent="dragover = true"
          @dragenter.prevent="dragover = true"
          @dragleave.prevent="dragover = false"
          :class="{ 'grey lighten-2': dragover }"
        >
          <v-card-text>
            <v-row class="d-flex flex-column" dense align="center" justify="center">
              <v-icon :class="[dragover ? 'mt-2, mb-6' : 'mt-5']" size="60">
                fa-cloud-upload-alt
              </v-icon>
              <p class="mt-5">
                Drop your file(s) here
              </p>
              <p>
                Or
              </p>
              <v-btn
                color="blue"
                large
                @click="onButtonClickProof()"
                class="
                  font-weight-bolder
                  btn-default
                "
                
              >
                Browse
              </v-btn>
              <input
                  :ref="'file_proof'"
                  class="d-none"
                  type="file"
                  accept="image/*, video/*"
                  @change="onFileChangedProof"
                >
            </v-row>
            <v-virtual-scroll
              v-if="uploadedFiles.length > 0"
              :items="uploadedFiles"
              height="150"
              item-height="50"
            >
              <template v-slot:default="{ item }">
                <v-list-item :key="item.name">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.name }}
                      <span class="ml-3 text--secondary">
                        {{ item.size }} bytes</span
                      >
                    </v-list-item-title>
                  </v-list-item-content>
    
                  <v-list-item-action>
                    <v-btn @click.stop="removeFile(item.name)" icon>
                      <v-icon style="color: red;">fas fa-times </v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
    
                <v-divider></v-divider>
              </template>
            </v-virtual-scroll>
          </v-card-text>
        </v-card>
    
    
              </v-card-text>
              
              <v-divider></v-divider>
      
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue"
                  text
                  :loading="loadingUpload"
                  @click="submit()"
                >
                  upload
                </v-btn>
                 <v-btn
                  color="primary"
                  text
                  @click="dialogDetail = false"
                >
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
              persistent
            v-model="dialogFileAudio"
            width="700"
          >
      
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                Add song
              </v-card-title>
      
              <v-card-text style="margin-top:30px;"> 
                <v-text-field
                                id="name"
                                name="name"
                                label="Title"
                                class="font-size-input input-style"
                                width="300px"
                                ref="name"
                                placeholder="What is the name of the song?"
                                v-model="file_audio.song_title"
                                />
                                <v-text-field
                                id="name"
                                name="name"
                                label="Artist"
                                class="font-size-input input-style"
                                width="300px"
                                ref="name"
                                placeholder="Who performed it?"
                                v-model="file_audio.song_artist"
                                />
                </v-card-text>
                <v-divider></v-divider>
      
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue"
                    text
                    :loading="loadingUpload"
                    @click="submitAudio()"
                  >
                    Add
                  </v-btn>
                  <v-btn
                    color="primary"
                    text
                    @click="dialogFileAudio = false"
                  >
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
              </v-dialog>
          <avatar-cropper
                        :cropper-options="{autoCropArea: 1,aspectRatio: NaN,}"
                        v-model="showCropper"
                        :file="selectedFileRaw"
                        :upload-handler="handleUploaded"
                      />
          <v-dialog
              v-model="dialogPreviewFile"
              width="1200"
            >
              <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                  Preview
                </v-card-title>
                <div  class="mt-5 mb-5">
                 <v-card-text >
                  <v-row>
                    <v-col cols="12">
                      
                  <v-img
                      v-if="selectedFile.file_extension == 'png' || selectedFile.file_extension == 'jpg' || selectedFile.file_extension == 'jpeg' || selectedFile.file_extension == 'gif' || selectedFile.file_extension == 'webp'"
                          :src="image_url+'slideshow/'+selectedFile.file_name"
                          :lazy-src="image_url+'slideshow/'+selectedFile.file_name"
                          :style="'width: 100%;height:500px'"
                          
                          class="grey lighten-2 white--text align-end float-right"
                        >
                                          <template v-slot:placeholder>
                                            <v-row
                                              class="fill-height ma-0"
                                              align="center"
                                              justify="center"
                                            >
                                              <v-progress-circular
                                                indeterminate
                                                color="grey lighten-5"
                                              ></v-progress-circular>
                                            </v-row>
                                          </template>
                                      
                                        </v-img>
                                        <video v-else ref="videoRef" :src="image_url+'slideshow/'+selectedFile.file_name" id="video-container" width="100%" controls></video>
                                        </v-col>
                                        </v-row>
                </v-card-text>
                </div>
                <v-divider></v-divider>
    
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    @click="dialogPreviewFile = false"
                  >
                    close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
         </v-main>
    </v-app>
    
    </template>
    
    <script>
    import { VueTelInput } from 'vue-tel-input'
    import draggable from 'vuedraggable'
    import AvatarCropper from 'vue-avatar-cropper'
    import moment from 'moment'
    export default {
    components: {
          VueTelInput,draggable,AvatarCropper
      },
      data () {
    
          return {
            fname : '',
            lname : '',
            email : '',
            name:'',
            e1 : 3,
            phone:'',
            loading : false,
            password : '',
            is_admin:0,
            showCropper: false,
            dialogFileAudio: false,
            confpassword : '',
            dialogDetail: false,
            selectedFile : '',
            role : '',
            value: 0,
            query: false,
            parents: [
                {
                    id: 1,
                    title: "john doe",
                    active: true
                },
                {
                    id: 3,
                    title: "scarlett",
                    active: false
                },
                {
                    id: 4,
                    title: "scarlett",
                    active: false
                },
                {
                    id: 5,
                    title: "scarlett",
                    active: false
                },
                {
                    id: 6,
                    title: "scarlett",
                    active: false
                },
                {
                    id: 7,
                    title: "scarlett",
                    active: false
                },
                {
                    id: 8,
                    title: "scarlett",
                    active: false
                },
                {
                    id: 9,
                    title: "scarlett",
                    active: false
                },
                {
                    id: 10,
                    title: "scarlett",
                    active: false
                },
                {
                    id: 11,
                    title: "scarlett",
                    active: false
                },
                {
                    id: 12,
                    title: "scarlett",
                    active: false
                },
                {
                    id: 13,
                    title: "scarlett",
                    active: false
                },
                {
                    id: 14,
                    title: "scarlett",
                    active: false
                }
                ],
                messages: [
                {
                    content: "lorem ipsum",
                    me: true,
                    created_at: "11:11am"
                },
                {
                    content: "dolor",
                    me: false,
                    created_at: "11:11am"
                },
                {
                    content: "dolor",
                    me: false,
                    created_at: "11:11am"
                },
                {
                    content: "dolor",
                    me: false,
                    created_at: "11:11am"
                },
            ],
            messageForm: {
            content: "",
            me: true,
            created_at: "11:11am"
            },
            show: true,
            interval: 0,
            valid : true,
            companyname : '',
            sector : '',
            menu : false,
            no_of_employee : '',
            showPassword : false,
            phoneFlag:0,
            emailFlag:0,
            country :'',
            dialogPreviewFile: false,
            otp_email:'',
            image_url :process.env.VUE_APP_WASBI_URL,
            otp_phone:'',
            dragover: false,
            uploadedFiles: [],
            loadingUpload : false,
            stateOptions: [
                    { name: 'ALABAMA', abbreviation: 'AL'},
                    { name: 'ALASKA', abbreviation: 'AK'},
                    { name: 'AMERICAN SAMOA', abbreviation: 'AS'},
                    { name: 'ARIZONA', abbreviation: 'AZ'},
                    { name: 'ARKANSAS', abbreviation: 'AR'},
                    { name: 'CALIFORNIA', abbreviation: 'CA'},
                    { name: 'COLORADO', abbreviation: 'CO'},
                    { name: 'CONNECTICUT', abbreviation: 'CT'},
                    { name: 'DELAWARE', abbreviation: 'DE'},
                    { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC'},
                    { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM'},
                    { name: 'FLORIDA', abbreviation: 'FL'},
                    { name: 'GEORGIA', abbreviation: 'GA'},
                    { name: 'GUAM', abbreviation: 'GU'},
                    { name: 'HAWAII', abbreviation: 'HI'},
                    { name: 'IDAHO', abbreviation: 'ID'},
                    { name: 'ILLINOIS', abbreviation: 'IL'},
                    { name: 'INDIANA', abbreviation: 'IN'},
                    { name: 'IOWA', abbreviation: 'IA'},
                    { name: 'KANSAS', abbreviation: 'KS'},
                    { name: 'KENTUCKY', abbreviation: 'KY'},
                    { name: 'LOUISIANA', abbreviation: 'LA'},
                    { name: 'MAINE', abbreviation: 'ME'},
                    { name: 'MARSHALL ISLANDS', abbreviation: 'MH'},
                    { name: 'MARYLAND', abbreviation: 'MD'},
                    { name: 'MASSACHUSETTS', abbreviation: 'MA'},
                    { name: 'MICHIGAN', abbreviation: 'MI'},
                    { name: 'MINNESOTA', abbreviation: 'MN'},
                    { name: 'MISSISSIPPI', abbreviation: 'MS'},
                    { name: 'MISSOURI', abbreviation: 'MO'},
                    { name: 'MONTANA', abbreviation: 'MT'},
                    { name: 'NEBRASKA', abbreviation: 'NE'},
                    { name: 'NEVADA', abbreviation: 'NV'},
                    { name: 'NEW HAMPSHIRE', abbreviation: 'NH'},
                    { name: 'NEW JERSEY', abbreviation: 'NJ'},
                    { name: 'NEW MEXICO', abbreviation: 'NM'},
                    { name: 'NEW YORK', abbreviation: 'NY'},
                    { name: 'NORTH CAROLINA', abbreviation: 'NC'},
                    { name: 'NORTH DAKOTA', abbreviation: 'ND'},
                    { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP'},
                    { name: 'OHIO', abbreviation: 'OH'},
                    { name: 'OKLAHOMA', abbreviation: 'OK'},
                    { name: 'OREGON', abbreviation: 'OR'},
                    { name: 'PALAU', abbreviation: 'PW'},
                    { name: 'PENNSYLVANIA', abbreviation: 'PA'},
                    { name: 'PUERTO RICO', abbreviation: 'PR'},
                    { name: 'RHODE ISLAND', abbreviation: 'RI'},
                    { name: 'SOUTH CAROLINA', abbreviation: 'SC'},
                    { name: 'SOUTH DAKOTA', abbreviation: 'SD'},
                    { name: 'TENNESSEE', abbreviation: 'TN'},
                    { name: 'TEXAS', abbreviation: 'TX'},
                    { name: 'UTAH', abbreviation: 'UT'},
                    { name: 'VERMONT', abbreviation: 'VT'},
                    { name: 'VIRGIN ISLANDS', abbreviation: 'VI'},
                    { name: 'VIRGINIA', abbreviation: 'VA'},
                    { name: 'WASHINGTON', abbreviation: 'WA'},
                    { name: 'WEST VIRGINIA', abbreviation: 'WV'},
                    { name: 'WISCONSIN', abbreviation: 'WI'},
                    { name: 'WYOMING', abbreviation: 'WY' }
                ],
            company : {
              id : '',
              project_name: "",
              deadline:  "",
              status : "",
              title : "",
              sub_title : "",
              closing_text : "",
              files :[],
    
            },
            otp_phone_input:"",
            otp_email_input:"",
            user_phone:'',
            selectedFileRaw: '',
            settings: [],
            cropperOutputMime : '',
            tab : 1,
            starter_price : 0,
            starter_qty :0,
            song_qty : 0,
            song_price : 0,
            user_email:'',
            conversations: [],
            conversation : {
            sender : '',
            receiver_user_id : '',
            receiver : '',
            subject : '',
            body : '',
            type : ''
            },
            status:0,
            file_audio :{
              song_artist : "",
              song_title : "",
              id :"",
            },
    
            tab: null,
            headers: [
    
              { text: '', value: 'CompanyURL' },
              { text: '', value: 'CompanyName' },
    
            ],
            history: [
    
            ],
    
            setting: [
            ],
    
            items: [
                  'Admin',
                  'Viewer',
          ],
          select_role : 'Admin',
           sectors: [
    
          ],
          employeeNumber: [
                  '10-50',
                  '50-100',
                  'More than 100',
    
          ],
    
          }
        },
      watch: {
                // company: {
                //     handler () {
                //         this.company.company_url = this.company.name.replace(/\s/g, '-').toLowerCase()
                //     },
                //     deep: true,
                // },
            },
        methods: {
          getCount(type){
            let count_file = 0;
            let count_audio = 0;
            
            this.company.files.forEach(element => {
              if(element.type !='audio'){
                count_file = count_file +1;
              }else{
                count_audio = count_audio  +1;
              }
            });
            
            if(type == 'audio')
              return count_audio;
            else
              return count_file;
          },
          queryAndIndeterminate () {
            this.query = true
            this.show = true
            this.value = 0
    
            setTimeout(() => {
              this.query = false
    
              this.interval = setInterval(() => {
                this.value += 10
              }, 1000)
            }, 2500)
          },
          getGHLChat(){
            axios({
                method: "POST",
                url: "/api/get-ghl-conversation",
                data : {client_id : this.company.client_id,ref_id : this.company.id},
                headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
                })
                .then(response => {
                    let data = response.data.data;
                    if(data){
                    this.conversations = data;
                    }
                    })
            },
            getDateTime(date){
            return moment(date).format('LLLL');
            },
          sendConversation(flag){
            this.conversation.type = flag;
            this.conversation.receiver_user_id = this.company.client_id;
            this.conversation.ref_id = this.company.id;
            let self = this;
            if(!this.conversation.body){
                    Vue.$toast.error("Message is required! ", {
                    position: 'top-right'
                    });
                }else{
            self.loadingChat = true;
            let header = { headers : {
                        Authorization:"Bearer " + localStorage.getItem('token')
                        }
                } 
            axios
                .post("/api/send-conversation",this.conversation,header)
                .then(response => {
                self.loadingChat = false;
                self.getGHLChat();
                Vue.$toast.success('Message Sent', {
                        position: 'top-right'
                        });
                        this. conversation =  {
                            sender : '',
                            receiver_user_id : '',
                            receiver : '',
                            subject : '',
                            body : '',
                            type : ''
                            }
                })
                .catch(err => {
                self.loadingChat = false;
                    Vue.$toast.error('Unable to send', {
                    position: 'top-right'
                    });
                })
            }
            },
          getSettings() {
            let flag = 1;
            let url = process.env.VUE_APP_WASBI_URL
            var config = {headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache'}};
            axios({
                method: "POST",
                url: "/api/get-company-settings",
                headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
                })
                .then(response => {
                    let data = response.data.data;
                    if(data){
                        this.settings = data.settings
                    localStorage.setItem('settings', JSON.stringify(data.settings));
                    localStorage.setItem('admin_bg', JSON.stringify(url+data.settings.backgroung_logo));
                    if(this.$route.params.id)
                      this.getUserProfile(this.$route.params.id);
                    }


                })
                .catch(err => {
                    // Vue.$toast.error(err, {
                    // position: 'top-right'
                    // });

                });

            },
          loaderOff(){
                  clearInterval(this.interval)
                  this.show = false
          },
          onButtonClickProof(id) {
            this.$refs['file_proof'].click()
          },
          removeItem(id){
            var r = confirm("Are you sure, You went to delete?");
                if (r == true) {
                  this.queryAndIndeterminate()
                  axios
                    .get("/api/delete-slideshow-file/"+id,this.header)
                    .then(response => {
                        let data = response.data;
      
                    if (response.data) {
                        Vue.$toast.success('Deleted successfully', {
                            position: 'top-right'
                            });
                          this.getUserProfile(this.$route.params.id);
                        }else {
                            Vue.$toast.error(data.message, {
                            position: 'top-right'
                            });
                        }
      
                    })
                  .catch(err => {
                    Vue.$toast.error(err, {
                    position: 'top-right'
                    });
                  })
                }
          },
          removeFile(fileName) {
          // Find the index of the
          const index = this.uploadedFiles.findIndex(
            file => file.name === fileName
          );
          // If file is in uploaded files remove it
          if (index > -1) this.uploadedFiles.splice(index, 1);
        },
        async convertUrlToFile(imageUrl, fileExt) {
          try {
            const response = await fetch(imageUrl);
            const blob = await response.blob();
            const file = new File([blob], 'image.'+fileExt, { type: blob.type });
            // Now you have the 'file' object that represents the image file
            this.selectedFileRaw = file;
            this.showCropper = true
          } catch (error) {
            console.error('Error converting URL to File:', error);
          }
        },
        onFileChangedProof(e) {
          e.target.files.forEach(element =>
              this.uploadedFiles.push(element)
            );
        },
        onDrop(e) {
          this.dragover = false;
          // If there are already uploaded files remove them
          if (this.uploadedFiles.length > 0) this.uploadedFiles = [];
          // If user has uploaded multiple files but the component is not multiple throw error
          if (!this.multiple && e.dataTransfer.files.length > 1) {
            this.$store.dispatch("addNotification", {
              message: "Only one file can be uploaded at a time..",
              colour: "error"
            });
          }
          // Add each file to the array of uploaded files
          else
            e.dataTransfer.files.forEach(element =>
              this.uploadedFiles.push(element)
            );
        },
        submit(editFlag = 0,editFlagId='') {
          // If there aren't any files to be uploaded throw error
          if (!this.uploadedFiles.length > 0) {
            Vue.$toast.error('There are no files to upload', {
              position: 'top-right'
          });
          } else {
            let header = { headers : {
                    Authorization:"Bearer " + localStorage.getItem('token')
                    }
            }
            // Send uploaded files to parent component
            // this.$emit("filesUploaded", this.uploadedFiles);
            const formData = new FormData();
            formData.append('type', 'file');
            formData.append('edit_flag', editFlag);
            formData.append('edit_flag_id', editFlagId);
              // Append each file to the form data
              for (let i = 0; i < this.uploadedFiles.length; i++) {
                formData.append('files[]', this.uploadedFiles[i]);
              }
              this.loadingUpload = true;
              this.queryAndIndeterminate()
              axios
              .post("/api/upload-slideshow-files/"+this.company.id,formData,header)
              .then(response => {
                  let data = response.data;
    
                if (data.flag == 1) {
                    
                  this.dialogDetail = false;
                  this.uploadedFiles = [];
                  this.getUserProfile(this.$route.params.id)
                  this.loadingUpload = false;
                  }else {
                    console.log(data.response);
                      // Vue.$toast.error(data.message, {
                      // position: 'top-right'
                      // });
                  }
    
              })
                .catch(err => {
                  if(err.response.data.error == 'validation.mimetypes')
                    Vue.$toast.error('Invalid file type', {
                    position: 'top-right'
                    });
                  else
                    Vue.$toast.error('Unable to upload', {
                      position: 'top-right'
                      });
    
                });
            // Close the dialog box
          }
        },
        downloadFile(file){
        let self = this;
        window.open(this.image_url+'slideshow/'+file.file_name, '_blank');
        },
        submitAudio(editFlag = 0,editFlagId='') {
          if(this.file_audio.id){
            editFlag = 1;
            editFlagId = this.file_audio.id;
          }
          // If there aren't any files to be uploaded throw error
          if (!this.file_audio.song_title) {
            Vue.$toast.error('Title is required', {
              position: 'top-right'
          });
          }else if(!this.file_audio.song_artist){
            Vue.$toast.error('Artist is required', {
              position: 'top-right'
          });
          } else {
            let header = { headers : {
                    Authorization:"Bearer " + localStorage.getItem('token')
                    }
            }
            // Send uploaded files to parent component
            // this.$emit("filesUploaded", this.uploadedFiles);
            const formData = new FormData();
            formData.append('type', 'audio');
            formData.append('edit_flag', editFlag);
            formData.append('edit_flag_id', editFlagId);
            formData.append('song_title', this.file_audio.song_title);
            formData.append('song_artist', this.file_audio.song_artist);
              // Append each file to the form data
              this.loadingUpload = true;
              this.queryAndIndeterminate()
              axios
              .post("/api/upload-slideshow-files-audio/"+this.company.id,formData,header)
              .then(response => {
                  let data = response.data;
    
                if (data.flag == 1) {
                    
                  this.dialogFileAudio = false;
                  this.uploadedFiles = [];
                  this.getUserProfile(this.$route.params.id)
                  this.loadingUpload = false;
                  }else {
                    console.log(data.response);
                      // Vue.$toast.error(data.message, {
                      // position: 'top-right'
                      // });
                  }
    
              })
                .catch(err => {
                  if(err.response.data.error == 'validation.mimetypes')
                    Vue.$toast.error('Invalid file type', {
                    position: 'top-right'
                    });
                  else
                    Vue.$toast.error('Unable to upload', {
                      position: 'top-right'
                      });
    
                });
            // Close the dialog box
          }
        },
        dataURLtoFile(dataurl, filename) {
     
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        
        return new File([u8arr], filename, {type:mime});
        },
        handleUploaded(cropper) {
              let image = cropper.getCroppedCanvas().toDataURL(this.cropperOutputMime);
              let img = this.dataURLtoFile(image,this.selectedFile.file_name);
              this.uploadedFiles.push(img);
              this.submit(1,this.selectedFile.id);
              this.showCropper = false;
    
            },
        addPhone(){
            this.phoneFlag = 1;
        },
        addEmail(){
            this.emailFlag = 1;
        },
        countryChanged(country) {
            this.country = '+' + country.dialCode
        },
    
    
        add: function () {
             // for setting tab
        },
    
        getUserProfile(id) {
        let flag = 1;
        let header = { headers : {
                    Authorization:"Bearer " + localStorage.getItem('token')
                    }
            }
            this.queryAndIndeterminate();
          axios
            .get("/api/get-slideshow/"+id,header)
            .then(response => {
                let data = response.data;
    
               if (flag == 1) {
                  this.company = data.data;
                  let count_file = 0;
                  let count_audio = 0;
                  this.company.files.forEach(element => {
                    if(element.type !='audio'){
                        count_file = count_file +1;
                    }else{
                        count_audio = count_audio  +1;
                    }
                    });
                    const dynamicThreshold =  Math.ceil(count_file / Number(this.settings.plan_1_quantity)) == 0 ? 1 : Math.ceil(count_file / Number(this.settings.plan_1_quantity));
                    this.starter_price =  dynamicThreshold * this.settings.plan_1_price;
                    this.starter_qty= dynamicThreshold == 1 ? '('+this.settings.plan_1_quantity+')' :'('+this.settings.plan_1_quantity+'x'+dynamicThreshold+')';
                    const dynamicThresholdSong =  Math.ceil(count_audio / Number(this.settings.song_quantity)) == 0 ? 1 : Math.ceil(count_audio / Number(this.settings.song_quantity));
                    this.song_price =  dynamicThresholdSong * this.settings.song_price;
                    this.song_qty= dynamicThresholdSong == 1 ? ''+this.settings.song_quantity+'' :'('+this.settings.song_quantity+'x'+dynamicThresholdSong+')';
                  this.loaderOff();
                  this.getGHLChat()
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }
    
             })
              .catch(err => {
                Vue.$toast.error(err, {
                position: 'top-right'
                });
    
               });
    
         },
         sortChange(){
          let header = { headers : {
                    Authorization:"Bearer " + localStorage.getItem('token')
                    }
            }
          axios
            .post("/api/change-slideshow-order/",this.company.files,header)
            .then(response => {
                let data = response.data;
    
               if (data.flag == 1) {
                  this.getUserProfile(this.$route.params.id)
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }
    
             })
              .catch(err => {
                Vue.$toast.error(err, {
                position: 'top-right'
                });
    
               });
         },
    
    
    
        saveProfile: function(submit=0) {
          let self = this;
          let flag = 1;
          let header = { headers : {
                    Authorization:"Bearer " + localStorage.getItem('token')
                    }
            }
    
            if (flag) {
              self.loading = true;
              
            axios({
              method: "POST",
             headers : {
                    Authorization:"Bearer " + localStorage.getItem('token')
                    },
               
              url: "/api/save-slideshow",
              data: self.company
            })
              .then(response => {
                let data = response.data.data;
                if (response.data) {
                      
                      self.loading = false;
                      if(!this.company.id){
                        this.$router.push('/add-slideshow/'+data.id)
                      }else if(submit == 1){
                        Vue.$toast.success('Status Saved', {
                        position: 'top-right'
                      })
                      }else
                        this.e1 = 2;
    
                } else {
                    Vue.$toast.error('Failed To Add', {
                    position: 'top-right'
                    });
                    self.loading = false;
                }
              })
              .catch(err => {
                console.log(err.response);
                
                  if(err.response.data.errors.email)
                    Vue.$toast.error(err.response.data.errors.email[0], {
                    position: 'top-right'
                    });
                  if(err.response.data.errors.user_name)
                    Vue.$toast.error(err.response.data.errors.user_name[0], {
                    position: 'top-right'
                    });     
                  if(err.response.data.errors.company_url)
                    Vue.$toast.error(err.response.data.errors.company_url[0], {
                    position: 'top-right'
                    });             
              });
            }
        },
    
     },
    
    created() {
          console.log('created called.');
          this.getSettings();
          
        },
    
    }
    </script>
    
    <style>
    .v-stepper__step__step{
      background: #4CAF50;
    }
    .stepper-slide  {
      box-shadow: none!important;
    }
    .text-gray-400 {
      --tw-text-opacity: 1;
      color: #9ca3af;
      font-family: inherit;
      font-size: 1.5rem;
      color: rgba(156,163,175,var(--tw-text-opacity));
    }
    .v-tabs-slider-wrapper
    {
      height: 46px!important;
    }
    </style>