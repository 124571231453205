<template>
  <v-app id="inspire">
  
  <vue-header-admin></vue-header-admin>
  <v-main>
      <v-container fluid class="px-6 py-6" >
          <v-row
              align="center"
              style="margin-bottom: 80px!important;"
              justify="center"
            >
              <v-col cols="12" md="12">
                <v-card class="card-shadow border-radius-xl mt-2" id="basic">
                  <v-card-text style="margin-bottom: -30px;">
                    <v-row>
                      <v-col
                        cols="12"
                        md="12"
                      >
                      <h5 class="text-h4 font-weight-bold text-typo">Conversations <span v-if="company.id">({{ company.project_name }})</span></h5>
                      </v-col>
                      <v-col
                        cols="12"
                      >
                      <!-- <div class="pb-5"><h3 class="pb-2.5 text-xl sm:text-2xl leading-8 font-light text-gray-400">
                        Tell us about your slideshow.
                      </h3> <p class="text-sm leading-6 font-light text-gray-500 mt-3">
                        Use the fields below to add or edit information for your slideshow.
                      </p></div> -->
                    </v-col>
                      <v-row style="padding:10px">
                      <v-col
                        cols="12"
                      >

                              <v-row class="no-gutters elevation-4">
                                  <v-col
                                  cols="12" sm="3"
                                  class="flex-grow-1 flex-shrink-0"
                                  style="border-right: 1px solid #0000001f;"
                                  >
                                  <v-responsive
                                      class="overflow-y-auto fill-height"
                                      height="1200"
                                  >
                                      <v-list subheader>
                                      <v-list-item-group v-model="activeChat">
                                          <template v-for="(item, index) in conversations">
                                          <v-list-item
                                              :key="`parent${index}`"
                                              :value="item.id"
                                              @click="renderChat(item, index)"
                                          >
                                              <v-list-item-avatar color="grey lighten-1 white--text">
                                              <v-icon>
                                                far fa-comment-alt
                                              </v-icon>
                                              </v-list-item-avatar>
                                              <v-list-item-content>
                                              <v-list-item-title v-text="index" />
                                              <v-list-item-subtitle v-text="" />
                                              </v-list-item-content>
                                              <v-list-item-icon v-if="item.count">
                                                <v-badge
                                                inline
                                                  color="green"
                                                  :content="item.count"
                                                >
                                                </v-badge>
                                              </v-list-item-icon>
                                          </v-list-item>
                                          <v-divider
                                              :key="`chatDivider${index}`"
                                              class="my-0"
                                          />
                                          </template>
                                      </v-list-item-group>
                                      </v-list>
                                  </v-responsive>
                                  </v-col>
                                  <v-col
                                  cols="auto"
                                  class="flex-grow-1 flex-shrink-0"
                                  >
                                  <v-responsive
                                    v-if="selectedProject"
                                      class="overflow-y-hidden fill-height"
                                      height="1200"
                                  >
                                      <v-card
                                      flat
                                      class="d-flex flex-column fill-height"
                                      >
                                      <v-card-title>
                                          Customer Support
                                      </v-card-title>
                                      <v-card-text class="flex-grow-1 overflow-y-auto">
                                          <template v-for="(msg, i) in selectedProjectItem.items">
                                          <div
                                          style="margin-top:10px;" 
                                              :class="msg.user_id == client_id ? 'd-flex flex-row-reverse' : 'd-flex' "
                                          >
                                          <v-card
                                          :color="msg.user_id == client_id ? 'blue' : '#555555'"
                                              dark
                                              max-width="400"
                                          >
                                              <v-card-title>
                                              <span class="text-h6 font-weight-light">{{msg.subject}}</span>
                                              </v-card-title>

                                              <v-card-text class="text-h5 font-weight-bold">
                                              {{msg.body}}
                                              </v-card-text>
                                              <v-card-actions style="display: block;text-align:right">
                                                  <sub class="float-right mb-5"
                                style="font-size: .7rem;">{{ getDateTime(msg.created_at) }}</sub>
                                                  </v-card-actions>
                                              </v-card>
                                              
                                          </div>
                                          </template>
                                      </v-card-text>
                                      <v-card-text class="flex-shrink-1">
                                                  <v-text-field
                                              label="Subject"
                                              v-model="conversation.subject"
                                              filled

                                            ></v-text-field>
                                            <v-textarea
                                                          filled
                                                          v-model="conversation.body"
                                                          rows="9"
                                                          class="mt-5"
                                                          placeholder="Body"
                                                        ></v-textarea>
                                                        
                                                                        <v-btn
                                                                          depressed
                                                                          :loading="loadingChat"
                                                                          style="text-transform: none;"
                                                                          color="#FFD221"
                                                                          class="
                                                                            font-weight-normal
                                                                            text-capitalize
                                                                            ms-auto
                                                                            btn-primary
                                                                            float-right
                                                                            py-3
                                                                            px-6
                                                                            ms-3
                                                                          "
                                                                          @click="sendConversation('email')"
                                                                        >
                                                                          SEND
                                                                        </v-btn>
                                      </v-card-text>
                                      </v-card>
                                  </v-responsive>
                                  <div v-else>
                                    <div class="text-h6 text-typo font-weight-bold" style="padding:50px;text-align:center!important;"> Select project to see conversation</div>
                                  </div>
                                  </v-col>
                              </v-row>



                              <v-row class="mt-3">
                            <v-col
                            cols="12"
                            md="12"
                            >


                            <!-- <div style="height:500px; overflow:scroll" v-if="conversations.length != 0">
                      
                              <v-responsive
                                      class="overflow-y-hidden fill-height"
                                      height="500"
                                  >
                                      <v-card
                                      flat
                                      class="d-flex flex-column fill-height"
                                      >
                                      <v-card-text class="flex-grow-1 overflow-y-auto">
                                          <template v-for="(msg, i) in conversations">
                                          <div
                                          style="margin-top:10px;" 
                                              :class="{ 'd-flex flex-row-reverse': msg.user_id != company.client_id }"
                                          >
                                             
                                          <v-card
                                          :color="msg.user_id != company.client_id ? 'blue' : ''"
                                              dark
                                              max-width="400"
                                          >
                                              <v-card-title>
         
                                              <span class="text-h6 font-weight-light">{{msg.subject}}</span>
                                              </v-card-title>

                                              <v-card-text class="text-h5 font-weight-bold">
                                              {{msg.body}}
                                              </v-card-text>
                                              <v-card-actions style="display: block;text-align:right">
                                                  <sub class="float-right mb-5"
                                style="font-size: .7rem;">{{ getDateTime(msg.created_at) }}</sub>
                                                  </v-card-actions>
                                              </v-card>
                                              
                                          </div>
                                          </template>
                                      </v-card-text>
                                      </v-card>
                                  </v-responsive>
                   
                    </div>
                    <div v-else>
                      <div class="text-h6 text-typo font-weight-bold" style="padding:50px;text-align:center!important;"> You're starting a new conversation</div>
                    </div> -->
                  
                        <!-- <div class="mt-5">
                          <v-text-field
                      label="Subject"
                      v-model="conversation.subject"
                      filled

                    ></v-text-field>
                    <v-textarea
                                  filled
                                  v-model="conversation.body"
                                   rows="9"
                                   class="mt-5"
                                   placeholder="Body"
                                ></v-textarea>
                                
                                                <v-btn
                                                  depressed
                                                  :loading="loadingChat"
                                                  style="text-transform: none;"
                                                  color="#FFD221"
                                                  class="
                                                    font-weight-normal
                                                    text-capitalize
                                                    ms-auto
                                                    btn-primary
                                                    float-right
                                                    py-3
                                                    px-6
                                                    ms-3
                                                  "
                                                  @click="sendConversation('email')"
                                                >
                                                  SEND
                                                </v-btn>
                                              </div>
                                               -->
                            </v-col>
                            </v-row>
                      </v-col>
                      </v-row>
                      
  
                      
                    </v-row>
                  </v-card-text>
                  <!-- <v-card-actions class="justify-center ">
                    <v-btn
                      tile  dark
                      style="height: 49px;width:95px;"
                      color="primary"
                      class="mb-10"
                      @click="saveProfile"
                    >Save</v-btn>
                  </v-card-actions> -->
                </v-card>
              </v-col>
            </v-row>
      </v-container>
      <v-dialog
            persistent
          v-model="dialogDetail"
          width="700"
        >
    
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Upload Files
            </v-card-title>
    
            <v-card-text style="margin-top:30px;"> 
              
      <v-card
        @drop.prevent="onDrop($event)"
        @dragover.prevent="dragover = true"
        @dragenter.prevent="dragover = true"
        @dragleave.prevent="dragover = false"
        :class="{ 'grey lighten-2': dragover }"
      >
        <v-card-text>
          <v-row class="d-flex flex-column" dense align="center" justify="center">
            <v-icon :class="[dragover ? 'mt-2, mb-6' : 'mt-5']" size="60">
              fa-cloud-upload-alt
            </v-icon>
            <p class="mt-5">
              Drop your file(s) here
            </p>
            <p>
              Or
            </p>
            <v-btn
              color="blue"
              large
              @click="onButtonClickProof()"
              class="
                font-weight-bolder
                btn-default
              "
              
            >
              Browse
            </v-btn>
            <input
                :ref="'file_proof'"
                class="d-none"
                type="file"
                accept="image/*, video/*"
                @change="onFileChangedProof"
              >
          </v-row>
          <v-virtual-scroll
            v-if="uploadedFiles.length > 0"
            :items="uploadedFiles"
            height="150"
            item-height="50"
          >
            <template v-slot:default="{ item }">
              <v-list-item :key="item.name">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.name }}
                    <span class="ml-3 text--secondary">
                      {{ item.size }} bytes</span
                    >
                  </v-list-item-title>
                </v-list-item-content>
  
                <v-list-item-action>
                  <v-btn @click.stop="removeFile(item.name)" icon>
                    <v-icon style="color: red;">fas fa-times </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
  
              <v-divider></v-divider>
            </template>
          </v-virtual-scroll>
        </v-card-text>
      </v-card>
  
  
            </v-card-text>
            
            <v-divider></v-divider>
    
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue"
                text
                :loading="loadingUpload"
                @click="submit()"
              >
                upload
              </v-btn>
               <v-btn
                color="primary"
                text
                @click="dialogDetail = false"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
            persistent
          v-model="dialogFileAudio"
          width="700"
        >
    
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Add song
            </v-card-title>
    
            <v-card-text style="margin-top:30px;"> 
              <v-text-field
                              id="name"
                              name="name"
                              label="Title"
                              class="font-size-input input-style"
                              width="300px"
                              ref="name"
                              placeholder="What is the name of the song?"
                              v-model="file_audio.song_title"
                              />
                              <v-text-field
                              id="name"
                              name="name"
                              label="Artist"
                              class="font-size-input input-style"
                              width="300px"
                              ref="name"
                              placeholder="Who performed it?"
                              v-model="file_audio.song_artist"
                              />
              </v-card-text>
              <v-divider></v-divider>
    
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue"
                  text
                  :loading="loadingUpload"
                  @click="submitAudio()"
                >
                  Add
                </v-btn>
                <v-btn
                  color="primary"
                  text
                  @click="dialogFileAudio = false"
                >
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
            </v-dialog>
        <avatar-cropper
                      :cropper-options="{autoCropArea: 1,aspectRatio: NaN,}"
                      v-model="showCropper"
                      :file="selectedFileRaw"
                      :upload-handler="handleUploaded"
                    />
        <v-dialog
            v-model="dialogPreviewFile"
            width="1200"
          >
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                Preview
              </v-card-title>
              <div  class="mt-5 mb-5">
               <v-card-text >
                <v-row>
                  <v-col cols="12">
                    
                <v-img
                    v-if="selectedFile.file_extension == 'png' || selectedFile.file_extension == 'jpg' || selectedFile.file_extension == 'jpeg' || selectedFile.file_extension == 'gif' || selectedFile.file_extension == 'webp'"
                        :src="image_url+'slideshow/'+selectedFile.file_name"
                        :lazy-src="image_url+'slideshow/'+selectedFile.file_name"
                        :style="'width: 100%;height:500px'"
                        
                        class="grey lighten-2 white--text align-end float-right"
                      >
                                        <template v-slot:placeholder>
                                          <v-row
                                            class="fill-height ma-0"
                                            align="center"
                                            justify="center"
                                          >
                                            <v-progress-circular
                                              indeterminate
                                              color="grey lighten-5"
                                            ></v-progress-circular>
                                          </v-row>
                                        </template>
                                    
                                      </v-img>
                                      <video v-else ref="videoRef" :src="image_url+'slideshow/'+selectedFile.file_name" id="video-container" width="100%" controls></video>
                                      </v-col>
                                      </v-row>
              </v-card-text>
              </div>
              <v-divider></v-divider>
  
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  @click="dialogPreviewFile = false"
                >
                  close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
       </v-main>
  </v-app>
  
  </template>
  
  <script>
  import { VueTelInput } from 'vue-tel-input'
  import draggable from 'vuedraggable'
  import AvatarCropper from 'vue-avatar-cropper'
  import moment from 'moment'
  export default {
  components: {
        VueTelInput,draggable,AvatarCropper
    },
    data () {
  
        return {
          fname : '',
          lname : '',
          email : '',
          name:'',
          e1 : 3,
          phone:'',
          loading : false,
          password : '',
          is_admin:0,
          showCropper: false,
          dialogFileAudio: false,
          confpassword : '',
          dialogDetail: false,
          selectedFile : '',
          role : '',
          value: 0,
          query: false,
          parents: [
              {
                  id: 1,
                  title: "john doe",
                  active: true
              },
              {
                  id: 3,
                  title: "scarlett",
                  active: false
              },
              {
                  id: 4,
                  title: "scarlett",
                  active: false
              },
              {
                  id: 5,
                  title: "scarlett",
                  active: false
              },
              {
                  id: 6,
                  title: "scarlett",
                  active: false
              },
              {
                  id: 7,
                  title: "scarlett",
                  active: false
              },
              {
                  id: 8,
                  title: "scarlett",
                  active: false
              },
              {
                  id: 9,
                  title: "scarlett",
                  active: false
              },
              {
                  id: 10,
                  title: "scarlett",
                  active: false
              },
              {
                  id: 11,
                  title: "scarlett",
                  active: false
              },
              {
                  id: 12,
                  title: "scarlett",
                  active: false
              },
              {
                  id: 13,
                  title: "scarlett",
                  active: false
              },
              {
                  id: 14,
                  title: "scarlett",
                  active: false
              }
              ],
              messages: [
              {
                  content: "lorem ipsum",
                  me: true,
                  created_at: "11:11am"
              },
              {
                  content: "dolor",
                  me: false,
                  created_at: "11:11am"
              },
              {
                  content: "dolor",
                  me: false,
                  created_at: "11:11am"
              },
              {
                  content: "dolor",
                  me: false,
                  created_at: "11:11am"
              },
          ],
          messageForm: {
          content: "",
          me: true,
          created_at: "11:11am"
          },
          show: true,
          interval: 0,
          valid : true,
          companyname : '',
          sector : '',
          menu : false,
          no_of_employee : '',
          showPassword : false,
          phoneFlag:0,
          emailFlag:0,
          country :'',
          dialogPreviewFile: false,
          otp_email:'',
          image_url :process.env.VUE_APP_WASBI_URL,
          otp_phone:'',
          dragover: false,
          uploadedFiles: [],
          loadingUpload : false,
          stateOptions: [
                  { name: 'ALABAMA', abbreviation: 'AL'},
                  { name: 'ALASKA', abbreviation: 'AK'},
                  { name: 'AMERICAN SAMOA', abbreviation: 'AS'},
                  { name: 'ARIZONA', abbreviation: 'AZ'},
                  { name: 'ARKANSAS', abbreviation: 'AR'},
                  { name: 'CALIFORNIA', abbreviation: 'CA'},
                  { name: 'COLORADO', abbreviation: 'CO'},
                  { name: 'CONNECTICUT', abbreviation: 'CT'},
                  { name: 'DELAWARE', abbreviation: 'DE'},
                  { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC'},
                  { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM'},
                  { name: 'FLORIDA', abbreviation: 'FL'},
                  { name: 'GEORGIA', abbreviation: 'GA'},
                  { name: 'GUAM', abbreviation: 'GU'},
                  { name: 'HAWAII', abbreviation: 'HI'},
                  { name: 'IDAHO', abbreviation: 'ID'},
                  { name: 'ILLINOIS', abbreviation: 'IL'},
                  { name: 'INDIANA', abbreviation: 'IN'},
                  { name: 'IOWA', abbreviation: 'IA'},
                  { name: 'KANSAS', abbreviation: 'KS'},
                  { name: 'KENTUCKY', abbreviation: 'KY'},
                  { name: 'LOUISIANA', abbreviation: 'LA'},
                  { name: 'MAINE', abbreviation: 'ME'},
                  { name: 'MARSHALL ISLANDS', abbreviation: 'MH'},
                  { name: 'MARYLAND', abbreviation: 'MD'},
                  { name: 'MASSACHUSETTS', abbreviation: 'MA'},
                  { name: 'MICHIGAN', abbreviation: 'MI'},
                  { name: 'MINNESOTA', abbreviation: 'MN'},
                  { name: 'MISSISSIPPI', abbreviation: 'MS'},
                  { name: 'MISSOURI', abbreviation: 'MO'},
                  { name: 'MONTANA', abbreviation: 'MT'},
                  { name: 'NEBRASKA', abbreviation: 'NE'},
                  { name: 'NEVADA', abbreviation: 'NV'},
                  { name: 'NEW HAMPSHIRE', abbreviation: 'NH'},
                  { name: 'NEW JERSEY', abbreviation: 'NJ'},
                  { name: 'NEW MEXICO', abbreviation: 'NM'},
                  { name: 'NEW YORK', abbreviation: 'NY'},
                  { name: 'NORTH CAROLINA', abbreviation: 'NC'},
                  { name: 'NORTH DAKOTA', abbreviation: 'ND'},
                  { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP'},
                  { name: 'OHIO', abbreviation: 'OH'},
                  { name: 'OKLAHOMA', abbreviation: 'OK'},
                  { name: 'OREGON', abbreviation: 'OR'},
                  { name: 'PALAU', abbreviation: 'PW'},
                  { name: 'PENNSYLVANIA', abbreviation: 'PA'},
                  { name: 'PUERTO RICO', abbreviation: 'PR'},
                  { name: 'RHODE ISLAND', abbreviation: 'RI'},
                  { name: 'SOUTH CAROLINA', abbreviation: 'SC'},
                  { name: 'SOUTH DAKOTA', abbreviation: 'SD'},
                  { name: 'TENNESSEE', abbreviation: 'TN'},
                  { name: 'TEXAS', abbreviation: 'TX'},
                  { name: 'UTAH', abbreviation: 'UT'},
                  { name: 'VERMONT', abbreviation: 'VT'},
                  { name: 'VIRGIN ISLANDS', abbreviation: 'VI'},
                  { name: 'VIRGINIA', abbreviation: 'VA'},
                  { name: 'WASHINGTON', abbreviation: 'WA'},
                  { name: 'WEST VIRGINIA', abbreviation: 'WV'},
                  { name: 'WISCONSIN', abbreviation: 'WI'},
                  { name: 'WYOMING', abbreviation: 'WY' }
              ],
          company : {
            id : '',
            project_name: "",
            deadline:  "",
            status : "",
            title : "",
            sub_title : "",
            closing_text : "",
            files :[],
  
          },
          otp_phone_input:"",
          otp_email_input:"",
          user_phone:'',
          selectedFileRaw: '',
          settings: [],
          cropperOutputMime : '',
          tab : 1,
          starter_price : 0,
          starter_qty :0,
          song_qty : 0,
          song_price : 0,
          user_email:'',
          selectedProject : '',
          selectedProjectItem: [],
          conversations: [],
          conversation : {
          sender : '',
          receiver_user_id : '',
          receiver : '',
          subject : '',
          body : '',
          type : ''
          },
          status:0,
          client_id : localStorage.getItem("user_id"),
          file_audio :{
            song_artist : "",
            song_title : "",
            id :"",
          },
  
          tab: null,
          headers: [
  
            { text: '', value: 'CompanyURL' },
            { text: '', value: 'CompanyName' },
  
          ],
          history: [
  
          ],
  
          setting: [
          ],
  
          items: [
                'Admin',
                'Viewer',
        ],
        select_role : 'Admin',
         sectors: [
  
        ],
        employeeNumber: [
                '10-50',
                '50-100',
                'More than 100',
  
        ],
  
        }
      },
    watch: {
              // company: {
              //     handler () {
              //         this.company.company_url = this.company.name.replace(/\s/g, '-').toLowerCase()
              //     },
              //     deep: true,
              // },
          },
      methods: {
        getCount(type){
          let count_file = 0;
          let count_audio = 0;
          
          this.company.files.forEach(element => {
            if(element.type !='audio'){
              count_file = count_file +1;
            }else{
              count_audio = count_audio  +1;
            }
          });
          
          if(type == 'audio')
            return count_audio;
          else
            return count_file;
        },
        queryAndIndeterminate () {
          this.query = true
          this.show = true
          this.value = 0
  
          setTimeout(() => {
            this.query = false
  
            this.interval = setInterval(() => {
              this.value += 10
            }, 1000)
          }, 2500)
        },
        getGHLChat(){
          let client_id = localStorage.getItem("user_id");
          axios({
              method: "POST",
              url: "/api/get-ghl-conversation",
              data : {client_id : client_id, ref_id : 'all'},
              headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
              })
              .then(response => {
                  let data = response.data.data;
                  if(data){
                    const groupedByIdWithCount = data.reduce((acc, obj) => {
                    const key = obj.project_name;
                    if (!acc[key]) {
                      acc[key] = { count: 0,project_id : obj.project_id, items: [] };
                    }
                    if (obj.read_status == 0 && obj.user_id != client_id) {
                      acc[key].count++;
                    }

                    acc[key].items.push(obj);

                    return acc;
                  }, {});
                    this.conversations = groupedByIdWithCount;

                    if(this.selectedProject)
                      this.selectedProjectItem = this.conversations[this.selectedProject];
                    }

                  })
          },
          getDateTime(date){
          return moment(date).format('LLLL');
          },
        sendConversation(flag){
          let client_id = localStorage.getItem("user_id");
          this.conversation.type = flag;
          this.conversation.receiver_user_id = 1;
          this.conversation.ref_id = this.selectedProjectItem.project_id;
          let self = this;
          if(!this.conversation.body){
                  Vue.$toast.error("Message is required! ", {
                  position: 'top-right'
                  });
              }else{
          self.loadingChat = true;
          let header = { headers : {
                      Authorization:"Bearer " + localStorage.getItem('token')
                      }
              } 
          axios
              .post("/api/send-conversation",this.conversation,header)
              .then(response => {
              self.loadingChat = false;
              self.getGHLChat();
              Vue.$toast.success('Message Sent', {
                      position: 'top-right'
                      });
                      this. conversation =  {
                          sender : '',
                          receiver_user_id : '',
                          receiver : '',
                          subject : '',
                          body : '',
                          type : ''
                          }
              })
              .catch(err => {
              self.loadingChat = false;
                  Vue.$toast.error('Unable to send', {
                  position: 'top-right'
                  });
              })
          }
          },
        getSettings() {
          let flag = 1;
          let url = process.env.VUE_APP_WASBI_URL
          var config = {headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache'}};
          axios({
              method: "POST",
              url: "/api/get-company-settings",
              headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
              })
              .then(response => {
                  let data = response.data.data;
                  if(data){
                      this.settings = data.settings
                  localStorage.setItem('settings', JSON.stringify(data.settings));
                  localStorage.setItem('admin_bg', JSON.stringify(url+data.settings.backgroung_logo));
                  }


              })
              .catch(err => {
                  // Vue.$toast.error(err, {
                  // position: 'top-right'
                  // });

              });

          },
        loaderOff(){
                clearInterval(this.interval)
                this.show = false
        },
        renderChat(item,key){
          if(this.selectedProject == key)
            this.selectedProject = '';
          else{
            this.selectedProject = key;
            this.updateReadStatus(item.project_id);
          }
          this.selectedProjectItem = item;

        },
        onButtonClickProof(id) {
          this.$refs['file_proof'].click()
        },
        removeItem(id){
          var r = confirm("Are you sure, You went to delete?");
              if (r == true) {
                this.queryAndIndeterminate()
                axios
                  .get("/api/delete-slideshow-file/"+id,this.header)
                  .then(response => {
                      let data = response.data;
    
                  if (response.data) {
                      Vue.$toast.success('Deleted successfully', {
                          position: 'top-right'
                          });
                        this.getUserProfile(this.$route.params.id);
                      }else {
                          Vue.$toast.error(data.message, {
                          position: 'top-right'
                          });
                      }
    
                  })
                .catch(err => {
                  Vue.$toast.error(err, {
                  position: 'top-right'
                  });
                })
              }
        },
        removeFile(fileName) {
        // Find the index of the
        const index = this.uploadedFiles.findIndex(
          file => file.name === fileName
        );
        // If file is in uploaded files remove it
        if (index > -1) this.uploadedFiles.splice(index, 1);
      },
      async convertUrlToFile(imageUrl, fileExt) {
        try {
          const response = await fetch(imageUrl);
          const blob = await response.blob();
          const file = new File([blob], 'image.'+fileExt, { type: blob.type });
          // Now you have the 'file' object that represents the image file
          this.selectedFileRaw = file;
          this.showCropper = true
        } catch (error) {
          console.error('Error converting URL to File:', error);
        }
      },
      onFileChangedProof(e) {
        e.target.files.forEach(element =>
            this.uploadedFiles.push(element)
          );
      },
      onDrop(e) {
        this.dragover = false;
        // If there are already uploaded files remove them
        if (this.uploadedFiles.length > 0) this.uploadedFiles = [];
        // If user has uploaded multiple files but the component is not multiple throw error
        if (!this.multiple && e.dataTransfer.files.length > 1) {
          this.$store.dispatch("addNotification", {
            message: "Only one file can be uploaded at a time..",
            colour: "error"
          });
        }
        // Add each file to the array of uploaded files
        else
          e.dataTransfer.files.forEach(element =>
            this.uploadedFiles.push(element)
          );
      },
      submit(editFlag = 0,editFlagId='') {
        // If there aren't any files to be uploaded throw error
        if (!this.uploadedFiles.length > 0) {
          Vue.$toast.error('There are no files to upload', {
            position: 'top-right'
        });
        } else {
          let header = { headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  }
          }
          // Send uploaded files to parent component
          // this.$emit("filesUploaded", this.uploadedFiles);
          const formData = new FormData();
          formData.append('type', 'file');
          formData.append('edit_flag', editFlag);
          formData.append('edit_flag_id', editFlagId);
            // Append each file to the form data
            for (let i = 0; i < this.uploadedFiles.length; i++) {
              formData.append('files[]', this.uploadedFiles[i]);
            }
            this.loadingUpload = true;
            this.queryAndIndeterminate()
            axios
            .post("/api/upload-slideshow-files/"+this.company.id,formData,header)
            .then(response => {
                let data = response.data;
  
              if (data.flag == 1) {
                  
                this.dialogDetail = false;
                this.uploadedFiles = [];
                this.getUserProfile(this.$route.params.id)
                this.loadingUpload = false;
                }else {
                  console.log(data.response);
                    // Vue.$toast.error(data.message, {
                    // position: 'top-right'
                    // });
                }
  
            })
              .catch(err => {
                if(err.response.data.error == 'validation.mimetypes')
                  Vue.$toast.error('Invalid file type', {
                  position: 'top-right'
                  });
                else
                  Vue.$toast.error('Unable to upload', {
                    position: 'top-right'
                    });
  
              });
          // Close the dialog box
        }
      },
      downloadFile(file){
      let self = this;
      window.open(this.image_url+'slideshow/'+file.file_name, '_blank');
      },
      submitAudio(editFlag = 0,editFlagId='') {
        if(this.file_audio.id){
          editFlag = 1;
          editFlagId = this.file_audio.id;
        }
        // If there aren't any files to be uploaded throw error
        if (!this.file_audio.song_title) {
          Vue.$toast.error('Title is required', {
            position: 'top-right'
        });
        }else if(!this.file_audio.song_artist){
          Vue.$toast.error('Artist is required', {
            position: 'top-right'
        });
        } else {
          let header = { headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  }
          }
          // Send uploaded files to parent component
          // this.$emit("filesUploaded", this.uploadedFiles);
          const formData = new FormData();
          formData.append('type', 'audio');
          formData.append('edit_flag', editFlag);
          formData.append('edit_flag_id', editFlagId);
          formData.append('song_title', this.file_audio.song_title);
          formData.append('song_artist', this.file_audio.song_artist);
            // Append each file to the form data
            this.loadingUpload = true;
            this.queryAndIndeterminate()
            axios
            .post("/api/upload-slideshow-files-audio/"+this.company.id,formData,header)
            .then(response => {
                let data = response.data;
  
              if (data.flag == 1) {
                  
                this.dialogFileAudio = false;
                this.uploadedFiles = [];
                this.getUserProfile(this.$route.params.id)
                this.loadingUpload = false;
                }else {
                  console.log(data.response);
                    // Vue.$toast.error(data.message, {
                    // position: 'top-right'
                    // });
                }
  
            })
              .catch(err => {
                if(err.response.data.error == 'validation.mimetypes')
                  Vue.$toast.error('Invalid file type', {
                  position: 'top-right'
                  });
                else
                  Vue.$toast.error('Unable to upload', {
                    position: 'top-right'
                    });
  
              });
          // Close the dialog box
        }
      },
      dataURLtoFile(dataurl, filename) {
   
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), 
          n = bstr.length, 
          u8arr = new Uint8Array(n);
          
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      
      return new File([u8arr], filename, {type:mime});
      },
      handleUploaded(cropper) {
            let image = cropper.getCroppedCanvas().toDataURL(this.cropperOutputMime);
            let img = this.dataURLtoFile(image,this.selectedFile.file_name);
            this.uploadedFiles.push(img);
            this.submit(1,this.selectedFile.id);
            this.showCropper = false;
  
          },
      addPhone(){
          this.phoneFlag = 1;
      },
      addEmail(){
          this.emailFlag = 1;
      },
      countryChanged(country) {
          this.country = '+' + country.dialCode
      },
  
  
      add: function () {
           // for setting tab
      },
  
      getUserProfile(id) {
      let flag = 1;
      let header = { headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  }
          }
          this.queryAndIndeterminate();
        axios
          .get("/api/get-slideshow/"+id,header)
          .then(response => {
              let data = response.data;
  
             if (flag == 1) {
                this.company = data.data;
                let count_file = 0;
                let count_audio = 0;
                this.company.files.forEach(element => {
                  if(element.type !='audio'){
                      count_file = count_file +1;
                  }else{
                      count_audio = count_audio  +1;
                  }
                  });
                  const dynamicThreshold =  Math.ceil(count_file / Number(this.settings.plan_1_quantity)) == 0 ? 1 : Math.ceil(count_file / Number(this.settings.plan_1_quantity));
                  this.starter_price =  dynamicThreshold * this.settings.plan_1_price;
                  this.starter_qty= dynamicThreshold == 1 ? '('+this.settings.plan_1_quantity+')' :'('+this.settings.plan_1_quantity+'x'+dynamicThreshold+')';
                  const dynamicThresholdSong =  Math.ceil(count_audio / Number(this.settings.song_quantity)) == 0 ? 1 : Math.ceil(count_audio / Number(this.settings.song_quantity));
                  this.song_price =  dynamicThresholdSong * this.settings.song_price;
                  this.song_qty= dynamicThresholdSong == 1 ? ''+this.settings.song_quantity+'' :'('+this.settings.song_quantity+'x'+dynamicThresholdSong+')';
                this.loaderOff();
                this.getGHLChat()
              }else {
                  Vue.$toast.error(data.message, {
                  position: 'top-right'
                  });
              }
  
           })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
  
             });
  
       },
       sortChange(){
        let header = { headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  }
          }
        axios
          .post("/api/change-slideshow-order/",this.company.files,header)
          .then(response => {
              let data = response.data;
  
             if (data.flag == 1) {
                this.getUserProfile(this.$route.params.id)
              }else {
                  Vue.$toast.error(data.message, {
                  position: 'top-right'
                  });
              }
  
           })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
  
             });
       },
       updateReadStatus(id){
        let header = { headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  }
          }
        axios
          .get("/api/update-conversation-status/"+id,header)
          .then(response => {
              let data = response.data;
  
             if (data.flag == 1) {
                this.getGHLChat()
              }else {
                  Vue.$toast.error(data.message, {
                  position: 'top-right'
                  });
              }
  
           })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
  
             });
       },
  
  
  
      saveProfile: function(submit=0) {
        let self = this;
        let flag = 1;
        let header = { headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  }
          }
  
          if (flag) {
            self.loading = true;
            
          axios({
            method: "POST",
           headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  },
             
            url: "/api/save-slideshow",
            data: self.company
          })
            .then(response => {
              let data = response.data.data;
              if (response.data) {
                    
                    self.loading = false;
                    if(!this.company.id){
                      this.$router.push('/add-slideshow/'+data.id)
                    }else if(submit == 1){
                      Vue.$toast.success('Status Saved', {
                      position: 'top-right'
                    })
                    }else
                      this.e1 = 2;
  
              } else {
                  Vue.$toast.error('Failed To Add', {
                  position: 'top-right'
                  });
                  self.loading = false;
              }
            })
            .catch(err => {
              console.log(err.response);
              
                if(err.response.data.errors.email)
                  Vue.$toast.error(err.response.data.errors.email[0], {
                  position: 'top-right'
                  });
                if(err.response.data.errors.user_name)
                  Vue.$toast.error(err.response.data.errors.user_name[0], {
                  position: 'top-right'
                  });     
                if(err.response.data.errors.company_url)
                  Vue.$toast.error(err.response.data.errors.company_url[0], {
                  position: 'top-right'
                  });             
            });
          }
      },
  
   },
  
  created() {
        console.log('created called.');
          this.getGHLChat();
      },
  
  }
  </script>
  
  <style>
  .v-stepper__step__step{
    background: #4CAF50;
  }
  .stepper-slide  {
    box-shadow: none!important;
  }
  .text-gray-400 {
    --tw-text-opacity: 1;
    color: #9ca3af;
    font-family: inherit;
    font-size: 1.5rem;
    color: rgba(156,163,175,var(--tw-text-opacity));
  }
  .v-tabs-slider-wrapper
  {
    height: 46px!important;
  }
  </style>