<template>
  <v-app >
    <vue-header-admin></vue-header-admin>
    <v-main  >
    <v-container fluid style="margin-bottom:150px;" class="px-6 py-6">
      <v-row >
        <v-col cols="12">
        <v-btn
            outlined
              :ripple="false"
              elevation="0"
              :x-small=" this.$vuetify.breakpoint.xs ? true : false "
             color="#4CAF50"
             @click="$router.push('/admin/client-profiles')"
              class="
                font-weight-bolder
                btn-primary
                py-5
                px-6
                ms-2
              "
              
            >
            <v-icon class="material-icons-round me-2">arrow_back</v-icon>  Back to list
            </v-btn>
            <div class="d-flex float-right">
                
                <!-- <v-btn
              :ripple="false"
              elevation="0"
              :x-small=" this.$vuetify.breakpoint.xs ? true : false "
             color="#4CAF50"
              class="
                font-weight-bolder
                btn-primary
                py-5
                px-6
                ms-2
              "
              
            >
              Send to outsourcing
            </v-btn> -->
            </div>
            </v-col>
            <v-col cols="12">
            <v-expansion-panels v-model="panel">
            <v-expansion-panel style="background-color:#D7EDF9;"  v-model="panel">
              <v-expansion-panel-header><v-icon class="material-icons-round" style="float:left!important;display:block;">
                              menu
                            </v-icon></v-expansion-panel-header>
              <v-expansion-panel-content key="0">
                <v-row>
            <v-col cols="12" md="6">
              <v-card class="card-shadow px-4 py-4 overflow-hidden border-radius-xl">

                        <div class="h-100">
                      <p class="mt-5 mb-1"> <span class="mb-1 text-h4 text-typo font-weight-bold">
                          {{client.first_name ? client.first_name+' '+client.last_name : ''}}
                        </span>
                      <!-- <span> ( Current status : <span class="text-h4" style="color:#1A73E8;">Active</span> )</span> -->
                        </p>

                      </div>
                      <v-row>
                        <v-col cols="6">
                          <div class="customer-side-panel-ssn-left">Cell: {{client.phone_m}}</div><br/>
                          <div class="customer-side-panel-ssn-left">Email: {{client.email}}</div><br/>
                          <!-- <div class="customer-side-panel-ssn-left">{{client.address_1}}</div><br/> 
                          <div class="customer-side-panel-ssn-left">{{client.city}}, {{client.state}} {{client.zip}}</div> -->
                        </v-col>
                        <v-col cols="6">
                          <!-- <div class="customer-side-panel-ssn-left">SSN: {{client.ssn}}</div><br/> -->
                          <div class="customer-side-panel-ssn-left ">DOB: {{client.dob}}</div><br/>
                          <div class="customer-side-panel-ssn-left ">Started: {{client.created_at}}</div><br/>
                           <div class="customer-side-panel-ssn-left ">Created: {{client.created_at}}</div><br/>
                        </v-col>
                      </v-row>

                    <v-expansion-panels v-model="panel5">
                      <v-expansion-panel style="background-color:#D7EDF9;margin-top:20px;margin-bottom:20px;"  v-model="panel5">
                        <v-expansion-panel-header style="background: rgb(39, 39, 39);"><h2 class="text-h5 font-weight-bold text-white">Conversations</h2></v-expansion-panel-header>
                        <v-expansion-panel-content key="0" style="background: #FAFAFA;">
                      <div style="height:500px; overflow:scroll" v-if="conversations.length != 0">
                        <v-timeline
                              align-top
                              class="mt-5"
                              
                              :dense="$vuetify.breakpoint.smAndDown"
                            >
                            <v-timeline-item
                                    v-for="item in conversations"
                                    :key="item"
                                    small
                                  >
                                    <template v-slot:icon>
                                        <v-icon class="material-icons-round" v-if="item.conversation_type =='email'">
                              mail
                            </v-icon><v-icon class="material-icons-round" v-if="item.conversation_type =='sms'">
                              sms
                            </v-icon>
                                    </template>
                                    <template v-slot:opposite>
                                      <span><strong>{{item.first_name}} {{ item.last_name }}</strong></span>
                                      <p style="font-size : 11px;">{{ getDateTime(item.created_at) }}</p>
                                    </template>
                                    <v-card class="elevation-2">
                                      <v-card-title class="text-h5">
                                        {{item.conversation_type =='sms' ? 'SMS' : item.subject}}
                                      </v-card-title>
                                      <v-card-text>{{item.body}}</v-card-text>
                                    </v-card>
                                  </v-timeline-item>
                                </v-timeline>
                      </div>
                      <div v-else>
                        <div class="text-h6 text-typo font-weight-bold" style="padding:50px;text-align:center!important;"> You're starting a new conversation</div>
                      </div>
                      <v-tabs
                        v-model="tab"
                        fixed-tabs
                      >
                        <v-tabs-slider color="green"></v-tabs-slider>

                        <v-tab
                          key="1"
                        >SMS
                      </v-tab>
                      <v-tab
                          key="2"
                        >Email
                      </v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="tab">
                          <v-tab-item
                            key="1"
                          >
                          <div>
                      <v-textarea
                                    filled
                                     rows="9"
                                     class="mt-5"
                                     v-model="conversation.body"
                                     placeholder="message..."
                                  ></v-textarea>
                                  
                                                  <v-btn
                                                    depressed
                                                    :loading="loadingChat"
                                                    style="text-transform: none;"
                                                    color="#FFD221"
                                                    class="
                                                      font-weight-normal
                                                      text-capitalize
                                                      ms-auto
                                                      btn-primary
                                                      float-right
                                                      py-3
                                                      px-6
                                                      ms-3
                                                    "
                                                    @click="sendConversation('sms')"
                                                  >
                                                    SEND
                                                  </v-btn>
                                                </div>
                                                </v-tab-item>
                                                <v-tab-item
                            key="2"
                          >
                          <div class="mt-5">
                            <v-text-field
                        label="From"
                        filled
                        disabled
                        v-model="email_parent"

                      ></v-text-field>
                            <v-text-field
                        label="Subject"
                        v-model="conversation.subject"
                        filled

                      ></v-text-field>
                      <v-textarea
                                    filled
                                    v-model="conversation.body"
                                     rows="9"
                                     class="mt-5"
                                     placeholder="Body"
                                  ></v-textarea>
                                  
                                                  <v-btn
                                                    depressed
                                                    :loading="loadingChat"
                                                    style="text-transform: none;"
                                                    color="#FFD221"
                                                    class="
                                                      font-weight-normal
                                                      text-capitalize
                                                      ms-auto
                                                      btn-primary
                                                      float-right
                                                      py-3
                                                      px-6
                                                      ms-3
                                                    "
                                                    @click="sendConversation('email')"
                                                  >
                                                    SEND
                                                  </v-btn>
                                                </div>
                                                </v-tab-item>
                          </v-tabs-items>
                                                  </v-expansion-panel-content>
                                                  </v-expansion-panel>
                                                  </v-expansion-panels>
                    
              </v-card>
              
              </v-col>
              <v-col cols="12" md="6">
                <v-card class="card-shadow px-4 py-4 overflow-hidden border-radius-xl">
                  <div class="h-100">
                      <p class="mt-5 mb-1"> <span class="mb-1 text-h4 text-typo font-weight-bold">
                          Company & Affiliate
                        </span>
                      
                        </p>

                      </div>
                      <hr
                          style="background-color:#d8e1eb;height: 2px;"
                        class="horizontal mt-3"
                      />
                      <v-row class="mt-3">
                          <v-col cols="auto">
                            <v-avatar width="50" height="50" class="shadow rounded-circle">
                              <img
                              v-if="client.logo"
                                :src="wasabi_url+client.logo"
                                alt="Avatar"
                                class="rounded-circle"
                              />
                               <img
                              v-else
                                src="@/assets/img/logo.png"
                                alt="Avatar"
                                class="rounded-circle"
                              />
                            </v-avatar>
                          </v-col>
                          <v-col cols="auto" class="my-auto">
                            <div class="h-100">
                              <h5 class="mb-1 text-h5 text-typo font-weight-bold">
                                Company Assigned
                              </h5>
                              <p class="mb-0 font-weight-light text-body text-md" v-if="client.name">
                                {{client.name}}
                              </p>
                              <p class="mb-0 font-weight-light text-body text-md" v-else>
                                Wecreate
                              </p>
                            </div>
                          </v-col>
                          </v-row>
                          <v-row class="mt-3">
                          <v-col cols="auto">
                            <v-avatar width="50" height="50" class="shadow rounded-circle">
                              <img
                                src="@/assets/img/avatar.png"
                                alt="Avatar"
                                class="rounded-circle"
                              />
                            </v-avatar>
                          </v-col>
                          <v-col cols="auto" class="my-auto">
                            <div class="h-100"> 
                              <h5 class="mb-1 text-h5 text-typo font-weight-bold">
                                Affiliate From
                              </h5>
                              <p class="mb-0 font-weight-light text-body text-md">
                                N/A
                              </p>
                            </div>
                          </v-col>

                          </v-row>
                          
              </v-card>
              </v-col>
            </v-row>
              </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
         


        </v-row>
      
      <v-dialog
      v-model="importDialog"
      width="700"
    >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
          Import Credit Report
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
          <rotate-loader class="mt-3 mb-3" :loading="loadingImport" color="#1D75F4"></rotate-loader>
          <span style="color:#5D6167;">Import From</span>
          <v-select
                          v-model="import_from"
                          @change="renderImport"
                          outlined
                            :items="['Smart Credit','My Free Score Now']"
                            
                          ></v-select>
          <span style="color:#5D6167;">Username</span>
          <v-text-field
                v-model="username"
                outlined
          ></v-text-field>
          <span style="color:#5D6167;">Password</span>
          <v-text-field
             type="password"
                v-model="password"
                outlined
          ></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            :dark ="isDarkImport"
            :disabled="isDisableImport"
            @click="getReportFromSmartCredit()"
          >
            Import
          </v-btn>
           <v-btn
            color="green"
            outlined
            :disabled="loadingAssign"
            @click="importDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      v-model="sendLetterDialog"
    >

     <v-card style="background: #18214D;">
        <v-toolbar
          dark
          color="green"
        >
          <v-btn
            icon
            dark
            @click="sendLetterDialog = false"
          >
            <v-icon class="ml-5">fa-times</v-icon>
          </v-btn>
          <v-toolbar-title>Send Letter</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>

          </v-toolbar-items>
        </v-toolbar>
        <div >
          <v-form
                    ref="form"
                    v-model="valid_letter"
                    lazy-validation
                  >
               <v-row  style="padding: 50px;">
               
                <v-col cols="12" md="6">
                  <v-card
                      class="mx-auto"
                      
                    >
                      <v-card-text class="pl-5">
                        <div><v-radio-group v-model="radioGroup">
                                <v-radio
                                color="info"
                                  :label="`Upload a document`"
                                  :value="1"
                                ></v-radio>
                              </v-radio-group>
                        </div>
                        <div>
                          <v-btn
                          x-large
                              color="#5E72E4"
                              class="ma-2 white--text"
                              @click="onButtonClick('file_letter')"
                            >
                            <v-icon
                                left
                                dark
                                
                              >
                                fa-upload
                              </v-icon>
                                 Click to upload PDF
                              
                            </v-btn>
                            <input
                              ref="file_letter"
                              class="d-none"
                              type="file"
                              accept="application/pdf"
                              @change="onFileChangedLetter"
                            >
                            Must be PDF format,
10mb max
                        </div>
                        <div class="mt-2">
                          {{docupost.file ? docupost.file.name : ''}} 
                            <v-icon class="ml-2" v-if="docupost.file.length != 0" color="red" @click="docupost.file = []">
                              fa-times
                            </v-icon>
                        </div>
                      </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                  <v-card
                      class="mx-auto"
                      
                    >
                      <v-card-text class="pl-5 pr-5">
                        <v-row>
                           <v-col cols="12" md="6">
                        <div style="margin:15px 0 7px 0;">
                          <h2>Mail service</h2>
                        </div>
                        <div>
                          <v-select
                          v-model="docupost.mail_service"
                            :items="['USPS First Class (3-10 days)','USPS Standard (5-20 days)']"
                            
                          ></v-select>
                          
                        </div>
                        </v-col>
                         <v-col cols="12" md="6">
                          <div style="margin:15px 0 7px 0;" v-if="docupost.mail_service =='USPS First Class (3-10 days)'">
                          <h2>Certified options</h2>
                        </div>
                        <div v-if="docupost.mail_service =='USPS First Class (3-10 days)'">
                          <v-select
                          v-model="docupost.certified_option"
                            :items="['','Certified mail','Certified w/electronic return receipt (proof of receipt)']"
                            
                          ></v-select>
                          
                        </div>
                         </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                  <v-card
                      class="mx-auto"
                      
                    >
                      <v-card-text class="pl-5 pr-5">
                         <div style="margin:15px 0 7px 0;">
                          <h2>To Recipient</h2>
                        </div>
                        <div>
                          <v-row>
                            <v-col
                                cols="12"
                                md="12"
                              >
                              <v-select
                              style="margin-bottom:-25px;"
                              label="Select Address"
                              item-text="name"
                              item-value="name"
                              @change="renderAddress('res')"
                                v-model="address_select_res"
                                  :items="address_list_res"
                                  
                                ></v-select>
                            </v-col>
                              <v-col
                                cols="12"
                                md="12"
                              >
                                <v-text-field
                                style="margin-bottom:-25px;margin-top:15px;"
                                  v-model="recipient.name"
                                  :rules="nameRules"
                                  label="Recipient name"
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col
                                cols="12"
                                md="12"
                              >
                                <v-text-field
                                 style="margin-bottom:-25px;"
                                  v-model="recipient.address1"
                                  :rules="nameRules"
                                  label="Address line 1"
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col
                                cols="12"
                                md="12"
                              >
                                <v-text-field
                                 style="margin-bottom:-25px;"
                                  v-model="recipient.address2"
                                  label="Address line 2"
                                  
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col
                                cols="12"
                                md="4"
                              >
                                <v-text-field
                                 style="margin-bottom:-25px;"
                                  v-model="recipient.city"
                                  :rules="nameRules"
                                  label="City"
                                  
                                  required
                                ></v-text-field>
                              </v-col>
                               <v-col
                                cols="12"
                                md="4"
                              >
                                <v-select
                                 style="margin-bottom:-25px;"
                                item-text="name"
                                  item-value="abbreviation"
                                  :items="stateOptions"
                                  v-model="recipient.state"
                                  :rules="nameRules"
                                  label="State"
                                  
                                  required
                                ></v-select>
                              </v-col>
                               <v-col
                                cols="12"
                                md="4"
                              >
                                <v-text-field
                                 style="margin-bottom:-25px;"
                                  v-model="recipient.zip"
                                  :rules="nameRules"

                                  label="Zip"
                                  v-mask="'#####'"
                                  
                                  required
                                ></v-text-field>
                              </v-col>
                          </v-row>
                        </div>
                        
                      </v-card-text>
                    </v-card>
                </v-col>
                 <v-col cols="12" md="6">
                  <v-card
                      class="mx-auto"
                      
                    >
                      <v-card-text class="pl-5 pr-5">
                         <div style="margin:15px 0 7px 0;">
                          <h2>From Sender</h2>
                        </div>
                        <div>
                          <v-row>
                            <v-col
                                cols="12"
                                md="12"
                              >
                              <v-select
                              style="margin-bottom:-25px;"
                              label="Select Address"
                              item-text="name"
                              item-value="name"
                              @change="renderAddress('sen')"
                                v-model="address_select_sen"
                                  :items="address_list_sen"
                                  
                                ></v-select>
                            </v-col>
                              <v-col
                                cols="12"
                                md="12"
                              >
                                <v-text-field
                                style="margin-bottom:-25px;margin-top:15px;"
                                  v-model="sender.name"
                                  :rules="nameRules"
                                  label="Sender name"
                                  
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col
                                cols="12"
                                md="12"
                              >
                                <v-text-field
                                 style="margin-bottom:-25px;"
                                  v-model="sender.address1"
                                  :rules="nameRules"
                                  label="Address line 1"
                                  
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col
                                cols="12"
                                md="12"
                              >
                                <v-text-field
                                 style="margin-bottom:-25px;"
                                  v-model="sender.address2"
                                  label="Address line 2"
                                  
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col
                                cols="12"
                                md="4"
                              >
                                <v-text-field
                                 style="margin-bottom:-25px;"
                                  v-model="sender.city"
                                  :rules="nameRules"
                                  label="City"
                                  
                                  required
                                ></v-text-field>
                              </v-col>
                               <v-col
                                cols="12"
                                md="4"
                              >
                                <v-select
                                 style="margin-bottom:-25px;"
                                item-text="name"
                                  item-value="abbreviation"
                                  :items="stateOptions"
                                  v-model="sender.state"
                                  :rules="nameRules"
                                  label="State"
                                  
                                  required
                                ></v-select>
                              </v-col>
                               <v-col
                                cols="12"
                                md="4"
                              >
                                <v-text-field
                                 style="margin-bottom:-25px;"
                                  v-model="sender.zip"
                                  :rules="nameRules"

                                  label="Zip"
                                  v-mask="'#####'"
                                  
                                  required
                                ></v-text-field>
                              </v-col>
                          </v-row>
                        </div>
                        
                      </v-card-text>
                    </v-card>
                </v-col>
                 <v-col cols="12" md="12">
                  <v-card
                      class="mx-auto"
                      
                    >
                      <v-card-text class="pl-5 pr-5">
                         <div style="margin:15px 0 7px 0;">
                          <h2>Document options</h2>
                        </div>
                        <div>
                          <v-row>
                            <v-col cols="12" md="3">
                           <v-checkbox
                                v-model="docupost.document_options.color"
                                :label="`Print in color `"
                              ></v-checkbox>
                              </v-col>
                              <v-col cols="12" md="3">
                              <v-checkbox
                                v-model="docupost.document_options.double_side"
                                :label="`Double-sided `"
                              ></v-checkbox>
                              </v-col>
                              </v-row>
                        </div>
                        </v-card-text>
                  </v-card>
                 </v-col>
                   <v-col cols="12" md="12">
                  <v-card
                      class="mx-auto"
                      
                    >
                      <v-card-text class="pl-5 pr-5">
                         <div style="margin:15px 0 7px 0;">
                           <v-btn
                          x-large
                              :disabled="!valid_letter"
                              color="#5E72E4"
                              class="ma-2 white--text"
                              @click="sendLetter"
                            >
                            <v-icon
                                left
                                dark
                                
                              >
                                fa-paper-plane
                              </v-icon>
                                 Send Letter
                              <rotate-loader style="" :loading="loadingSendLetter" color="white"></rotate-loader>
                            </v-btn>
                         </div>
                         </v-card-text>
                         </v-card>
                   </v-col>
              
               </v-row>
                </v-form>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
        persistent
      v-model="dialogAssign2"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2" v-if="!product.id">
          Add new Grant
        </v-card-title>
        <v-card-title class="text-h5 grey lighten-2" v-else>
          Edit Product
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
          <v-text-field
                label="Grant Name"
                v-model="product.name"
          ></v-text-field>
          <v-text-field
                v-model="product.url"
                label="Grant URL"
          ></v-text-field>
             <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="product.deadline"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="product.deadline"
                      label="Deadline"
                      prepend-icon="fa-calendar"
                      readonly
                      v-bind="attrs"
                      clearable
                      v-on="on"
                      @click:clear="product.deadline = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="product.deadline"
                    scrollable
                    color="green lighten-1"
                    :min="minDate"
                    header-color="green lighten-1"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="menu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(product.deadline)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
                <v-select
                            item-text="name"
                            label="Focus Area"
                            item-value="id"
                            :disabled="loadingAssign"
                            v-model="product.focus_area"
                            :items="focusarea"
                            ></v-select>
                             <v-select
                            item-text="name"
                            label="Location"
                            item-value="id"
                            :disabled="loadingAssign"
                            v-model="product.location"
                            :items="locations"
                            ></v-select>
                            <v-text-field
                            label="Value"
                v-model="product.value"
                v-mask="'###########################'"
                
          ></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
          v-if="!product.id"
            color="#4caf50"
            text
            :disabled="loadingAssign2"
            @click="addGrant()"
          >
            Submit
          </v-btn><v-btn
          v-else
            color="primary"
            text
            :disabled="loadingAssign"
            @click="editPromo()"
          >
            Submit
          </v-btn>
           <v-btn
            color="primary"
            text
            :disabled="loadingAssign"
            @click="dialogAssign2 = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        persistent
      v-model="dialogImport2"
      width="500"
    >
  
      <v-card>
        
        <v-card-title class="text-h5 grey lighten-2">
          Submit new request
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
           <rotate-loader class="mt-3 mb-3" :loading="loadingUpload2" color="#1D75F4"></rotate-loader>
                <span style="color:#5D6167;">Process</span>
                 <v-select
                            outlined
                            :disabled="loadingUpload2"
                            v-model="request.process"
                            :items="itemOptions"
                            ></v-select>
                <span style="color:#5D6167;">Round</span>
                 <v-select
                            outlined
                            :disabled="loadingUpload2"
                            v-model="request.round"
                            :items="[1,2,3,4]"
                            ></v-select>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :disabled="loadingUpload2"
            @click="submitRequest()"
          >
            Submit
          </v-btn>
           <v-btn
            color="primary"
            text
            :disabled="loadingUpload2"
            @click="dialogImport2 = false"
          >
            Close
          </v-btn>
        </v-card-actions>
        
      </v-card>
    </v-dialog>
     <v-dialog
          v-model="dialogDoc"
          width="700"
        >
          <v-card>
            <rotate-loader class="mt-3 mb-3" :loading="loadingUpload2" color="#1D75F4"></rotate-loader>
            <v-card-title class="text-h5 grey lighten-2">
              Process 1
            </v-card-title>
            <div  class="mt-5 mb-5">
             <v-card-text >
              <span class="text-h5" >
                <v-btn
                            class="mx-2"
                            dark
                            @click="StartProcess1(1)"
                            :color="'green'"
                          >
                          Server 1
                          </v-btn>
              </span>
            </v-card-text>
            <v-card-text >
              <span class="text-h5" >
                <v-btn
                            class="mx-2"
                            dark
                            @click="StartProcess1(2)"
                            :color="'green'"
                          >
                          Server 2
                          </v-btn>
              </span>
            </v-card-text>
            </div>
            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="dialogDoc = false"
              >
                close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
        scrollable
        transition="dialog-bottom-transition"
      v-model="dialogSignContract"
      width="1000"
    >
  
      <v-card>
        
        <v-card-title class="text-h5 grey lighten-2">
          Sign Contract({{selectedContract.title}})
        </v-card-title>

        <v-card-text style="margin-top:30px;">
          


          <div v-html="renderHtml(selectedContract.letter)"></div>
          <v-checkbox v-model="checkbox">
          <template v-slot:label>
            <div>
              I, {{client.first_name}} {{client.last_name}} am giving my legal consent for my Electronic Signature to be used as proof that I did in fact sign this Electronic Document. I agree to all terms set forth herein.
              </div>
          </template>
        </v-checkbox>
          <div class="buttons-sig" v-if="checkbox">Signature</div>
          <div class="buttons-sig">
            
          <VueSignaturePad
          id="signature"
          scaleToDevicePixelRatio
          width="500px"
          v-if="checkbox"
          height="300px"
          ref="signaturePad"
          :options="options_pad"
        />
          </div>
        <div class="buttons-sig"  v-if="checkbox">
        <button @click="undo">Undo</button>
      </div>

        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
                text
                @click="submitContract()"
              >Agree to contract and Submit</v-btn>
              <v-btn
                text
                @click="dialogSignContract = false"
              >Close</v-btn>
           
            </v-card-actions>
        </v-card>
        
        </v-dialog>
        <v-dialog
          v-model="creditDialog"
          width="700"
        >
         <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{creditFlag == 'remove' ? 'Remove credit' : 'Add credit'}}
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
          <span style="color:#5D6167;">Credit</span>
          <v-text-field
                v-model="new_credit"
                v-mask="'###########################'"
                
          ></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :disabled="loadingAssign"
            @click="addCredit()"
          >
            Submit
          </v-btn>
           <v-btn
            color="primary"
            text
            :disabled="loadingAssign"
            @click="creditDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
        <v-overlay
          :absolute="true"
          :value="creditFlag2"
          :opacity=".5"
        >
          <v-btn
            color="primary"
            @click="addCreditCheck()"
          >
            Buy Credit
          </v-btn>
          <v-btn
            color="black"
            @click="creditFlag2 = false,creditDialog = false"
          >
            Close
          </v-btn>
        </v-overlay>
      </v-card>
        </v-dialog>





        <v-dialog
          v-model="creditDialogProdigy"
          width="700"
        >
         <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{creditFlag == 'remove' ? 'Remove credit' : 'Add credit'}}
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
          <span style="color:#5D6167;">You have to buy credits to push to Prodigy.</span>
         
        </v-card-text>

        <v-overlay
          :absolute="true"
          :value="creditFlagProdigy"
          :opacity=".5"
        >
          <v-btn
            color="blue"
            class="mr-3"
            @click="addCreditCheck()"
          >
            Buy Credit
          </v-btn>
          <v-btn
            color="black"
            @click="creditDialogProdigy = false"
          >
            Close
          </v-btn>
        </v-overlay>
      </v-card>
        </v-dialog>






        <v-dialog
        persistent
      v-model="dialogImportGrant"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Import client from CSV
        </v-card-title>

        <v-card-text>
           <rotate-loader class="mt-3" :loading="loadingUpload" color="#1D75F4"></rotate-loader>
           <h3 v-if="loadingUpload"> Importing...</h3>
          <v-file-input
              show-size
              class="mt-15"
              
              :disabled="loadingUpload"
              v-model="uploadedDocumentCSV"
              accept="application/csv"
              prepend-icon="fa-paperclip"
              label="Upload CSV"
          ></v-file-input>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :disabled="loadingUpload"
            @click="uploadFiles()"
          >
            Upload
          </v-btn>
           <v-btn
            color="primary"
            text
            :disabled="loadingUpload"
            @click="dialogImportGrant = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-container>
    </v-main>
    
    </v-app>
</template>

<script>
function labelText(series) {
        var value = series.points(0).options('y'),
          fgg = value >= 750 ? 'Great!' : value >= 630 ? 'Good' : 'Fair';
        return '<span >%sum</span><br/><span >' + fgg + '</span>';
      }
import HVStateStepper from '../HVStateStepper';
import JSCharting, { JSC } from 'jscharting-vue';
import Chart from "chart.js/auto";
import ClipLoader from 'vue-spinner/src/PulseLoader.vue';
import VueJsonToCsv from 'vue-json-to-csv'
import RoundSlider from "vue-round-slider";
import moment from 'moment'
export default {
  components : {
   HVStateStepper,
   JSCharting,
   'RotateLoader': ClipLoader,VueJsonToCsv
    },
   data () {
      return {
        model: 'tab-2',
        switch1 : true,
        panel: 0,
        page_letter : 1,
        panel5: 0,
        itemsPerPage_letter: 10,
        selectedContract : '',
        loadingChat: false,
        doc_id_id : '',
        doc_bill_id :'',
        doc_letter_a_id :'',
        doc_letter_b_id :'',
        doc_letter_c_id: '',
        loadingFileId : false,
        loadingFileBill : false,
        loadingFileLetterA : false,
        loadingFileLetterB : false,
        loadingFileLetterC : false,
        email_parent : localStorage.getItem('email') ? localStorage.getItem('email') : '',
        phone_parent : localStorage.getItem('phone') ? localStorage.getItem('phone') : '',
        userContracts: [],
        tab: 1,
        last_letter : 0,
        conversation : {
          sender : '',
          receiver_user_id : '',
          receiver : '',
          subject : '',
          body : '',
          type : ''
        },
        datatable_options_letter: {},
        dialogSignContract : false,
        limit_letter: 10,
        newFilesUpload : [],
        panel3 : 0,
        panel4 : 0,
        options_pad: {
      penColor: "#00f",
    },
        wasabi_url : process.env.VUE_APP_WASBI_URL,
        grant : {
                first_name : "",
                last_name : '',
                middle_name: '',
                
                email : localStorage.getItem('temp_email') ? localStorage.getItem('temp_email') : '',
                is_email : false ,
                phone_h : '',
                phone_w : '',
                dob: '',
                is_exist : "",
                wasabi_url : process.env.VUE_APP_WASBI_URL.replace('/logo',''),
                ssn : '',
                phone_m : '',
                fax : '',
                address :'',
                address1: '',
                city : '',
                state : '',
                zip : '',
                country :'United States',
                memo : '',
                is_verified:  0,
                document : '',
                suffix:'',
                recaptcha: '',
                user_name: "",
                password: '',
                confirm_password: "",
                crc_id : "",
                driving_license_number : '',
                driving_license_state : "",
                process_email : "",
                process_email_password : "",
                is_grant :'no'

            },
        newFileName : '',
        headers_letter : [
           { text: 'Title', value: 'title',
            sortable: true },
          { text: 'Type', value: 'type',
            sortable: true },
            { text: 'Round', value: 'round',
            sortable: true },
          { text: 'Print Staus', value: 'print_status',
            sortable: true },
          { text: 'Created at', value: 'created_at',
            sortable: true,width: '200' },
          { text: 'Updated at', value: 'updated_at',
            sortable: true,width: '200' },
            // { text: 'Edit', value: 'edit',
            // sortable: true,width: '200' },
            { text: 'View', value: 'download',
            sortable: true,width: '200' },
          // { text: 'Delete', value: 'id',
          //   sortable: false,width: '50' },
        ],
        valid : true,
        uploadedDocumentCSV : [],
        loadingUpload : false,
        isDarkImport : true,
        totalCountAllGrants : 0,
        panel2: 1,
        conversations: [],
        totalCountInprogressGrants : 0,
        totalCountCompletedGrants : 0,
        dialogAssign2 : false,
        datatable_options_grants : {},
        address_select_sen : 'Custom',
        address_select_res : 'Custom',
        dialogImportGrant : false,
        datatable_options_grants_in : {},
        datatable_options_grants_com : {},
        product : {
          id : '',
          name : '',
          url : '',
          deadline : '',
          focus_area : '',
          location :'',
          value : 0
        },
        minDate: moment().format('YYYY-MM-DD'),
        settings : JSON.parse(localStorage.getItem('settings')),
        grant_count : 0,
        headers_grants : [
          { text: 'Grant Name', value: 'name',
            sortable: true },
          { text: 'Grant URL', value: 'url',
            sortable: true },
            {
               text: 'Deadline', value: 'deadline',
            sortable: true
            },
            {
               text: 'Focus Area', value: 'focus_area_name',
            sortable: true
            },{
               text: 'Location', value: 'location_name',
            sortable: true
            },
            {
               text: 'Value', value: 'value',
            sortable: true
            },
          { text: 'Timestamp', value: 'created_at',
            sortable: true,width: '200' },
            { text: 'Action', value: 'apply',
            sortable: true,width: '200' },
         ],
         headers_grants_in : [
          { text: 'Grant Name', value: 'name',
            sortable: true },
          { text: 'Grant URL', value: 'url',
            sortable: true },
            {
               text: 'Status', value: 'status',
            sortable: false
            },
          { text: 'Submitted At', value: 'submitted_date',
            sortable: true,width: '200' },
          
         ],
         headers_grants_com : [
          { text: 'Grant Name', value: 'name',
            sortable: true },
          { text: 'Grant URL', value: 'url',
            sortable: true },
            {
               text: 'Status', value: 'status',
            sortable: false
            },
            {
               text: 'Proof', value: 'proof',
            sortable: false
            },
          { text: 'Submitted At', value: 'submitted_date',
            sortable: true,width: '200' },
            { text: 'Completed At', value: 'completed_date',
            sortable: true,width: '200' },
         ],
        datatable_options_grants : [],
        allGrants : [],
        allGrantsIn : [],
        allGrantsCom : [],
        page_in : 1,
        page_com : 1,
        steps: 3, step: 1,
        import_from: 'Smart Credit',
        // isStepActive : "2",
        dialogStep: false,
        creditHistoryDialog : false,
        doc_count : 4,
        search: '',
        loading: false,
        addFileFlag: false,
        certifiedLetters : [],
        company_logo : localStorage.getItem('company_logo'),
        dialogUpload : false,
        limit : 10,
        applyGrantFlag : false,
        pageCount:1,
        pageCountIn:1,
        pageCountCom:1,
        backgroundLogo: localStorage.getItem('admin_bg'),
        itemsPerPage:10,
        avatar: '',
        creditFlagProdigy :false,
        creditDialogProdigy: false,
        letters : [],
        pageCountLetter : 0,
        selectedItemsToBeCompleted : '',
        selectedItemsCompleted : '',
        selectGrant : [],
        selectedItemsHistory : [],
        dialogAssign : false,
        src : "https://app.idealergy.com/v2/preview/t3AjaHbWdNQMrU5pWaxi?notrack=true",
        memoFlag : false,
        client: '',
        docs : [],
        datatable_options_history : {},
        page : 1,
        focusarea : [],
        locations: [],
        role: JSON.parse(localStorage.getItem('role')),
        tasks : [],
        doc_id_flag : false,
        doc_id_value : '',
        sendLetterDialog : false,
        rules: [
                value => !!value || 'Required.',
            ],
        moreDocuments : [],
        history : [],
        headers_history: [
          { text: 'History', value: 'history',
            sortable: true },
          { text: 'Transaction Id', value: 'transaction_id',
            sortable: true },
            {
               text: 'Gateway', value: 'transaction_from',
            sortable: false
            },
          { text: 'Created At', value: 'created_at',
            sortable: true,width: '200' },
          { text: 'Updated At', value: 'updated_at',
            sortable: true,width: '200' },
            

        ],
        doc_bill_flag : false,
        dialogImport2 : false,
        creditFlag2: false,
        loadingUpload2 :false,
        loadingUpload :false,
        contract : [],
        valid_letter : true,
         nameRules: [
        v => !!v || '',
      ],
        radioGroup : 1,
        credentials : [],
        selectedItems: [],
        selected_bot : '',
        checkbox : false,
        loadingSendLetter : false,
        docupost : {
          file : [],
          document_options : {
            color : 0,
            double_side : 0
          },
          mail_service: 'USPS First Class (3-10 days)',
          certified_option:'',
        },
        recipient : {
          name : '',
          address1 : '',
          address2: '',
          city : '',
          state : '',
          zip : ''
        },
        sender : {
          name : '',
          address1 : '',
          address2: '',
          city : '',
          state : '',
          zip : ''
        },
        documentCheck : [],
        selectedCountries: [],
        countries: [],
        isLoading: false,
        selectedFile : '',
        menu: false,
        dialog:false,
        loadingCustomeFile : false,
        reports: [],
        credit_available : 0,
        creditDialog:false,
        loadSignature: false,
        model_grant : '',
        search2 : '',
        search3 : '',
        creditFlag: 'add',
        importDialog: false,
        source_code: '',
        username : '',
        password : '',
        dialogDoc: false,
        loadingUpload3: false,
        value: null,
        loadingGrantApplication : false,
        options: ['list', 'of', 'options'],
        selected_id:'',
        new_credit: 0,
        loadingAssign: false,
        credit_used: 0,
        itemOptions: ['609','Inquires','609 and Inquires','Bankrupcy','Identity Thieft','Mailing Letters'],
        request: {
            process: "609",
            round: 1,
            recaptcha: ""
        },
        creditFlag :false,
        
        doc_bill_value : '',
        doc_letter_a_flag : false,
        doc_letter_a_value : '',
        doc_letter_b_flag : false,
        doc_letter_b_value : '',
        doc_letter_c_flag : false,
        doc_letter_c_value : '',
        width : 100,
        currentState : "",
        importDialog : false,
        dashboard  : {
          score_eq : 0,
          score_ex : 0,
          score_tu : 0,

        },
        isDisableImport : false,
         FinalData: {
          profile : {
                score : {
                  transunion : 0,
                  experian : 0,
                  equifax : 0,
                },
                report_date : {
                  transunion : '',
                  experian : '',
                  equifax : '',
                },
                name : {
                  transunion : '',
                  experian : '',
                  equifax : '',
                },
                known_as : {
                  transunion : '',
                  experian : '',
                  equifax : '',
                },
                birth : {
                  transunion : '',
                  experian : '',
                  equifax : '',
                },
                current_address: {
                  transunion : [],
                  experian : [],
                  equifax : [],
                },
                employer: {
                  transunion : [],
                  experian : [],
                  equifax : [],
                },
                previous_address : {
                  transunion : [],
                  experian : [],
                  equifax : [],
                },
          },
          credit_summary : {
                  transunion : {},
                  experian : {},
                  equifax : {},
                
          },
          credit_inquiries: [],
          public_record : [],
          account_history : []
        },
        isDarkImport : true,
        trade_flag : false,
        client : {progress  : 1},
        chartConsumption: "chart-consumption",
        chartOptionseq: {
           
              debug: false, 
              type: 'gauge ', 
              legend_visible: false, 
              chartArea_boxVisible: false, 
              xAxis: { 
                /*Used to position marker on top of axis line.*/
                scale: { range: [0, 1], invert: true } 
              }, 
              palette: { 
                pointValue: '%yValue', 
                ranges: [ 
                  { value: 350, color: '#FF5353' }, 
                  { value: 600, color: '#FFD221' }, 
                  { value: 700, color: '#77E6B4' }, 
                  { value: [800, 850], color: '#21D683' } 
                ] 
              }, 
              yAxis: { 
                defaultTick: { padding: 13, enabled: false }, 
                customTicks: [600, 700, 800], 
                line: { 
                  width: 10, 
                  breaks_gap: 0.03, 
                  color: 'smartPalette'
                }, 
                scale: { range: [350, 850] } 
              }, 
              defaultSeries: { 
                opacity: 1, 
                shape: { 
                  label: { 
                    align: 'center', 
                    verticalAlign: 'middle'
                  } 
                } 
              }, 
              series: [ 
                { 
                  type: 'marker', 
                  name: 'Score', 
                  shape_label: { 
                    text: labelText, 
                    style: { fontSize: 25 } 
                  }, 
                  defaultPoint: { 
                    tooltip: '%yValue', 
                    marker: { 
                      outline: { 
                        width: 5, 
                        color: 'currentColor'
                      }, 
                      fill: 'white', 
                      type: 'circle', 
                      visible: true, 
                      size: 20 
                    } 
                  }, 
                  points: [[1, 0]] 
                } 
              ] 

        
         },
         chartOptionsex: {
           
              debug: false, 
              type: 'gauge ', 
              legend_visible: false, 
              chartArea_boxVisible: false, 
              xAxis: { 
                /*Used to position marker on top of axis line.*/
                scale: { range: [0, 1], invert: true } 
              }, 
              palette: { 
                pointValue: '%yValue', 
                ranges: [ 
                  { value: 350, color: '#FF5353' }, 
                  { value: 600, color: '#FFD221' }, 
                  { value: 700, color: '#77E6B4' }, 
                  { value: [800, 850], color: '#21D683' } 
                ] 
              }, 
              yAxis: { 
                defaultTick: { padding: 13, enabled: false }, 
                customTicks: [600, 700, 800], 
                line: { 
                  width: 10, 
                  breaks_gap: 0.03, 
                  color: 'smartPalette'
                }, 
                scale: { range: [350, 850] } 
              }, 
              defaultSeries: { 
                opacity: 1, 
                shape: { 
                  label: { 
                    align: 'center', 
                    verticalAlign: 'middle'
                  } 
                } 
              }, 
              series: [ 
                { 
                  type: 'marker', 
                  name: 'Score', 
                  shape_label: { 
                    text: labelText, 
                    style: { fontSize: 25 } 
                  }, 
                  defaultPoint: { 
                    tooltip: '%yValue', 
                    marker: { 
                      outline: { 
                        width: 5, 
                        color: 'currentColor'
                      }, 
                      fill: 'white', 
                      type: 'circle', 
                      visible: true, 
                      size: 20 
                    } 
                  }, 
                  points: [[1, 0]] 
                } 
              ] 

        
         },
         chartOptionstu: {
           
              debug: false, 
              type: 'gauge ', 
              legend_visible: false, 
              chartArea_boxVisible: false, 
              xAxis: { 
                /*Used to position marker on top of axis line.*/
                scale: { range: [0, 1], invert: true } 
              }, 
              palette: { 
                pointValue: '%yValue', 
                ranges: [ 
                  { value: 350, color: '#FF5353' }, 
                  { value: 600, color: '#FFD221' }, 
                  { value: 700, color: '#77E6B4' }, 
                  { value: [800, 850], color: '#21D683' } 
                ] 
              }, 
              yAxis: { 
                defaultTick: { padding: 13, enabled: false }, 
                customTicks: [600, 700, 800], 
                line: { 
                  width: 10, 
                  breaks_gap: 0.03, 
                  color: 'smartPalette'
                }, 
                scale: { range: [350, 850] } 
              }, 
              defaultSeries: { 
                opacity: 1, 
                shape: { 
                  label: { 
                    align: 'center', 
                    verticalAlign: 'middle'
                  } 
                } 
              }, 
              series: [ 
                { 
                  type: 'marker', 
                  name: 'Score', 
                  shape_label: { 
                    text: labelText, 
                    style: { fontSize: 25 } 
                  }, 
                  defaultPoint: { 
                    tooltip: '%yValue', 
                    marker: { 
                      outline: { 
                        width: 5, 
                        color: 'currentColor'
                      }, 
                      fill: 'white', 
                      type: 'circle', 
                      visible: true, 
                      size: 20 
                    } 
                  }, 
                  points: [[1, 0]] 
                } 
              ] 

        
         },
        grantStates : [],
        testStates: [
      { name: 'Customer Details',current: false,done : false },
      { name: 'Import Report',current: false,done : false },
      { name: 'Required Documents',current: false,done : false },
      { name: 'Agreement Signed',current: false,done : false },
      { name: 'Payment Made',current: false,done : false },
      { name: 'Resolution Round 1',current: false,done : false },
      { name: 'Resolution Round 2',current: false,done : false },
      { name: 'Resolution Round 3',current: false,done : false },
      { name: 'Resolution Round 4',current: false,done : false },
    ],
    dialogDoc : false,
        role: JSON.parse(localStorage.getItem('role')),
        tasks : [],
        doc_id_flag : false,
        src: "https://app.idealergy.com/v2/preview/t3AjaHbWdNQMrU5pWaxi?notrack=true",
        doc_id_value : '',
        doc_bill_flag : false,
        dialog:false,
        dialogImport2 : false,
        dialogImport : false,
        loadingUpload : false,
        loadingUpload2 :false,
        loadingImport : false,
        isDarkImport : true,
        selected_id:'',
        selected_bot : '',
        selectedItems:[],
        sitekey: process.env.MIX_RECAPTCHA_SITE_KEY,
        id: this.$route.params.id,
        selectedFile : '',
        selectedFileProof :'',
        credit_available : 0,
        creditDialog:false,
        source_code:'',
        creditFlag: 'add',
      address_list_res : [
      {
          name : "Custom",
          address_1 : "",
          address_2 : "",
          city : "",
          state : "",
          zip : ""

        },
        {
          name : "TransUnion Consumer Solutions",
          address_1 : "P.O. Box 2000",
          address_2 : "",
          city : "Chester",
          state : "PA",
          zip : "19016"

        },
        {
          name : "Equifax Information Services LLC",
          address_1 : "P.O. Box 740256",
          address_2 : "",
          city : "Atlanta",
          state : "GA",
          zip : "30374"

        },
        {
          name : "Experian",
          address_1 : "P.O. Box 4500",
          address_2 : "",
          city : "Allen",
          state : "TX",
          zip : "75013"

        },
      ],
      address_list_sen : [
      {
          name : "Custom",
          address_1 : "",
          address_2 : "",
          city : "",
          state : "",
          zip : ""

        },
        {
          name : "TransUnion Consumer Solutions",
          address_1 : "P.O. Box 2000",
          address_2 : "",
          city : "Chester",
          state : "PA",
          zip : "19016"

        },
        {
          name : "Equifax Information Services LLC",
          address_1 : "P.O. Box 740256",
          address_2 : "",
          city : "Atlanta",
          state : "GA",
          zip : "30374"

        },
        {
          name : "Experian",
          address_1 : "P.O. Box 4500",
          address_2 : "",
          city : "Allen",
          state : "TX",
          zip : "75013"

        },
      ],
        new_credit: 0,
        reports: [],
        username : '',
        password : '',
        loadingAssign: false,
        credentials : '',
        credit_used: 0,
        importDialog: false,
        loadingAssign2 : false,
        stateOptions: [
                { name: 'ALABAMA', abbreviation: 'AL'},
                { name: 'ALASKA', abbreviation: 'AK'},
                { name: 'AMERICAN SAMOA', abbreviation: 'AS'},
                { name: 'ARIZONA', abbreviation: 'AZ'},
                { name: 'ARKANSAS', abbreviation: 'AR'},
                { name: 'CALIFORNIA', abbreviation: 'CA'},
                { name: 'COLORADO', abbreviation: 'CO'},
                { name: 'CONNECTICUT', abbreviation: 'CT'},
                { name: 'DELAWARE', abbreviation: 'DE'},
                { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC'},
                { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM'},
                { name: 'FLORIDA', abbreviation: 'FL'},
                { name: 'GEORGIA', abbreviation: 'GA'},
                { name: 'GUAM', abbreviation: 'GU'},
                { name: 'HAWAII', abbreviation: 'HI'},
                { name: 'IDAHO', abbreviation: 'ID'},
                { name: 'ILLINOIS', abbreviation: 'IL'},
                { name: 'INDIANA', abbreviation: 'IN'},
                { name: 'IOWA', abbreviation: 'IA'},
                { name: 'KANSAS', abbreviation: 'KS'},
                { name: 'KENTUCKY', abbreviation: 'KY'},
                { name: 'LOUISIANA', abbreviation: 'LA'},
                { name: 'MAINE', abbreviation: 'ME'},
                { name: 'MARSHALL ISLANDS', abbreviation: 'MH'},
                { name: 'MARYLAND', abbreviation: 'MD'},
                { name: 'MASSACHUSETTS', abbreviation: 'MA'},
                { name: 'MICHIGAN', abbreviation: 'MI'},
                { name: 'MINNESOTA', abbreviation: 'MN'},
                { name: 'MISSISSIPPI', abbreviation: 'MS'},
                { name: 'MISSOURI', abbreviation: 'MO'},
                { name: 'MONTANA', abbreviation: 'MT'},
                { name: 'NEBRASKA', abbreviation: 'NE'},
                { name: 'NEVADA', abbreviation: 'NV'},
                { name: 'NEW HAMPSHIRE', abbreviation: 'NH'},
                { name: 'NEW JERSEY', abbreviation: 'NJ'},
                { name: 'NEW MEXICO', abbreviation: 'NM'},
                { name: 'NEW YORK', abbreviation: 'NY'},
                { name: 'NORTH CAROLINA', abbreviation: 'NC'},
                { name: 'NORTH DAKOTA', abbreviation: 'ND'},
                { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP'},
                { name: 'OHIO', abbreviation: 'OH'},
                { name: 'OKLAHOMA', abbreviation: 'OK'},
                { name: 'OREGON', abbreviation: 'OR'},
                { name: 'PALAU', abbreviation: 'PW'},
                { name: 'PENNSYLVANIA', abbreviation: 'PA'},
                { name: 'PUERTO RICO', abbreviation: 'PR'},
                { name: 'RHODE ISLAND', abbreviation: 'RI'},
                { name: 'SOUTH CAROLINA', abbreviation: 'SC'},
                { name: 'SOUTH DAKOTA', abbreviation: 'SD'},
                { name: 'TENNESSEE', abbreviation: 'TN'},
                { name: 'TEXAS', abbreviation: 'TX'},
                { name: 'UTAH', abbreviation: 'UT'},
                { name: 'VERMONT', abbreviation: 'VT'},
                { name: 'VIRGIN ISLANDS', abbreviation: 'VI'},
                { name: 'VIRGINIA', abbreviation: 'VA'},
                { name: 'WASHINGTON', abbreviation: 'WA'},
                { name: 'WEST VIRGINIA', abbreviation: 'WV'},
                { name: 'WISCONSIN', abbreviation: 'WI'},
                { name: 'WYOMING', abbreviation: 'WY' }
            ],
        itemOptions: ['609','Inquires','609 and Inquires','Bankrupcy','Identity Thieft','Mailing Letters'],
        request: {
            process: "609",
            round: 1,
            recaptcha: ""
        },
        creditFlag :false,
        
        doc_bill_value : '',
        doc_letter_a_flag : false,
        doc_letter_a_value : '',
        doc_letter_b_flag : false,
        doc_letter_b_value : '',
        doc_letter_c_flag : false,
        doc_letter_c_value : '',
      }
    },
    computed: {
    documentCheckAll : {
      set(val) {
        this.documentCheck  = []
        if (val) {
           this.documentCheck.push('id');
           this.documentCheck.push('bill');
           this.documentCheck.push('letter_1');
           this.documentCheck.push('letter_2');
           this.documentCheck.push('letter_3');
          for(let i = 1; i <= this.moreDocuments.length; i++) {
            let id = 3 + i;
            this.documentCheck.push('letter_'+id)
          }
        }
      },
      get() {
        console.log(this.documentCheck.length);
        console.log(this.moreDocuments.length + 5);
        // if(this.documentCheck.length !== (this.moreDocuments.length + 5))
        //   this.documentCheckAll = [];
        // return this.documentCheck.length != 0 && this.documentCheck.length === (this.moreDocuments.length + 5)
      }
    },
    
  },
    methods : {
      
      generateChart(){
        new Chart(document.getElementById(this.chartConsumption).getContext("2d"), {
             
  debug: true, 
  type: 'gauge ', 
  legend_visible: false, 
  chartArea_boxVisible: false, 
  xAxis: { 
    /*Used to position marker on top of axis line.*/
    scale: { range: [0, 1], invert: true } 
  }, 
  palette: { 
    pointValue: '%yValue', 
    ranges: [ 
      { value: 350, color: '#FF5353' }, 
      { value: 600, color: '#FFD221' }, 
      { value: 700, color: '#77E6B4' }, 
      { value: [800, 850], color: '#21D683' } 
    ] 
  }, 
  yAxis: { 
    defaultTick: { padding: 13, enabled: false }, 
    customTicks: [600, 700, 800], 
    line: { 
      width: 15, 
      breaks_gap: 0.03, 
      color: 'smartPalette'
    }, 
    scale: { range: [350, 850] } 
  }, 
  defaultSeries: { 
    opacity: 1, 
    shape: { 
      label: { 
        align: 'center', 
        verticalAlign: 'middle'
      } 
    } 
  }, 
  series: [ 
    { 
      type: 'marker', 
      name: 'Score', 
      shape_label: { 
        text: 
          "720<br/> <span style='fontSize: 35'>Great!</span>", 
        style: { fontSize: 48 } 
      }, 
      defaultPoint: { 
        tooltip: '%yValue', 
        marker: { 
          outline: { 
            width: 10, 
            color: 'currentColor'
          }, 
          fill: 'white', 
          type: 'circle', 
          visible: true, 
          size: 30 
        } 
      }, 
      points: [[1, 720]] 
    } 
  ] 

          });
      },
      getAddressData: function (addressData, placeResultData, id) {
            this.grant.grant_address = addressData.name;
        },
        getAddressData2: function (addressData2, placeResultData, id) {
            this.grant.grant_business_address = addressData2.name;
        },
      creditCheck(){
        this.getUserProfileParent();
        

      },
      creditCheckProdigy(){
        let self = this;
        let header = { headers : {
                    Authorization:"Bearer " + localStorage.getItem('token')
                    }
            }
          axios
            .get("/api/user",header)
            .then(response => {
                let data = response.data;

                  this.user = data.user;
                  let settings = localStorage.getItem('settings');
                  settings = JSON.parse(settings);
                  this.setting = settings;
                  let item = 1;
                 
                  if(parseInt(this.user.credit) < (parseInt(settings.request_charge) * item) && this.role.name != 'super_admin'){
                    this.creditFlagProdigy = true;
                    this.creditDialogProdigy = true;
                    
                  }else{
                    if (confirm('Are you sure, You want to push this client to Prodigy?')) {
                        this.loadingUpload3 = true;
                                    axios
                                .post("/api/prodigy-send-client",{id : this.id},this.header)
                                .then(response => {
                                    let data = response.data;

                                    if (response.data) {
                                        Vue.$toast.success('Client pushed to prodigy successfully', {
                                            position: 'top-right'
                                            });
                                            this.getUserProfile();
                                            this.loadingUpload3 = false;
                                        }else {
                                            Vue.$toast.error(data.message, {
                                            position: 'top-right'
                                            });
                                        }

                                    })
                                    .catch(err => {
                                    Vue.$toast.error(err, {
                                    position: 'top-right'
                                    });
                                    })
                                  }
                    
                  }
                

            })
              .catch(err => {
                Vue.$toast.error(err, {
                position: 'top-right'
                });

              });
      },
      onCaptchaVerified: function (recaptchaToken) {
        this.request.recaptcha = recaptchaToken
        this.validateCaptcha = true
        },
        onCaptchaExpired: function () {
        this.$refs.recaptcha.reset();
        this.request.recaptcha = ''
        },
    onButtonClick(id) {
      this.$refs[id].click()
      this.selectedFile = id;
    },
    onButtonClickProof(id) {
      this.$refs['file_proof_'+id].click()
      this.selectedFileProof = id;
    },
    getScore(item,flag){
      
      let data = JSON.parse(item);
      if(flag == 'tu')
        return data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.CreditScore[0].riskScore
      if(flag == 'ex')
        return data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.CreditScore[1].riskScore
      if(flag == 'eq')
        return data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.CreditScore[2].riskScore
    },
    applyGrant(item){
      if(confirm('Are you sure, You want to apply for this grant?')){
        this.loading = true;
        axios
              .post("/api/apply-grant",{user_id : this.$route.params.id,grant_id : item.id},this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {

                if(response.data.flag == 1){
                  Vue.$toast.success('You have applied successfully', {
                      position: 'top-right'
                      });
                      this.loading = false;
                    this.getUsers();
                  }else{
                      Vue.$toast.error(response.data.message, {
                      position: 'top-right'
                      });
                  }
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
      }
    },
    viewLetter(item){
      window.open(process.env.VUE_APP_WASBI_URL+'letters/'+item.id+'_'+item.type+'.pdf', '_blank');
    },
    printRound(item){
      window.open(process.env.VUE_APP_WASBI_URL+'letters/'+item.credit_report_id+'.pdf', '_blank');
    },
    redoRound(item){
      if(confirm('Are you sure, You want to redo the round?')){
        this.$router.push('/admin/credit-report/'+item.credit_report_id);
      }
    },
    renderImport(){
      if(this.import_from=="Smart Credit"){
        this.username = this.client.smart_credit_user_name
        this.password = atob(this.client.smart_credit_password)
      }else{
        this.username = this.client.my_free_score_user_name
        this.password = atob(this.client.my_free_score_password)
      }
    },
    getUserProfileParent() {
        let flag = 1;
        let self = this;
        self.loading = true;
        let header = { headers : {
                    Authorization:"Bearer " + localStorage.getItem('token')
                    }
            }
          axios
            .get("/api/user",header)
            .then(response => {
                let data = response.data;

              if (flag == 1) {
                  this.user = data.user;
                  let settings = localStorage.getItem('settings');
                  settings = JSON.parse(settings);
                  this.setting = settings;
                  let item = 1;
                 
                  if(parseInt(this.user.credit) < (parseInt(settings.request_charge) * item)){
                    this.creditFlag = true;
                    this.dialogImport2 = true;
                    
                  }else{
                    this.dialogImport2 = true;
                    this.creditFlag = false;
                    
                  }
                  self.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                    self.loading = false;
                }

            })
              .catch(err => {
                Vue.$toast.error(err, {
                position: 'top-right'
                });

              });

        },
        viewFile(file){
          console.log(file);
            window.open(process.env.VUE_APP_WASBI_URL+file, '_blank');
        },
        getFormattedDate(date){
          return moment(date).format('MM/DD/YYYY');
        },
        getDateTime(date){
          return moment(date).format('LLLL');
        },
        clientLogin(){
          if(confirm('Are you sure, You want to login as a client?')){
            axios({
              method: "POST",
              
              url: "/api/auth/login-admin-client",
              data: {
                email: this.client.email              }
            })
              .then(response => {
                let data = response.data;
                if (data.flag == 1) {
                  let result = data.data;
                  localStorage.setItem("from_admin",1);
                  localStorage.setItem("from_admin_email",localStorage.getItem('email'));
                  localStorage.setItem("token", data.token.token);
                  localStorage.setItem("user_id", result.user_id);
                  localStorage.setItem("email", result.email);
                  localStorage.setItem("phone", result.phone_m);
                  localStorage.setItem("name", result.first_name+' '+result.last_name);
                  localStorage.setItem("document_flag",response.data.doc_flag);
                  localStorage.setItem("agreement_flag",result.agreement_flag);
                  localStorage.setItem("is_admin",0);
                  localStorage.setItem("role", JSON.stringify({name : 'client'}));
                  
                  if(localStorage.getItem('temp_request_id'))
                    this.$router.push("/restart-process/"+localStorage.getItem('temp_request_id'));
                    this.$router.push("/dashboard");
                  location.reload();
                } else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }
              })
              .catch(err => {
                Vue.$toast.error(err, {
                position: 'top-right'
                });
              });
          }
        },
        uploadFiles(){
            let self = this;
            let flag = 1;
            if(self.uploadedDocumentCSV.length == 0){
                Vue.$toast.error('Select document', {
                position: 'top-right'
                });
                flag = 0;
            }
            else{
                self.loadingUpload = true;
                const data = new FormData();
                data.append('client_csv', self.uploadedDocumentCSV);
                axios({
                method: "POST",
                url: "/api/import-grant-csv",
                headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
                data: data
                })
                .then(response => {
                console.log(response);
                self.loadingUpload = false;
                 Vue.$toast.success('Grants imported successfully', {
                            position: 'top-right'
                            });
                self.getUsers();
                self.uploadedDocumentCSV = [];
                self.dialogImportGrant = false;
                // this.isDisableUpload = false;
                // this.isDarkUpload = true;
                // self.e1 = 3;
                
                })
                .catch(err => {
                    Vue.$toast.error("Invalid file to upload", {
                    position: 'top-right'
                    });
                    self.loadingUpload = false;
                    // this.isDisableUpload = false;
                    // this.isDarkUpload = true;
                });
            }
        },
        sendEmail (flag){
      let self = this;
      let items = [];
      if(confirm('Are you sure, You want to send email with all documents?')){
      if(flag == 1){
        items = self.docs;
      }else{console.log(this.docs);
        this.docs.forEach(val => {
          console.log(val.file_name);
          console.log(self.documentCheck);
          if(val.file_name.includes('ID') && self.documentCheck.includes('id'))
            items.push(val)
          else if(val.file_name.includes('Bill') && self.documentCheck.includes('bill'))
            items.push(val)
          else if(val.file_name.includes('Letter_A') && self.documentCheck.includes('letter_1'))
            items.push(val)
          else if(val.file_name.includes('Letter_B') && self.documentCheck.includes('letter_2'))
            items.push(val)
          else if(val.file_name.includes('Letter_C') && self.documentCheck.includes('letter_3'))
            items.push(val)
          else if(self.documentCheck.includes(val.file_name.split('(')[0]))
            items.push(val)
        })
      }
      self.loadingImport = true;
      self.loadingUpload = true;
      axios.post("/api/send-client-email",{client_id : this.$route.params.id ,items : items},this.header)
                .then(response => {
                  self.loadingImport = false
                  self.loadingUpload = false
                  Vue.$toast.success('Email sent successfully', {
                            position: 'top-right'
                            });

                });
                
      }
    },
    renderAddress(flag){
      if(flag == 'res'){
        this.address_list_res.forEach(val=>{
          if(val.name == this.address_select_res){
            this.recipient.name = val.address_1;
            this.recipient.address1 = val.address_1;
            this.recipient.address2 = val.address_2;
            this.recipient.city = val.city;
            this.recipient.state = val.state;
            this.recipient.zip = val.zip;
          }
        })
      }else{
        this.address_list_sen.forEach(val=>{
          if(val.name == this.address_select_sen){
            this.sender.name = val.address_1;
            this.sender.address1 = val.address_1;
            this.sender.address2 = val.address_2;
            this.sender.city = val.city;
            this.sender.state = val.state;
            this.sender.zip = val.zip;
          }
        })
      }
    },
    prodigyPost(){
      this.creditCheckProdigy()
        
      
    },
    markCompeted(){
      if (confirm('Are you sure, You want to mark as completed?')) {
                    axios
                .post("/api/marked-completed-grant",{ids : this.selectedItems},this.header)
                .then(response => {
                    let data = response.data;

                    if (response.data) {
                        Vue.$toast.success('Grant status changed successfully', {
                            position: 'top-right'
                            });
                            this.getInGrants();
                        }else {
                            Vue.$toast.error(data.message, {
                            position: 'top-right'
                            });
                        }

                    })
                    .catch(err => {
                    Vue.$toast.error(err, {
                    position: 'top-right'
                    });
                    })
                
            }
    },
sendLetter(){
      let self = this;
      
      if(this.docupost.file.length == 0){
        Vue.$toast.error("Document is required! ", {
          position: 'top-right'
          });
      }else if(this.$refs.form.validate()){
        self.loadingSendLetter = true;
        const data = new FormData();
        data.append('file_letter', this.docupost.file);
        data.append('docupost', JSON.stringify(this.docupost));
        data.append('sender', JSON.stringify(this.sender));
        data.append('recipient', JSON.stringify(this.recipient));
        data.append('user_id', this.$route.params.id);
        axios({
          method: "POST",
          url: "/api/send-letter-client",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: data
          })
          .then(response => { 
            self.loadingSendLetter = false;
            if(response.data.flag == 1){
               Vue.$toast.success('Your letter has been submitted', {
                    position: 'top-right'
                    });
                    this.$refs.form.reset()
                    this.docupost = {
                        file : [],
                        document_options : {
                          color : 0,
                          double_side : 0
                        },
                        mail_service: 'USPS First Class (3-10 days)',
                        certified_option:'',
                      }
            }else{
                    Vue.$toast.error(response.data.status, {
                    position: 'top-right'
                    });
            }
          })
           .catch(err => {
            self.loadingSendLetter = false;
                    Vue.$toast.error(err, {
                    position: 'top-right'
                    });
          })
      }
    },
    submitGrantApplication(){
      const data = new FormData();
      console.log(this.grant);
      data.append('user_id', this.$route.params.id);
      data.append('file_1', this.grant.file_1);
      data.append('file_2', this.grant.file_2);
      data.append('grant',JSON.stringify(this.grant));
      this.loadingGrantApplication = true
      axios({
      method: "POST",
      url: "/api/submit-grant-application",
      data:data,
      headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
      })
      .then(response => {
        this.loadingGrantApplication = false
          if (response.data) {
                        Vue.$toast.success(' Application submitted successfully', {
                            position: 'top-right'
                            });
          }
      })
      .catch(err => {
        this.loadingGrantApplication = false
          Vue.$toast.error(err, {
          position: 'top-right'
          });
      })
    },
    loadSig(){
      let self = this
      setTimeout(function(){
        self.loadSignature = true
      },1000)
      
    },
    viewContract(id){
      let url = process.env.VUE_APP_WASBI_URL
      window.open(url+this.id+'_'+id+'.pdf', '_blank');
    },
     StartProcess1(flag){
          let id = this.selected_id;
          let multiple_flag = 0;
          if(id == 'all')
            multiple_flag = 1
          var r = confirm("Are you sure, You went to start process 1?");
            if (r == true) {

              this.loadingUpload2 = true;
              this.dialogDoc = false;
                    axios
                .post("/api/process1/"+id+"/"+flag,{multiple_flag : multiple_flag, ids : this.selectedItems},this.header)
                .then(response => {
                    let data = response.data;

                    if (response.data) {
                        Vue.$toast.success('Process started successfully', {
                            position: 'top-right'
                            });
                            this.loadingUpload2 = false;
                            this.getUserProfile();
                        }else {
                            Vue.$toast.error(data.message, {
                            position: 'top-right'
                            });
                            this.loadingUpload2 = false;
                        }

                    })
                    .catch(err => {
                    Vue.$toast.error(err, {
                    position: 'top-right'
                    });
                    })
                
            }else{
                return false;
            }
        },
        submitContract(){
        let data = this.$refs.signaturePad.saveSignature();
        if(!data.data){
          Vue.$toast.error('Please sign to submit the contract', {
                    position: 'top-right'
                    });
        }else{
          axios({
          method: "POST",
          url: "/api/submit-user-contract/"+this.id,
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: {
            data : data,
            template : this.selectedContract.id
          }
          })
          .then(response => { 
            if(response.data.flag == 1){
               Vue.$toast.success('Your Signed Contract has been submitted', {
                    position: 'top-right'
                    });
                    this.getContracts();
                    this.dialogSignContract = false;
                  }

                })
                
        }
      },
      undo() {
      this.$refs.signaturePad.undoSignature();
    },
    removeContract(id) {
      if(confirm('Are you sure? You want to remove.')){
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/remove-user-contract",
          data: {
            user_id : this.id,
            template : id
          }
        })
        
          .then(response => {
              let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Contract removed successfully', {
                      position: 'top-right'
                      });
                      this.userContracts = [];
                      this.getContracts()
                    }
                  })
      }
    },
        addGrant(){
        
        if(!this.product.name){
        Vue.$toast.error("Name is required! ", {
          position: 'top-right'
          });
      }else if(!this.product.url){
        Vue.$toast.error("URL is required! ", {
          position: 'top-right'
          });
      }else{
        this.loadingAssign2 = true;
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/add-new-grant",
          data: this.product
        })
        
          .then(response => {
              let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Grant added successfully', {
                      position: 'top-right'
                      });
                      this.dialogAssign2 = false;
                      this.loadingAssign2 = false;
                      this.getUsers();
                      this.product.name = '';
                      this.product.url = '';
                      this.product.deadline = '';
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
              .catch(err => {
              Vue.$toast.error('An active promotion code with `code: '+this.promo.name+'` already exists.', {
              position: 'top-right'
              });
              this.loadingAssign = false;
              })
          }
        },
        addCreditCheck(){
          if(this.credentials.type == 'Jotform')
            this.dialogAssign = true
          else
            this.$router.push('/admin/pricing');
        },
    StartProcess1(flag){
          let id = this.selected_id;
          let multiple_flag = 0;
          if(id == 'all')
            multiple_flag = 1
          var r = confirm("Are you sure, You went to start process 1?");
            if (r == true) {

              this.loadingUpload2 = true;
              this.dialogDoc = false;
                    axios
                .post("/api/process1/"+id+"/"+flag,{multiple_flag : multiple_flag, ids : this.selectedItems},this.header)
                .then(response => {
                    let data = response.data;

                    if (response.data) {
                        Vue.$toast.success('Process started successfully', {
                            position: 'top-right'
                            });
                            this.loadingUpload2 = false;
                            this.getUserProfile();
                        }else {
                            Vue.$toast.error(data.message, {
                            position: 'top-right'
                            });
                            this.loadingUpload2 = false;
                        }

                    })
                    .catch(err => {
                    Vue.$toast.error(err, {
                    position: 'top-right'
                    });
                    })
                
            }else{
                return false;
            }
        },
        importReport(){
          axios({
          method: "POST",
          url: "/api/import-report",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: {source: this.source_code}
          })
          .then(response => {
          console.log(response);
         
          })
          .catch(err => {
              Vue.$toast.error("Invalid file to upload", {
              position: 'top-right'
              });
              
          });
        },
        addNewFile(){
      let id = 4;
      this.addFileFlag = true;
    },
    onFileChanged(e) {
      let self = this;
      let selectedFile = e.target.files[0]
      // if(!selectedFile.name.includes(self.client.email)){
      //   Vue.$toast.error("Document should contain user email", {
      //         position: 'top-right'
      //         });
      // }else{
      const data = new FormData();
          if(self.selectedFile == 'file_id'){
            data.append('file_id', selectedFile);
            this.loadingFileId = true;
          }if(self.selectedFile == 'file_bill'){
            data.append('file_bill', selectedFile);
            this.loadingFileBill = true;
          }if(self.selectedFile == 'file_letter_a'){
            data.append('file_letter_a', selectedFile);
            this.loadingFileLetterA = true;
          }if(self.selectedFile == 'file_letter_b'){
            data.append('file_letter_b',selectedFile);
            this.loadingFileLetterB = true;
          }if(self.selectedFile == 'file_letter_c'){
            data.append('file_letter_c',selectedFile);
            this.loadingFileLetterC = true;
          }
          data.append('flag',self.selectedFile.replace('file_', ''));
          data.append('user_id',this.$route.params.id);
          axios({
          method: "POST",
          url: "/api/upload-file",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: data
          })
          .then(response => {
          console.log(response);
          if(self.selectedFile == 'file_id'){
            self.loadingFileId = false;
          }if(self.selectedFile == 'file_bill'){
            self.loadingFileBill = false;
          }if(self.selectedFile == 'file_letter_a'){
            self.loadingFileLetterA = false;
          }if(self.selectedFile == 'file_letter_b'){
            self.loadingFileLetterB = false;
          }if(self.selectedFile == 'file_letter_c'){
            self.loadingFileLetterC = false;
          }
          Vue.$toast.success("File uploaded", {
              position: 'top-right'
              });
          self.getUserProfile();
          })
          .catch(err => {
              Vue.$toast.error("Invalid file to upload", {
              position: 'top-right'
              });
              this.isDisableUpload = false;
              this.isDarkUpload = true;
          });
      // }
      
      // do something
    },
    onFileChangedProof(e) {
      let self = this;
      let selectedFile = e.target.files[0]
      const data = new FormData();
        data.append('file_proof',selectedFile);
        data.append('id',this.selectedFileProof);
          axios({
          method: "POST",
          url: "/api/upload-file-proof",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: data
          })
          .then(response => {
          console.log(response);
          Vue.$toast.success("File uploaded", {
              position: 'top-right'
              });
          self.getComGrants();
          })
          .catch(err => {
              Vue.$toast.error("Invalid file to upload", {
              position: 'top-right'
              });
              this.isDisableUpload = false;
              this.isDarkUpload = true;
          });
      // }
      
      // do something
    },
    getReportFromSmartCredit(){
          if(this.import_from == 'Smart Credit'){
          if(!this.username){
            Vue.$toast.error("Username is required! ", {
              position: 'top-right'
              });
          }else if(!this.password){
            Vue.$toast.error("Password is required! ", {
              position: 'top-right'
              });
          }else{
            this.loadingImport = true;
          this.isDisableImport = true;
          this.isDarkImport = false;
          axios({
          method: "POST",
          url: "/api/import-smart-credit",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: {username: this.username,password : this.password, user_id: this.id}
          })
          .then(response => {
          console.log(response);
          if(response.data.flag == 1){
            let id = response.data.value.id;
            this.loadingImport = false;
            this.$router.push('/credit-report/'+id)
            
          }else{
            Vue.$toast.error("Can't import as invalid credentials ", {
              position: 'top-right'
              });
              this.loadingImport = false;
              this.isDisableImport = false;
              this.isDarkImport = true;
          }
         
          })
          .catch(err => {
              Vue.$toast.error("Invalid file to upload", {
              position: 'top-right'
              });
              
          });
          }
        }else{
          this.getReportFromMyfreescorenow();
        }
        },
        getReportFromMyfreescorenow(){
          
          if(!this.username){
            Vue.$toast.error("Username is required! ", {
              position: 'top-right'
              });
          }else if(!this.password){
            Vue.$toast.error("Password is required! ", {
              position: 'top-right'
              });
          }else{
            this.loadingImport = true;
          this.isDisableImport = true;
          this.isDarkImport = false;
          axios({
          method: "POST",
          url: "/api/import-report-myfreescorenow",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: {username: this.username,password : this.password, user_id: this.id}
          })
          .then(response => {
          console.log(response);
          if(response.data.flag == 1){
            let id = response.data.value.id;
            this.loadingImport = false;
            this.$router.push('/credit-report/'+id)
            
          }else{
            Vue.$toast.error("Can't import as invalid credentials ", {
              position: 'top-right'
              });
              this.loadingImport = false;
              this.isDisableImport = false;
              this.isDarkImport = true;
          }
         
          })
          .catch(err => {
              Vue.$toast.error("Can't import as invalid credentials", {
              position: 'top-right'
              });
              this.loadingImport = false;
              this.isDisableImport = false;
              this.isDarkImport = true;
              
          });
          }
        },
    getCompanySettings(){
       axios({
          method: "POST",
          url: "/api/get-company-settings",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
        })
        .then(response => {
            let data = response.data.data;
            if(data){
              this.credentials = data.credentials;
            }
            })
    },
    getGHLChat(){
       axios({
          method: "POST",
          url: "/api/get-ghl-conversation",
          data : {client_id : this.$route.params.id},
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
        })
        .then(response => {
            let data = response.data.data;
            if(data){
              this.conversations = data;
            }
            })
    },
    getFocusArea(){
       axios({
          method: "POST",
          url: "/api/get-all-focus-area",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
        })
        .then(response => {
            let data = response.data.data;
            if(data){
              this.focusarea = data;
            }
            })
    },
    getLocation(){
       axios({
          method: "POST",
          url: "/api/get-all-location",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
        })
        .then(response => {
            let data = response.data.data;
            if(data){
              this.locations = data;
            }
            })
    },
    sendConversation(flag){
      this.conversation.type = flag;
      this.conversation.receiver_user_id = this.client.user_id;
      this.conversation.sender = flag == 'sms' ? this.phone_parent : this.email_parent
      this.conversation.receiver = flag == 'sms' ? this.client.phone_m :this.client.email;
      let self = this;
      if(!this.conversation.body){
            Vue.$toast.error("Message is required! ", {
              position: 'top-right'
              });
          }else{
      self.loadingChat = true;
    let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        } 
      axios
        .post("/api/send-conversation",this.conversation,header)
        .then(response => {
          self.loadingChat = false;
          self.getGHLChat();
          Vue.$toast.success('Message Sent', {
                position: 'top-right'
                });
                this. conversation =  {
                      sender : '',
                      receiver_user_id : '',
                      receiver : '',
                      subject : '',
                      body : '',
                      type : ''
                    }
        })
        .catch(err => {
          self.loadingChat = false;
            Vue.$toast.error('Unable to send', {
            position: 'top-right'
            });
          })
      }
    },
    renderHtml(html){
      if(html){
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = mm + '/' + dd + '/' + yyyy;
        html = html.replace("{client_suffix}",this.client.suffix);
        html = html.replace("{client_first_name}",this.client.first_name);
        html = html.replace("{client_middle_name}",(this.client.middle_name ? this.client.middle_name : ''));
        html = html.replace("{client_last_name}",this.client.last_name);
        html = html.replace("{client_address}",this.client.address_1+'<br>'+this.client.city+', '+this.client.state+' '+this.client.zip);
        html = html.replace("{client_previous_address}",this.client.address_1+'<br>'+this.client.city+', '+this.client.state+' '+this.client.zip);
        html = html.replace("{bdate}",this.client.dob);
        html = html.replace("{ss_number}",this.client.ssn);
        html = html.replace("{t_no}",this.client.phone_m);
        html = html.replace("{curr_date}",today);
        html = html.replace("{client_signature}",'');
      }

      return html;
    },
     getUserCredit() {
    let flag = 1;
    let self = this;

    let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
      axios
        .get("/api/user",header)
        .then(response => {
            let data = response.data;
            

           if (flag == 1) {
              localStorage.setItem('credit',data.user.credit);
              
            }else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
                self.loading = false;
            }

         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });

     },
      saveMemo(){
        let header = { headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  }
          }
          let id  = this.$route.params.id;
        axios
          .post("/api/save-client-memo",{
            memo : this.client.memo,
            user_id : id
          },header)
          .then(response => {
            this.memoFlag = false
          })
          .catch(err => {
                        Vue.$toast.error(err, {
                        position: 'top-right'
                        });
                    });
      },
      downloadFile(key){
      let self = this;
      let url = process.env.VUE_APP_WASBI_URL.replace('/logo','')
      this.docs.forEach(val =>{
        if(val.file_name == key){
          window.open(url+val.folder_id+'/'+val.file_name, '_blank');
        }
      })
                // axios({
                //     method: "POST",
                //     url: "/api/download-file",
                //     responseType: 'blob',
                //     headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
                //     data: {key : key,user_id : this.$route.params.id}
                //     })
                //     .then(response => {
                //       console.log(response);
                //     var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                //    var fileLink = document.createElement('a');

                //    fileLink.href = fileURL;
                //    fileLink.setAttribute('download', Date.now());
                //    document.body.appendChild(fileLink);

                //    fileLink.click();
                    
                //     self.isDisableDownload = false;
                //     self.isDarkDownload = true;
                //     })
                //     .catch(err => {
                //         Vue.$toast.error(err, {
                //         position: 'top-right'
                //         });
                //     });
      },
      submitRequest(){
            let self = this;
            let flag = 1;
            let url ='';

                  self.request.multiple_flag = 0
                  url = "/api/user-add-request-admin/"+this.selected_bot
                

                this.loadingUpload2 = true;
                axios({
                method: "POST",
                url: url,
                data:self.request,
                headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
                })
                .then(response => {
                    console.log(response);
                    if(response.data.success != "False"){
                        // self.$router.push('/thanks');
                        Vue.$toast.success('Request submitted', {
                            position: 'top-right'
                            }); 
                         
                            self.loadingUpload2 = false;
                            self.dialogImport2 = false;
                            self.getUserProfile();
                            localStorage.setItem('credit', response.data.new_credit)
                        // location.reload();
                    }else{
                        this.isDark = true;
                        this.isDisable = false;
                        Vue.$toast.error(response.data.result.errors.error_message, {
                            position: 'top-right'
                            }); 
                    }
                })
                .catch(err => {
                    // console.log(JSON.parse(err.response.data));
                    this.isDark = true;
                    this.isDisable = false;
                    let data = self.isJson(err.response.data) ? JSON.parse(err.response.data) : (err.response.data);
                    if(data.email){
                        Vue.$toast.error(data.email[0], {
                        position: 'top-right'
                        });
                    }else if(data.password){
                        Vue.$toast.error(data.password[0], {
                        position: 'top-right'
                        });
                    }
                    else if(data.user_name){
                        Vue.$toast.error(data.user_name[0], {
                        position: 'top-right'
                        });
                    }
                    else{
                        Vue.$toast.error(data, {
                        position: 'top-right'
                        });
                    }
                    self.onCaptchaExpired()

                });

        },
        onFileChangedLetter(e) {
       let selectedFile = e.target.files[0];
       this.docupost.file =  selectedFile;
    },
        addCredit(){
          let id = this.$route.params.id;
           var r = this.creditFlag == 'remove' ? confirm("Are you sure, You went to remove credit?") : confirm("Are you sure, You went to add credit?");
          if (r == true) {
            this.loadingAssign = true;
            axios
              .post("/api/add-credit-admin/"+id,{
                credit : this.new_credit,
                flag : this.creditFlag,
                client_flag : true
              },this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                if(this.creditFlag == 'remove'){
                  Vue.$toast.success('Credit removed successfully', {
                      position: 'top-right'
                      });
                }else{
                  Vue.$toast.success('Credit added successfully', {
                      position: 'top-right'
                      });
                }
                      this.creditDialog = false;
                      this.new_credit = 0;
                      this.loadingAssign = false;
                    this.getUserProfile();
                    this.getUserCredit()
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                      this.loadingAssign = false;
                  }

              })
            .catch(err => {
              Vue.$toast.error(err.response.data.message, {
              position: 'top-right'
              });
              this.loadingAssign = false;
            })
          }
        },
        getContracts(){
       axios({
          method: "GET",
          url: "/api/get-contract-template/"+this.id,
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
        })
        .then(response => {
            let data = response.data.value;
            if(data){
              this.contract = data;
            }
            })
    },
      getUserProfile() {
      let flag = 1;
      let header = { headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  }
          }
          let wasbi_url = process.env.VUE_APP_WASBI_URL;
          let id  = this.$route.params.id;
          let avatar_url = process.env.VUE_APP_WASBI_URL
        axios
          .get("/api/get-client/" + id,header)
          .then(response => {
              let data = response.data.data;
            if (flag == 1) {
              this.client = data;
              localStorage.setItem('company_logo',wasbi_url+data.logo)
              localStorage.setItem('company_id',data.company_id)
              this.tasks = response.data.task;
              this.credit_available = response.data.data.credit
              this.credit_used = response.data.credit_used
              if(response.data.contracts.length != 0){
                response.data.contracts.forEach(val => {
                  this.userContracts.push(val.contract_id);
                })
              }
              if(this.client.smart_credit_password)
                this.password = atob(this.client.smart_credit_password);
              if(this.client.smart_credit_user_name)
                this.username = this.client.smart_credit_user_name;
              this.reports = response.data.reports;
              this.docs = response.data.doc;
              console.log('sssdddd',this.docs);
              this.doc_count = response.data.doc.length;
              this.certifiedLetters = response.data.letters;
              this.grant_count = response.data.grant_count;
              
              this.avatar = response.data.data.avatar ? avatar_url+response.data.data.avatar : ''
              if(data.is_grant)
                this.grant = JSON.parse(data.grant_details);
              console.log('asdasd',this.grant);

              if(this.reports.length != 0){
                this.assignData(this.reports[0]);
              }
               if(response.data.doc.length != 0){
                response.data.doc.forEach(element => {
                  if(element.file_name.includes("ID")){
                    this.doc_id_flag = true;
                    this.doc_id_value = element.file_name
                    this.doc_id_id = element.id
                  }
                  else if(element.file_name.includes("Bill")){
                    this.doc_bill_flag = true;
                    this.doc_bill_value = element.file_name
                    this.doc_bill_id = element.id
                  }
                  else if(element.file_name.includes("Letter_A")){
                    this.doc_letter_a_flag = true;
                    this.doc_letter_a_value = element.file_name
                    this.doc_letter_a_id = element.id
                  }
                  else if(element.file_name.includes("Letter_B")){
                    this.doc_letter_b_flag = true;
                    this.doc_letter_b_value = element.file_name
                    this.doc_letter_b_id = element.id
                  }
                  else if(element.file_name.includes("Letter_C")){
                    this.doc_letter_c_flag = true;
                    this.doc_letter_c_value = element.file_name
                    this.doc_letter_c_id = element.id
                  }else{
                    if(this.moreDocuments.length != 0){
                      let item_exist = false;
                      let index = 0;
                      this.moreDocuments.forEach((val,key) => {
                        if(val.id == element.file_name.match(/\d+/)[0]){
                          item_exist = true;
                          index = key;
                        }
                      })
                      if(item_exist){
                          this.moreDocuments[index].file_content = element.file_name;
                        }else{
                           this.moreDocuments.push({
                            id : element.file_name.match(/\d+/)[0],
                            file_id : element.id,
                            file_name : element.file_name,
                            file_content : element.file_name,
                            file_full_name : element.document_name
                          })
                        }
                    }else{
                      this.moreDocuments.push({
                        id : element.file_name.match(/\d+/)[0],
                        file_name : element.file_name,
                        file_id : element.id,
                        file_content : element.file_name,
                        file_full_name : element.document_name
                      })
                    }
                  }

                });
                
              }
              this.client.progress = this.client.progress +1;
              let process = this.client.progress;
              if( Math.round((100 * process) / 8) > 79)
                this.trade_flag = true;
                let flag =1;
                let done_flag = 1;
                this.testStates.forEach((val,key) => {
                  if(flag == (process-1)){
                    this.testStates[key].current = true;
                    done_flag = 0;
                    this.currentState = this.testStates[key].name;
                  }
                  flag = flag +1;
                  if(done_flag == 1)
                    this.testStates[key].done = true;
                })

            
          let count = this.settings.grant_count;
          
          for (let index = 1; index <= parseInt(count); index++) {
            if(this.grant_count == 0){
              this.grantStates.push( { name: 'Grant '+index,current: false,done : false });
            }else{
              if(this.grant_count == index)
                this.grantStates.push( { name: 'Grant '+index,current: true,done : false });
              else if(this.grant_count > index)
                this.grantStates.push( { name: 'Grant '+index,current: false,done : true });
              else
                this.grantStates.push( { name: 'Grant '+index,current: false,done : false });
            }
            
          }

              }else {
                  Vue.$toast.error(data.message, {
                  position: 'top-right'
                  });
              }

          })
            .catch(err => {
              // Vue.$toast.error(err, {
              // position: 'top-right'
              // });

            });

      },
      deleteGrant(item,flag=''){
        if(confirm('Are you sure, You want to delete the grant?')){
          if(flag =='all'){
             axios({
            method: "POST",
            url: "/api/delete-grant-all",
            headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
            data: {user_id: this.$route.params.id, id : this.selectGrant}
            })
            .then(response => {
              this.getUsers();
              Vue.$toast.success("Grant has been removed", {
              position: 'top-right'
              });
            });
          }else{
            axios({
            method: "POST",
            url: "/api/delete-grant",
            headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
            data: {user_id: this.$route.params.id, id : item.id}
            })
            .then(response => {
              this.getUsers();
              Vue.$toast.success("Grant has been removed", {
              position: 'top-right'
              });
            });
          }
          }
      },
      deleteItemToBeCompleted(item='com'){
        if(confirm('Are you sure, You want to delete the grant?')){
          if(item =='com'){
             axios({
            method: "POST",
            url: "/api/delete-grant-user-all",
            headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
            data: {user_id: this.$route.params.id, id : this.selectedItemsCompleted}
            })
            .then(response => {
              this.getComGrants();
              Vue.$toast.success("Grant has been removed", {
              position: 'top-right'
              });
            });
          }else{
            axios({
            method: "POST",
            url: "/api/delete-grant-user-all",
            headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
            data: {user_id: this.$route.params.id, id : this.selectedItemsToBeCompleted}
            })
            .then(response => {
              this.getInGrants();
              Vue.$toast.success("Grant has been removed", {
              position: 'top-right'
              });
            });
          }
        }
      },
      removeDocuments(){
          if(confirm('Are you sure, You want to remove all documents?')){
            this.loadingImport = true;
            axios({
            method: "POST",
            url: "/api/remove-all-documents",
            headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
            data: {user_id: this.$route.params.id}
            })
            .then(response => {
            if(response.data.flag == 1){
              this.loadingImport = false;
              this.doc_id_flag = false;
              this.doc_id_value = ''
              this.doc_bill_flag = false;
              this.doc_bill_value = ''
              this.doc_letter_a_flag = false;
              this.doc_letter_a_value = ''
              this.moreDocuments = [];
              this.getUserProfile();
              Vue.$toast.success("All documents has been removed", {
              position: 'top-right'
              });
            }
            });
          }
        },
        removeSelectedDocuments(){
          if(confirm('Are you sure, You want to remove selected documents?')){
            this.loadingImport = true;
            axios({
            method: "POST",
            url: "/api/remove-documents",
            headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
            data: {user_id: this.$route.params.id,ids : this.documentCheck}
            })
            .then(response => {
            if(response.data.flag == 1){
              this.loadingImport = false;
              this.doc_id_flag = false;
              this.doc_id_value = ''
              this.doc_bill_flag = false;
              this.doc_bill_value = ''
              this.doc_letter_a_flag = false;
              this.doc_letter_a_value = ''
              this.moreDocuments = [];
              this.documentCheck = []
              this.getUserProfile();
              Vue.$toast.success("Documents has been removed", {
              position: 'top-right'
              });
            }
            });
          }
        },
      uploadMultiple(){
        let id = 4;
        let self = this;
        
        if(!this.newFileName){
           Vue.$toast.error("File name is required", {
              position: 'top-right'
              });
        }else{
        if(this.moreDocuments.length != 0){
          this.moreDocuments.forEach(val => {
            id = val.id;
          })
          id = parseInt(id)+1;
        }
        self.loadingCustomeFile = true;
        const data = new FormData();
        let invalid_flag = false;
         let k = id;
          this.newFilesUpload.forEach(val => {
            if(!val.name.includes(self.client.email)){
              invalid_flag = true;
            }
            data.append('file_letter_'+k,val);
            k = k+1;
          })
          // if(invalid_flag == true){
          //   Vue.$toast.error("Document should contain user email", {
          //           position: 'top-right'
          //           });
          //   self.loadingCustomeFile = false;
          // }else{
          data.append('last_id',id);
          data.append('total_file',this.newFilesUpload.length);
          data.append('custom_file_name',this.newFileName);
          data.append('flag',self.selectedFile.replace('file_', ''));
          data.append('user_id',this.$route.params.id);
          this.loadingUpload = true;
          axios({
          method: "POST",
          url: "/api/upload-file",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: data
          })
          .then(response => {
          self.loadingCustomeFile = false;
          self.newFileName = '';
          self.newFilesUpload = [];
          self.addFileFlag = false;
          Vue.$toast.success("File uploaded", {
              position: 'top-right'
              });
          this.loadingUpload = false;
          self.getUserProfile();
          })
          .catch(err => {
              Vue.$toast.error("Invalid file to upload", {
              position: 'top-right'
              });
              this.isDisableUpload = false;
              this.isDarkUpload = true;
          });
          // }
        }
      },
      getUsers(){
        this.loading = true;
            this.offset = (this.page - 1) * this.datatable_options_grants.itemsPerPage
            this.selected_project = name;
            let req = {
              limit : this.datatable_options_grants.itemsPerPage,
              offset : this.offset,
              sort : this.datatable_options_grants.sortBy,
              sortDesc : this.datatable_options_grants.sortDesc && this.datatable_options_grants.sortDesc[0] == false ? 'ASC'  :'DESC',
              search : this.search
            }
            axios({
          method: "POST",
           
          url: "/api/get-all-grant",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
              console.log(data);
                this.allGrants = data.data;
                this.pageCount = data.total_page
                this.totalCountAllGrants = data.count
                console.log( this.users);
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
        
      },
      getInGrants(){
        this.loading = true;
            this.offset = (this.page_in - 1) * this.datatable_options_grants_in.itemsPerPage
            this.selected_project = name;
            let req = {
              limit : this.datatable_options_grants_in.itemsPerPage,
              offset : this.offset,
              sort : this.datatable_options_grants_in.sortBy,
              sortDesc : this.datatable_options_grants_in.sortDesc && this.datatable_options_grants_in.sortDesc[0] == false ? 'ASC'  :'DESC',
              search : this.search2,
              user_id : this.$route.params.id
            }
            axios({
          method: "POST",
           
          url: "/api/get-submitted-grant",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
              console.log(data);
                this.allGrantsIn = data.data;
                this.pageCountIn = data.total_page
                this.totalCountInprogressGrants = data.count
                console.log( this.users);
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
        
      },
      getLetters(){
      this.loading = true;
      let self = this;
            var offset = (this.page_letter - 1) * 10
            let req = {
              limit : 50,
              offset : offset,
              sort : '',
              sortDesc : 'DESC',
              search : this.search_letter
            }
            axios({
          method: "POST",
           
          url: "/api/get-client-letters/"+this.id,
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;
            if (response.data) {
               let letters = data.data;
               let arr = []
               arr = letters.reduce(function (acc, obj) {
                  var key = obj['round'];
                  if (!acc[key]) {
                    acc[key] = [];
                  }
                  acc[key].push(obj);
                  
                  return acc;
                }, {}); 
                if(letters.length != 0)
                  self.last_letter = letters[0].round;
              //   letters.forEach(element => {
              //     arr[ele]
              //   });
               this.letters = arr




                this.pageCountLetter = data.total_page
                this.countLetter = data.count
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
    },
      getComGrants(){
        this.loading = true;
            this.offset = (this.page_com - 1) * this.datatable_options_grants_com.itemsPerPage
            this.selected_project = name;
            let req = {
              limit : this.datatable_options_grants_com.itemsPerPage,
              offset : this.offset,
              sort : this.datatable_options_grants_com.sortBy,
              sortDesc : this.datatable_options_grants_com.sortDesc && this.datatable_options_grants_com.sortDesc[0] == false ? 'ASC'  :'DESC',
              search : this.search3,
              user_id : this.$route.params.id
            }
            axios({
          method: "POST",
           
          url: "/api/get-completed-grant",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
              console.log(data);
                this.allGrantsCom = data.data;
                this.pageCountCom = data.total_page
                this.totalCountCompletedGrants = data.count
                console.log( this.users);
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
        
      },
      assignData(data){
        let self = this;
        data = JSON.parse(data.report)
        // Score
        self.FinalData.profile.score.transunion = data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.CreditScore[0].riskScore
        self.FinalData.profile.score.experian = data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.CreditScore[1].riskScore
        self.FinalData.profile.score.equifax = data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.CreditScore[2].riskScore
        // report date
        self.FinalData.profile.report_date.transunion = data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.CreditScore[0].Source.InquiryDate
        self.FinalData.profile.report_date.experian = data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.CreditScore[1].Source.InquiryDate
        self.FinalData.profile.report_date.equifax = data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.CreditScore[2].Source.InquiryDate
        // name and known as
        data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.BorrowerName.forEach(element => {
          if(element.Source.Bureau.abbreviation == 'TransUnion'){
            if(element.NameType.abbreviation == 'Primary'){
              self.FinalData.profile.name.transunion = element.Name.first+' '+element.Name.middle+" "+element.Name.last
            }
            if(element.NameType.abbreviation == 'Also Known As'){
              self.FinalData.profile.known_as.transunion = element.Name.first+' '+element.Name.middle+" "+element.Name.last
            }
          }
          if(element.Source.Bureau.abbreviation == 'Experian'){
            if(element.NameType.abbreviation == 'Primary'){
              self.FinalData.profile.name.experian = element.Name.first+' '+element.Name.middle+" "+element.Name.last
            }
            if(element.NameType.abbreviation == 'Also Known As'){
              self.FinalData.profile.known_as.experian = element.Name.first+' '+element.Name.middle+" "+element.Name.last
            }
          }
          if(element.Source.Bureau.abbreviation == 'Equifax'){
            if(element.NameType.abbreviation == 'Primary'){
              self.FinalData.profile.name.equifax = element.Name.first+' '+element.Name.middle+" "+element.Name.last
            }
            if(element.NameType.abbreviation == 'Also Known As'){
              self.FinalData.profile.known_as.equifax = element.Name.first+' '+element.Name.middle+" "+element.Name.last
            }
          }
        });
        //Birth
        self.FinalData.profile.birth.transunion = data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.Birth[0].date
        self.FinalData.profile.birth.experian = data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.Birth[1].date
        self.FinalData.profile.birth.equifax = data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.Birth[2].date
        
        self.FinalData.credit_summary.transunion = {...data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Summary.TradelineSummary.TransUnion, ...data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Summary.InquirySummary.TransUnion,...data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Summary.PublicRecordSummary.TransUnion };
        self.FinalData.credit_summary.experian = {...data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Summary.TradelineSummary.Experian, ...data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Summary.InquirySummary.Experian,...data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Summary.PublicRecordSummary.Experian}
        self.FinalData.credit_summary.equifax = {...data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Summary.TradelineSummary.Equifax,...data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Summary.InquirySummary.Equifax,...data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Summary.PublicRecordSummary.Equifax}
        //Inquiries
        if(!Array.isArray(data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.InquiryPartition)){
          data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.InquiryPartition =[data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.InquiryPartition]
        }
        data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.InquiryPartition.forEach(element =>{
          if(element.Inquiry.Source.Bureau.abbreviation == 'TransUnion'){
            self.FinalData.credit_inquiries.push({title: element.Inquiry.subscriberName,date: element.Inquiry.inquiryDate,transunion : true, experian : false,equifax : false})
          }
          if(element.Inquiry.Source.Bureau.abbreviation == 'Experian'){
            self.FinalData.credit_inquiries.push({title: element.Inquiry.subscriberName,date: element.Inquiry.inquiryDate,transunion : false, experian : true,equifax : false})
          }
          if(element.Inquiry.Source.Bureau.abbreviation == 'Equifax'){
            self.FinalData.credit_inquiries.push({title: element.Inquiry.subscriberName,date: element.Inquiry.inquiryDate,transunion : false, experian : false,equifax : true})
          } 
        })
      this.chartOptionseq = {
           
              debug: false, 
              type: 'gauge ', 
              responsive : false,
              legend_visible: false, 
              chartArea_boxVisible: false, 
              xAxis: { 
                /*Used to position marker on top of axis line.*/
                scale: { range: [0, 1], invert: true } 
              }, 
              palette: { 
                pointValue: '%yValue', 
                ranges: [ 
                  { value: 350, color: '#FF5353' }, 
                  { value: 600, color: '#FFD221' }, 
                  { value: 700, color: '#77E6B4' }, 
                  { value: [800, 850], color: '#21D683' } 
                ] 
              }, 
              yAxis: { 
                defaultTick: { padding: 13, enabled: false }, 
                customTicks: [600, 700, 800], 
                line: { 
                  width: 10, 
                  breaks_gap: 0.03, 
                  color: 'smartPalette'
                }, 
                scale: { range: [350, 850] } 
              }, 
              defaultSeries: { 
                opacity: 1, 
                shape: { 
                  label: { 
                    align: 'center', 
                    verticalAlign: 'middle'
                  } 
                } 
              }, 
              series: [ 
                { 
                  type: 'marker', 
                  name: 'Score', 
                  shape_label: { 
                    text: labelText, 
                    style: { fontSize: 25 } 
                  }, 
                  defaultPoint: { 
                    tooltip: '%yValue', 
                    marker: { 
                      outline: { 
                        width: 5, 
                        color: 'currentColor'
                      }, 
                      fill: 'white', 
                      type: 'circle', 
                      visible: true, 
                      size: 20 
                    } 
                  }, 
                  points: [[1, parseInt(self.FinalData.profile.score.equifax)]] 
                } 
              ] 

        
         }
         this.chartOptionsex = {
           
              debug: false, 
              type: 'gauge ', 
              legend_visible: false, 
              chartArea_boxVisible: false, 
              xAxis: { 
                /*Used to position marker on top of axis line.*/
                scale: { range: [0, 1], invert: true } 
              }, 
              palette: { 
                pointValue: '%yValue', 
                ranges: [ 
                  { value: 350, color: '#FF5353' }, 
                  { value: 600, color: '#FFD221' }, 
                  { value: 700, color: '#77E6B4' }, 
                  { value: [800, 850], color: '#21D683' } 
                ] 
              }, 
              yAxis: { 
                defaultTick: { padding: 13, enabled: false }, 
                customTicks: [600, 700, 800], 
                line: { 
                  width: 10, 
                  breaks_gap: 0.03, 
                  color: 'smartPalette'
                }, 
                scale: { range: [350, 850] } 
              }, 
              defaultSeries: { 
                opacity: 1, 
                shape: { 
                  label: { 
                    align: 'center', 
                    verticalAlign: 'middle'
                  } 
                } 
              }, 
              series: [ 
                { 
                  type: 'marker', 
                  name: 'Score', 
                  shape_label: { 
                    text: labelText, 
                    style: { fontSize: 25 } 
                  }, 
                  defaultPoint: { 
                    tooltip: '%yValue', 
                    marker: { 
                      outline: { 
                        width: 5, 
                        color: 'currentColor'
                      }, 
                      fill: 'white', 
                      type: 'circle', 
                      visible: true, 
                      size: 20 
                    } 
                  }, 
                  points: [[1, parseInt(self.FinalData.profile.score.experian)]] 
                } 
              ] 

        
         }
         this.chartOptionstu = {
           
              debug: false, 
              type: 'gauge ', 
              legend_visible: false, 
              chartArea_boxVisible: false, 
              xAxis: { 
                /*Used to position marker on top of axis line.*/
                scale: { range: [0, 1], invert: true } 
              }, 
              palette: { 
                pointValue: '%yValue', 
                ranges: [ 
                  { value: 350, color: '#FF5353' }, 
                  { value: 600, color: '#FFD221' }, 
                  { value: 700, color: '#77E6B4' }, 
                  { value: [800, 850], color: '#21D683' } 
                ] 
              }, 
              yAxis: { 
                defaultTick: { padding: 13, enabled: false }, 
                customTicks: [600, 700, 800], 
                line: { 
                  width: 10, 
                  breaks_gap: 0.03, 
                  color: 'smartPalette'
                }, 
                scale: { range: [350, 850] } 
              }, 
              defaultSeries: { 
                opacity: 1, 
                shape: { 
                  label: { 
                    align: 'center', 
                    verticalAlign: 'middle'
                  } 
                } 
              }, 
              series: [ 
                { 
                  type: 'marker', 
                  name: 'Score', 
                  shape_label: { 
                    text: labelText, 
                    style: { fontSize: 25 } 
                  }, 
                  defaultPoint: { 
                    tooltip: '%yValue', 
                    marker: { 
                      outline: { 
                        width: 5, 
                        color: 'currentColor'
                      }, 
                      fill: 'white', 
                      type: 'circle', 
                      visible: true, 
                      size: 20 
                    } 
                  }, 
                  points: [[1, parseInt(self.FinalData.profile.score.transunion)]] 
                } 
              ] 

        
         }
      
      },
        
        

    
    },
     mounted() {
      let self = this;
      if(!localStorage.getItem('token'))
            this.$router.push('/login')
        // this.getUsers();
        this.getUserProfile();
        this.getContracts();
        this.getLetters()
        this.getLocation();
        this.getFocusArea();
        this.getCompanySettings();
        this.getGHLChat();
    
  },
  watch: {
            datatable_options_letter : {
              handler () {
                          this.getLetters()
                          },
                          deep: true,
            },
            datatable_options_grants: {
                handler () {
                    console.log(this.datatable_options_grants);
                this.getUsers()
                },
                deep: true,
            },
            datatable_options_grants_in: {
                handler () {
                    console.log(this.datatable_options_grants_in);
                this.getInGrants()
                },
                deep: true,
            },
            datatable_options_grants_com: {
                handler () {
                    console.log(this.datatable_options_grants_com);
                this.getComGrants()
                },
                deep: true,
            },
        },
}
</script>

<style>
.v-tabs-slider-wrapper{
  height: 5px!important;
}
.theme--light.v-expansion-panels .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon{
  color: white!important;
}
.card-border{
      color: white;
    -webkit-transform: rotate(-90deg);
    position: absolute;
    justify-content: center;
    text-align: center;
    display: block;
    height: 100%;
    margin-left: 175px; 
}
.checkbox-doc .v-messages{
  display : none;
} 
.only-next{
  display:none!important;
}
.white-text{
  text-align: center;
  color :#FFFFFF;
}
.customer-overview-score-2 {
  margin-bottom: 0px;
  font-size: 70px;
  line-height: 60px;
  text-align: center;
  font-family: Lato, sans-serif;
  font-weight: 700;
  letter-spacing: -4px;
}
.columnChart {
    height: 400px;
}
.text-black{
   text-align: center;
}
.table-one tbody tr:nth-of-type(even) {
   background-color: #EAF1F5;
 }
 .client_card_info_address{
  margin-bottom: 12px;
color: #272727;
font-weight: 700;
 }
 .customer-side-panel-ssn-left{
    display: inline-block;
    font-size: 13px;
    color: #272727;
    font-weight: 700;

 }
 #brandingLogo {
  display:none;
}
</style>