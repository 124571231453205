<template>
 <v-app id="inspire" style="background-color: white;">

    <div style=" justify-content: space-around" class=" mb-1 header">
      <div style="text-align: center;" class="mt-10">
                        <img v-if="!company && loadingFlag" :height="this.$vuetify.breakpoint.xs ? 100 : 150" class="image-logo2" src="@/assets/img/logo-top.png"> 
                                  <img v-if="company && loadingFlag &&  company.logo" :height="this.$vuetify.breakpoint.xs ? 150 : 200" class="image-logo2" :src="base_url_img+company.logo">
                                  
                                  <h2 class=" text-label " style=" font-size: 25px;font-weight: 600;color: white!important;">
                                    Let's collaborate on creating Slideshows.</h2>
<h3 class=" text-label " style=" font-size: 15px;font-weight: 600;color: white!important;">
POWERED By <span style="color: red;">WeCreate</span></h3>
                          </div>
       
<v-row class="" style="padding-left: 15px;padding-right: 15px;;">
      <v-col lg="8" cols="12" class="mx-auto my-auto my-16">
                    <v-form
                        ref="form1"
                        v-model="validGeneral"
                        lazy-validation
                    >
                    <v-row class="mt-2">
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                          label="First Name"
                          color="#e91e63"
                          v-model="partner.first_name"
                          :rules="rules"
                          required
                          class="font-size-input input-style"
                        ></v-text-field>
                      </v-col>
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                          label="Last Name"
                          color="#e91e63"
                          :rules="rules"
                          v-model="partner.last_name"
                          required
                          class="font-size-input input-style"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row class="mt-2">
                      
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                          label="Email Address"
                          color="#e91e63"
                          required
                          :rules="emailRules"
                          v-model="partner.email"
                          class="font-size-input input-style"
                        ></v-text-field>
                      </v-col>
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                        label="Phone (Mobile)"
                        color="#e91e63"
                        :rules="rules"
                                v-mask="'(###) ###-####'"
                                class="font-size-input input-style"
                                v-model="partner.phone_m"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                     <v-row class="mt-2">
                      
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                        label="Password"
                        :type="showpassword ? 'text' : 'password'"
                        :rules="rules"
                        :append-icon="showpassword ? 'fa-eye' : 'fa-eye-slash'"
                        @click:append="showpassword = !showpassword"
                        color="#e91e63"
                                class="font-size-input input-style"
                                v-model="partner.password"
                        ></v-text-field>
                      </v-col>
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                        :rules="rules"
                        label="Confirm Password"
                        :type="showconfpassword ? 'text' : 'password'"
                        :append-icon="showconfpassword ? 'fa-eye' : 'fa-eye-slash'"
                        @click:append="showconfpassword = !showconfpassword"
                        color="#e91e63"
                                class="font-size-input input-style"
                                v-model="partner.confirm_password"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" class="py-0">
                        <vue-recaptcha
                            :sitekey="sitekey"
                            :loadRecaptchaScript="true"
                            ref="recaptcha"
                            type="invisible"
                            @verify="onCaptchaVerified"
                            @expired="onCaptchaExpired"
                        >
                    </vue-recaptcha>
                    <v-btn
                      :ripple="false"
                      :elevation="0"
                      :loading="loadingSaveButton"
                      class="
                                      font-weight-bolder
                                      btn-default
                                      mb-5
                                      mt-5
                                      mr-2
                                      px-8
                                      ms-auto
                                    "
                      color="#D59C5A"
                      @click="checkForm()"
                    >
                      Submit
                    </v-btn>
                    <v-btn
                      :ripple="false"
                      :elevation="0"
                      :loading="loadingSaveButton"
                      class="
                                      font-weight-bolder
                                      btn-default
                                      mb-5
                                      mt-5
                                      px-8
                                      ms-auto
                                    "
                      color="grey"
                      @click="$router.push('/')"
                    >
                      Back to login
                    </v-btn>
                    </v-col>
                    </v-row>
                    </v-form>
                  
         
                   
                     
                    
      </v-col>
    </v-row>
    <v-dialog
      v-model="showDialog"
      width="700"
    >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
          Info
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
          <p>
            It's quite a daunting task answering the those grants by yourself and all the other agencies back and forth. So we make it easier for you.
            </p><p>
            While you're out taking care of business we're helping you take care of business.
            </p><p>

            To do this for you we need a process email for the task that we are going to do on your behalf.
            </p><p>

            All you have to do is make a process email so that we can check it and respond on your behalf.
            </p><p>

            To make a process email here is an Example. My last name and random numbers @gmail.com  like this —>(doe74564@gmail.com)
            </p><p>
            <a href="https://accounts.google.com/signup/v2/webcreateaccount?hl=en&amp;flowName=GlifWebSignIn&amp;flowEntry=SignUp" target="_blank" rel="nofollow">(CLICK HERE)</a> Once you get signed up enter login in information below.
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
           <v-btn
            color="green"
            outlined
            :disabled="loadingAssign"
            @click="showDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
              <defs>
              <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
              </defs>
              <g class="parallax">
              <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
              <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
              <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
              <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" />
              </g>
              </svg>
    </div>
 </v-app>
</template>

<script>
import DateDropdown from 'vue-date-dropdown'
import VueRecaptcha from 'vue-recaptcha'
import moment from 'moment'
import ClipLoader from 'vue-spinner/src/PulseLoader.vue';
// import Vue from 'vue'
export default {
    components: {
        DateDropdown,
        'vue-recaptcha' : VueRecaptcha,
        'RotateLoader': ClipLoader
    },
    data()  {
        return {
            address: '',
            dob:'',
            age: 0,
            e1 : 1,
            loadingSaveButton : false,
            verifyLoad: false,
            verfiedFlag : false,
            verifyUser: '',
            showDialog :false,
            showconfpassword : false,
            agree : false,
            base_url_img : process.env.VUE_APP_WASBI_URL,
            showpassword : false,
            rules: [
                value => !!value || 'Required.',
            ],
            temp_email  : localStorage.getItem('temp_email') ? true : false,
            validGeneral : true,
            validAddress : true,
            validSocial : true,
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
            loadingFlag : false,
            grant : {},
            partner : {
                first_name : "",
                last_name : '',
                middle_name: '',
                email : localStorage.getItem('temp_email') ? localStorage.getItem('temp_email') : '',
                is_email : false ,
                phone_h : '',
                phone_w : '',
                dob: '',
                is_exist : "",
                ssn : '',
                phone_m : '',
                fax : '',
                address :'',
                address1: '',
                city : '',
                state : '',
                zip : '',
                country :'United States',
                memo : '',
                is_verified:  0,
                document : '',
                suffix:'',
                recaptcha: '',
                user_name: "",
                password: '',
                confirm_password: "",
                crc_id : "",
                driving_license_number : '',
                driving_license_state : "",
                process_email : "",
                process_email_password : "",
                is_grant :'no'

            },
                url : '',
                company : '',
            isDisable :false,
            loading: false,
            isDark: true,
            existOption: ["Yes","No"],
            stateOptions: [
                { name: 'ALABAMA', abbreviation: 'AL'},
                { name: 'ALASKA', abbreviation: 'AK'},
                { name: 'AMERICAN SAMOA', abbreviation: 'AS'},
                { name: 'ARIZONA', abbreviation: 'AZ'},
                { name: 'ARKANSAS', abbreviation: 'AR'},
                { name: 'CALIFORNIA', abbreviation: 'CA'},
                { name: 'COLORADO', abbreviation: 'CO'},
                { name: 'CONNECTICUT', abbreviation: 'CT'},
                { name: 'DELAWARE', abbreviation: 'DE'},
                { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC'},
                { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM'},
                { name: 'FLORIDA', abbreviation: 'FL'},
                { name: 'GEORGIA', abbreviation: 'GA'},
                { name: 'GUAM', abbreviation: 'GU'},
                { name: 'HAWAII', abbreviation: 'HI'},
                { name: 'IDAHO', abbreviation: 'ID'},
                { name: 'ILLINOIS', abbreviation: 'IL'},
                { name: 'INDIANA', abbreviation: 'IN'},
                { name: 'IOWA', abbreviation: 'IA'},
                { name: 'KANSAS', abbreviation: 'KS'},
                { name: 'KENTUCKY', abbreviation: 'KY'},
                { name: 'LOUISIANA', abbreviation: 'LA'},
                { name: 'MAINE', abbreviation: 'ME'},
                { name: 'MARSHALL ISLANDS', abbreviation: 'MH'},
                { name: 'MARYLAND', abbreviation: 'MD'},
                { name: 'MASSACHUSETTS', abbreviation: 'MA'},
                { name: 'MICHIGAN', abbreviation: 'MI'},
                { name: 'MINNESOTA', abbreviation: 'MN'},
                { name: 'MISSISSIPPI', abbreviation: 'MS'},
                { name: 'MISSOURI', abbreviation: 'MO'},
                { name: 'MONTANA', abbreviation: 'MT'},
                { name: 'NEBRASKA', abbreviation: 'NE'},
                { name: 'NEVADA', abbreviation: 'NV'},
                { name: 'NEW HAMPSHIRE', abbreviation: 'NH'},
                { name: 'NEW JERSEY', abbreviation: 'NJ'},
                { name: 'NEW MEXICO', abbreviation: 'NM'},
                { name: 'NEW YORK', abbreviation: 'NY'},
                { name: 'NORTH CAROLINA', abbreviation: 'NC'},
                { name: 'NORTH DAKOTA', abbreviation: 'ND'},
                { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP'},
                { name: 'OHIO', abbreviation: 'OH'},
                { name: 'OKLAHOMA', abbreviation: 'OK'},
                { name: 'OREGON', abbreviation: 'OR'},
                { name: 'PALAU', abbreviation: 'PW'},
                { name: 'PENNSYLVANIA', abbreviation: 'PA'},
                { name: 'PUERTO RICO', abbreviation: 'PR'},
                { name: 'RHODE ISLAND', abbreviation: 'RI'},
                { name: 'SOUTH CAROLINA', abbreviation: 'SC'},
                { name: 'SOUTH DAKOTA', abbreviation: 'SD'},
                { name: 'TENNESSEE', abbreviation: 'TN'},
                { name: 'TEXAS', abbreviation: 'TX'},
                { name: 'UTAH', abbreviation: 'UT'},
                { name: 'VERMONT', abbreviation: 'VT'},
                { name: 'VIRGIN ISLANDS', abbreviation: 'VI'},
                { name: 'VIRGINIA', abbreviation: 'VA'},
                { name: 'WASHINGTON', abbreviation: 'WA'},
                { name: 'WEST VIRGINIA', abbreviation: 'WV'},
                { name: 'WISCONSIN', abbreviation: 'WI'},
                { name: 'WYOMING', abbreviation: 'WY' }
            ],
            year : new Date().getFullYear(),
            form: {
            // Some form values...
                recaptcha: '',
            },
            sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY
        }
    },
    created () {
        this.url = window.location.origin;//this.$route.params.url ? this.$route.params.url : '';
        if(this.url)
            this.getCompanyInfo(this.url)
        else
            this.loadingFlag = true;
    },
    watch: {
      dob (val) {
        this.age = val ?  moment().diff(this.dob, 'years') :0
        console.log(this.age);
        if(isNaN(this.age))
          this.age = 0;
      },
    },
    beforeCreate: function () {
        if (localStorage.getItem('token')) {
            if(localStorage.getItem('is_admin'))
                this.$router.push('/admin/dashboard')
            else
                this.$router.push('/dashboard')
        }
    },
    methods : {
      gotoPrivacy(){
        window.open('/privacy/'+this.$route.params.url, "_blank");
      },
        getCompanyInfo(url){
  axios({
          method: "POST",
           
          url: "/api/get-company-info",
          data: {
            url: url
          }
        })
          .then(response => {
            console.log(response.data.company);
            this.company = response.data.company;
            this.loadingFlag = true;
          })

},
        onCaptchaVerified: function (recaptchaToken) {
        this.partner.recaptcha = recaptchaToken
        this.validateCaptcha = true
        },
        onCaptchaExpired: function () {
        this.$refs.recaptcha.reset();
        },
         getAddressData3: function (addressData3, placeResultData, id) {
            this.grant.grant_address = addressData3.name;
        },
        getAddressData2: function (addressData2, placeResultData, id) {
            this.grant.grant_business_address = addressData2.name;
        },
        getAddressData: function (addressData, placeResultData, id) {
            this.partner.address = addressData.name;
            this.partner.city = addressData.locality;
            this.partner.state = addressData.administrative_area_level_1;
            this.partner.zip = addressData.postal_code
            console.log(this.partner);
        },
        isJson(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        },
        verifyCrc(){
            let self = this;
            let flag = 1;
            if (!this.partner.crc_id) {
                Vue.$toast.error('Customer ID is required', {
                    position: 'top-right'
                });
                flag = 0;
            }else{
                self.verifyLoad = true;
                axios({
                method: "POST",
                 
                url: "/api/auth/verify-crc",
                data:{crc_id : self.partner.crc_id},
                headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
                })
                .then(response => {
                    console.log(response);
                    if(response.data.data.success == 'True'){
                        self.verifyUser = response.data.data.result;
                        self.verfiedFlag = true;
                    }else{
                        Vue.$toast.error('Invalid customer id', {
                            position: 'top-right'
                        });
                    }
                    self.verifyLoad = false
                })
                .catch(err => {
                    // console.log(JSON.parse(err.response.data));
                    self.verifyLoad = flase;

                });
            }
        },
        autofill(){
            let self = this;
            self.partner.first_name = self.verifyUser.firstname
            self.partner.last_name = self.verifyUser.lastname
            if(!self.temp_email)
                self.partner.email = self.verifyUser.email
            self.partner.middle_name = self.verifyUser.middlename
            self.partner.phone_m = self.verifyUser.phone_mobile
            self.partner.phone_h = self.verifyUser.phone_home
            self.partner.phone_w = self.verifyUser.phone_work
            self.partner.ssn = self.verifyUser.ssno
            self.address = self.verifyUser.street_address
            if(self.verifyUser.state){
                self.stateOptions.forEach(element => {
                    if(self.verifyUser.state.toUpperCase()  == element.name)
                        self.partner.state = element.abbreviation
                });
            }
            self.partner.zip = self.verifyUser.zip
            self.partner.city = self.verifyUser.city
            if(self.verifyUser.birth_date){
                var temp_dob = self.verifyUser.birth_date.split("/");
                self.partner.dob = temp_dob[1]+'.'+temp_dob[0]+'.'+temp_dob[2]
                console.log(self.partner.dob);
            }
            
        },
        checkForm(){
            let self = this;
            let flag = 1;
            console.log(this.partner);
            
            if (!this.partner.first_name) {
                Vue.$toast.error('First Name is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            if (!this.partner.last_name && flag == 1) {
                Vue.$toast.error('Last Name is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            if (!this.partner.email && this.partner.is_email == false && flag == 1) {
                Vue.$toast.error('Email is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (reg.test(this.partner.email) == false && this.partner.is_email == false  && flag == 1) {
                Vue.$toast.error('Invalid email format', {
                    position: 'top-right'
                });
                flag = 0;
            }

            if (!this.partner.phone_m && this.partner.phone_m == false && flag == 1) {
                Vue.$toast.error('Phone (Mobile) is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            
            
            // if (!this.partner.crc_id && this.partner.crc_id == false && flag == 1) {
            //     Vue.$toast.error('CRC ID is required', {
            //         position: 'top-right'
            //     });
            //     flag = 0;
            // }
            this.partner.user_name = this.partner.email;
            // if (!this.partner.user_name && this.partner.user_name == false && flag == 1) {
            //     Vue.$toast.error('User name is required', {
            //         position: 'top-right'
            //     });
            //     flag = 0;
            // }
            if (!this.partner.password && this.partner.password == false && flag == 1) {
                Vue.$toast.error('Password is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            if (!this.partner.confirm_password && this.partner.confirm_password == false && flag == 1) {
                Vue.$toast.error('Confirm password is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            if (this.partner.password != this.partner.confirm_password && flag == 1) {
                Vue.$toast.error('Confirm password did not match with password', {
                    position: 'top-right'
                });
                flag = 0;
            }
            
            if (flag == 1) {
                this.loadingSaveButton = true;
                let is_verified = localStorage.getItem('email_verified') ? localStorage.getItem('email_verified') : 0
                this.partner.is_verified = is_verified;
                this.partner.company_id = this.company ? this.company.id : '';
                this.isDark = false;
                this.isDisable = true;
                const data = new FormData();
                for (var key in self.partner) {
                  data.append(key,self.partner[key]);
                }
                data.append('frontend_url',this.url);
                data.append('company',JSON.stringify(this.company));
                axios({
                method: "POST",
                url: "/api/auth/register",
                data:data,
                headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
                })
                .then(response => {
                    console.log(response);
                        let result = response.data.data;
                        //self.$router.push('/login');
                        if(is_verified == 0)
                            Vue.$toast.success('You have successfuly registered. Please check your inbox for confirm your email', {
                            position: 'top-right'
                            }); 
                        else
                            Vue.$toast.success('You have successfuly registered.', {
                            position: 'top-right'
                            }); 
                        localStorage.setItem("temp_email", '');
                        localStorage.setItem('email_verified','')
                        localStorage.setItem('temp_pass','')
                        localStorage.setItem("token", response.data.token.token);
                        localStorage.setItem("user_id", result.user_id);
                        localStorage.setItem("email", result.email);
                        localStorage.setItem("name", result.first_name+' '+result.last_name);
                        localStorage.setItem("document_flag",false);
                        localStorage.setItem("agreement_flag",0);
                        localStorage.setItem("is_admin",1);
                        localStorage.setItem("role", JSON.stringify(result.roles[0]));
                        //   console.log(response.data);
                        if(localStorage.getItem('temp_request_id'))
                            this.$router.push("/restart-process/"+localStorage.getItem('temp_request_id'));
                        if(localStorage.getItem("document_flag") != 'false')
                            this.$router.push("/qrmaker");
                        else
                            this.$router.push("/qrmaker");
                        location.reload();
                        this.isDark = true;
                        this.loadingSaveButton = false;
                        this.isDisable = false;                    
                })
                .catch(err => {
                    // console.log(JSON.parse(err.response.data));
                    this.isDark = true;
                    this.isDisable = false;
                    this.loadingSaveButton = false;
                    if(err.response.data.flag){
                        if(err.response.data.flag == 2){
                            Vue.$toast.error('Company exist with same email address!', {
                            position: 'top-right'
                            });
                        }
                        if(err.response.data.flag == 3){
                          Vue.$toast.error('Client exist with same email address!', {
                            position: 'top-right'
                            });
                        }
                    }else{
                      let data = self.isJson(err.response.data) ? JSON.parse(err.response.data) : (err.response.data);
                      if(data.email){
                          Vue.$toast.error(data.email[0], {
                          position: 'top-right'
                          });
                      }else if(data.password){
                          Vue.$toast.error(data.password[0], {
                          position: 'top-right'
                          });
                      }
                      else if(data.user_name){
                          Vue.$toast.error(data.user_name[0], {
                          position: 'top-right'
                          });
                      }
                      else{
                          Vue.$toast.error(data, {
                          position: 'top-right'
                          });
                      }
                  }
                    self.onCaptchaExpired()

                });

            }
        }
    }
}
</script>

<style>
/* .image-logo2{
position: absolute;
margin: -78px -5px -4px 170px;
} */
.date-dropdown-container{
    height: 85px;
}
.date-dropdown-select{
    border: 1px solid #9E9E9E!important;
    padding: 15px!important;
}
.required_span{
    color:red;
}
</style>