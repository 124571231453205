<template>
    <v-app class="bg-white">

    <v-main class="auth-pages">
      <div class="header">

<!--Content before waves-->
              <div class="inner-header flex" style="z-index: 10000!important;position: relative;">
              <!--Just the logo.. Don't mind this-->
              <v-row >
                <v-col cols="12">
                    <div class="mt-10">
                        <img v-if="!company && loadingFlag" :height="this.$vuetify.breakpoint.xs ? 100 : 150" class="image-logo2" src="@/assets/img/logo-top.png"> 
                                  <img v-if="company && loadingFlag &&  company.logo" :height="this.$vuetify.breakpoint.xs ? 150 : 200" class="image-logo2" :src="base_url_img+company.logo">
                                  
                                  <h1 style="font-weight: 800;font-family: inherit;">Let's collaborate on creating Slideshows.</h1>
                    </div>
              </v-col>
              <v-col cols="12">
                <h3 class="text-center text-white" style="font-family: inherit;">
                  ADMIN PORTAL

                </h3>
                  <div  v-if="!forgot_flag" class="flex">
                        <div class="card-padding pb-4 login-div">
                                  <v-text-field
                                    label="Email"
                                    color="#401478"
                                    outlined
                                    ref="email"
                                    rounded
                                    v-model="email"
                                    prepend-inner-icon="fa-envelope"
                                    dense
                                    class="font-size-input input-style py-0 input-login-new"
                                  ></v-text-field>

                                  <v-text-field
                                    label="Password"
                                    color="#401478"
                                    prepend-inner-icon="fa-key"
                                    outlined
                                    rounded
                                    ref="password"
                                    v-model="password"
                                    dense
                                    :type="showpassword ? 'text' : 'password'"
                                    :append-icon="showpassword ? 'fa-eye' : 'fa-eye-slash'"
                                    @click:append="showpassword = !showpassword"
                                    class="font-size-input input-style py-0 input-login-new"
                                  ></v-text-field>
                                  <v-btn
                                    elevation="0"
                                    :ripple="false"
                                    height="43"
                                    rounded
                                    class="
                                      font-weight-bold
                                      text-uppercase
                                      btn-primary
                                      
                                      py-2
                                      px-6
                                      me-2
                                      mt-7
                                      mb-2
                                      w-100
                                    "
                                    color="#D59C5A"
                                    small
                                    style="margin-top: -40px;"
                                    v-on:click="checkForm"
                                    >Login</v-btn
                                  >
                                  
                                  <div class="text-center">
                                    <p class="text-sm text-body font-weight-light mt-3 mb-0" style="color:#7b809a !important;">
                                      <a
                                        @click="forgot_flag = true"
                                        class="
                                            text-decoration-none
                                          font-weight-bold
                                        "
                                        >Forgot Password?</a
                                      >
                                    </p>
                                  </div>
                                  </div>
                                </div>
                        <v-card class="new-login-left-form" outlined style="background-color:transparent;" v-if="forgot_flag">
                         
                         <div class="card-padding pb-0" style="text-align:center;" >
                                  
                                  <h2 class=" mb-2 text-label text-white" style=" font-size: 25px;font-weight: 600;">Reset Password</h2>
                                  
                                </div>
                                <div class="card-padding pb-4">
                              
                                  <v-text-field
                                    label="Email"
                                    color="#401478"
                                    outlined
                                    rounded
                                    ref="email"
                                    v-model="email"
                                    prepend-inner-icon="fa-envelope"
                                    dense
                                    class="font-size-input input-style py-0 input-login-new"
                                  ></v-text-field>                                 
                                  <v-btn
                                    elevation="0"
                                    :ripple="false"
                                    height="43"
                                    rounded
                                    :loading="buttonFlag"
                                    class="
                                      font-weight-bold
                                      text-uppercase
                                      btn-primary
                                      
                                      py-2
                                      px-6
                                      me-2
                                      mt-7
                                      mb-2
                                      w-100
                                    "
                                    color="#D59C5A"
                                    small
                                    style="margin-top: -40px;"
                                    v-on:click="forgotPass"
                                    >Submit</v-btn
                                  >
                                  <v-btn
                                    elevation="0"
                                    :ripple="false"
                                    height="43"
                                    rounded
                                    class="
                                      font-weight-bold
                                      text-uppercase
                                      btn-primary
                                      
                                      py-2
                                      px-6
                                      me-2
                                      mt-7
                                      mb-2
                                      w-100
                                    "
                                    color="grey"
                                    small
                                    style="margin-top: -40px;"
                                    v-on:click="forgot_flag = false"
                                    >Back to login</v-btn
                                  >
                                 
                                </div>
                        </v-card>
                        
                      </v-col>
                      </v-row>
                      </div>
                      <!--Waves Container-->
              <div>
              <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
              <defs>
              <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
              </defs>
              <g class="parallax">
              <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
              <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
              <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
              <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" />
              </g>
              </svg>
              </div>
                      </div>
            </v-main>


</v-app>
</template>

<script>
// import image from "./assets/profile.png"
import Utility from "@/components/common/functions.js";
export default {
props: {
    source: String,
},

data () {
return {
    email : '',
    pageFlag: 'login',
    password : '',
    showPassword : false,
    forgot_flag : false,
    dialog: false,
    showpassword : false,
    buttonFlag: false,
    backgroundLogo: localStorage.getItem('admin_bg'),
    company : '',
    base_url_img : process.env.VUE_APP_WASBI_URL,
    loadingLogo : false,

}
},
beforeCreate: function () {
    if (localStorage.getItem('token') && localStorage.getItem('is_admin') == 1) {
        this.$router.push('/admin/slideshows')
    }
    
},
created : function (){
  this.url = window.location.origin;//this.$route.params.url ? this.$route.params.url : '';console.log(this.$route)
    if(this.url)
        this.getCompanyInfo(this.url)
    else
        this.loadingLogo = true;
},
methods: {
gotohome: function () {
    let self = this;
    self.$router.push({ name: "Home" });
},

gotosignup: function () {
    let self = this;
    self.$router.push('/signup');
},
getCompanyInfo(url){
  axios({
          method: "POST",
           
          url: "/api/get-company-info",
          data: {
            url: url
          }
        })
          .then(response => {
            this.company = response.data.company;
            this.loadingFlag = true;
            this.loadingLogo = true;
          })

},
forgotPass(){
  if (!this.email) {
        Vue.$toast.error('Email is required', {
            position: 'top-right'
        });
        this.$refs['email'].focus()
        flag = 0;
      }else{
    this.buttonFlag =true;
    axios({
          method: "POST",
           
          url: "/api/auth/forgot-password-admin",
          data: {
            email: this.email,
            quantum : 1,
            frontend_url : this.url,
            company : this.company
          }
        })
          .then(response => {
                this.sendFlag = 1;
                Vue.$toast.success('Forgot Password Mail sent', {
                position: 'top-right'
                });
                this.buttonFlag =false;
                this.pageFlag = 'login';
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          })
        }
},
/**
 * checkForm
 * login the user with valid email/pass
 */
checkForm: function(e) {
      var loginData = {};
      var header = {};
      let flag = 1;
      if (!this.email) {
        Vue.$toast.error('Email is required', {
            position: 'top-right'
        });
        this.$refs['email'].focus()
        flag = 0;
      }
      if (!this.password && flag == 1) {
        Vue.$toast.error('Password is required', {
            position: 'top-right'
        });
        this.$refs['password'].focus()
        flag = 0;
      }

      if (flag == 1) {
        var email = this.email;
        axios({
          method: "POST",
           
          url: "/api/auth/login-admin",
          data: {
            email: this.email,
            password: this.password,
          }
        })
          .then(response => {
            let data = response.data;
            if (data.flag == 1) {
              let result = data.data;
              localStorage.setItem("token", data.token.token);
              localStorage.setItem("user_id", result.user_id);
              localStorage.setItem("first_name", result.first_name);
              localStorage.setItem("email", result.email);
              localStorage.setItem("phone", result.phone_m);
              localStorage.setItem("is_admin", result.is_admin);
              localStorage.setItem("name", result.first_name+' '+result.last_name);
              localStorage.setItem("role", JSON.stringify(result.roles[0]));
              localStorage.setItem("company_name", result.company_name);
              localStorage.setItem('company_logo',process.env.VUE_APP_WASBI_URL+result.logo);
              localStorage.setItem("company_id", result.company_id);
              if(result.roles[0].name !='super_admin')
                localStorage.setItem("agreement_flag", result.agreement);
              if(result.roles[0].name =='super_admin')
                this.$router.push("/admin/slideshows");
              else{
              if(result.agreement == 0)
                this.$router.push("/admin/agreement");
              else
                this.$router.push("/admin/slideshows");
              }
              location.reload();
            } else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          });
      } else {

      }
    },
    checkForm2: function(e) {
      var loginData = {};
      var header = {};
      let flag = 1;
      if (!this.email) {
        Vue.$toast.error('Email is required', {
            position: 'top-right'
        });
        this.$refs['email'].focus()
        flag = 0;
      }
      if (!this.password && flag == 1) {
        Vue.$toast.error('Password is required', {
            position: 'top-right'
        });
        this.$refs['password'].focus()
        flag = 0;
      }

      if (flag == 1) {
        var email = this.email;
        axios({
          method: "POST",
           
          url: "/api/auth/check-email-exist",
          data: {
            email: this.email,
            password: this.password,
          }
        })
          .then(response => {
            let data = response.data;
            if (data.flag == 1) {
              localStorage.setItem('logged_in_email',this.email);
              localStorage.setItem('logged_in_password',btoa(this.password));
              this.$router.push("/choose-profile");
            } else if (data.flag == 2)  {
                this.checkForm();
            } else{
              Vue.$toast.error(response.data.message, {
            position: 'top-right'
            });
            }
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          });
      } else {

      }
    },
  
},

}
</script>

<style scoped>
   html {
  overflow: hidden !important;
}

</style>
<style>
.separator {
    display: flex;
    align-items: center;
    text-align: center;
}
.separator::before, .separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid rgba(0,0,0,.6);
}
.separator::before {
    margin-right: .25em;
}
.separator::after {
    margin-left: .25em;
}
</style>
<style>
.input-login .v-icon{
    color: #FDE038!important;
}
.input-login input{
    color: #FDE038!important;
}
.input-login .v-label{
  color: #FDE038!important;
}
.input-login #input-6{
  color: #FDE038!important;
}
.input-login #pass{
  color: #FDE038!important;
}
.input-login #input-19{
  color: #FDE038!important;
}
</style>
<style>
  .check-login .v-label {
    color: white;
  }
  .check-login .v-icon {
    color: white;
  }
.input-login-slide .v-icon{
    color:  white !important;
}
.input-login-new .v-label{
  color:  white !important;
}
.input-login-new #input-6{
  color:  white !important;
}
.input-login-new #input-10{
  color:  white !important;
}
.login-div .font-size-input .v-label {
  color: white!important;
}
.new-login-left {
  height: 100%;
  display: table;
  position: relative;
}
.new-login-left-box {
  display: table-cell;
  vertical-align: middle;
  height: 770px;
}
.new-login-left-form {
  max-width: 550px;
  width: 90%;
  margin: 30px auto 0;
  border: 1px solid #dedede;
  box-sizing: border-box;
}
.text-label{
  font-family: Arial,Helvetica,sans-serif!important;color: rgb(123, 128, 154) !important;font-weight: 400;
}
.default-color-code{
  color:  #3498db !important;
}
.score-header-1{
  color: rgb(255, 255, 255);
  line-height: 1.2;
  font-size: 13px;
  letter-spacing: 2px;
  font-weight: 800;
  font-family: "Poppins", Sans-serif;
}
.score-header-2{
  color: rgb(255, 255, 255);
  line-height: 1.2;
  font-size: 50px;
  letter-spacing: 2px;
  font-weight: 600;
  font-family: "Poppins", Sans-serif;
}
.e-hosted-video video {
  max-width: 100%;
width: 100%;
margin: 0;
line-height: 1;
border: none;
}
.header {
  position:relative;
  text-align:center;
  background: linear-gradient(60deg, rgba(84,58,183,1) 0%, rgba(0,172,193,1) 100%);
  color:white;
}
.logo {
  width:50px;
  fill:white;
  padding-right:15px;
  display:inline-block;
  vertical-align: middle;
}

.inner-header {
  height:65vh;
  width:100%;
  margin: 0;
  padding: 0;
}

.flex { /*Flexbox for containers*/
  display: flex;
  justify-content: center;
  text-align: center;
}

.waves {
  position:relative;
  width: 100%;
  height:15vh;
  margin-bottom:-7px; /*Fix for safari gap*/
  min-height:100px;
  max-height:150px;
}

.content {
  position:relative;
  height:20vh;
  text-align:center;
  background-color: white;
}

/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
   transform: translate3d(-90px,0,0);
  }
  100% { 
    transform: translate3d(85px,0,0);
  }
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height:40px;
    min-height:40px;
  }
  .content {
    height:30vh;
  }
  h1 {
    font-size:24px;
  }
}
.text-white {
  --tw-text-opacity: 1;
  color: #fff;
  color: rgba(255,255,255,var(--tw-text-opacity));
}
.font-light {
  font-weight: 300;
}
.text-center {
  text-align: center;
}
.max-w-xl {
  max-width: 36rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}
.py-1 {
  padding-bottom: .25rem;
  padding-top: .25rem;
}
.text-milestone-600{
  color: white;
}
.font-black {
  font-weight: 900;
}
.bg-milestone-100 {
  --tw-bg-opacity: 1;
  background-color: #edf8ff;
  background-color: #3498db;
}
.rounded-full {
  border-radius: 9999px;
}
.border-2 {
  border-width: 2px;
}
.gap-4{
  justify-content: initial;
}
</style>