<template>
  <v-app class="bg-white">
    <p>Loading...</p>
  </v-app>
</template>
<script>
import AppBarBlurAuth from "@/components/AppBarBlurAuth";
import { FadeTransition } from "vue2-transitions";

export default {
  name: "page-layout",
  components: {
    AppBarBlurAuth,
    FadeTransition,
  },
  data() {
    return {
       checkbox: false,
       showpassword : false,
       email : '',
      password : '',
      showPassword : false,
      dialog: false,
      pageFlag: 'init',
      forgot_flag : false,
      backgroundLogo: localStorage.getItem('admin_bg'),
      isDark :true,
      buttonFlag   : false,
      loadingLogo : false,
      url : '',
      company : '',
      loadingFlag : false,
      base_url_img : process.env.VUE_APP_WASBI_URL
    };
  },
  beforeCreate: function () {
    if (localStorage.getItem('token')) {
        if(localStorage.getItem('is_admin') && localStorage.getItem('is_admin') == 1)
            this.$router.push('/admin/slideshows')
        else
            this.$router.push('/dashboard')
    }
},
created: function () {
  this.checkForm(this.$route.params.id);
},
methods: {
gotohome: function () {
    let self = this;
    self.$router.push({ name: "Home" });
},
getCompanyInfo(url){
  axios({
          method: "POST",
           
          url: "/api/get-company-info",
          data: {
            url: url
          }
        })
          .then(response => {
            this.company = response.data.company;
            console.log(this.company);
            this.loadingFlag = true;
            this.loadingLogo = true;
          })

},
forgotPass(){
  if (!this.email) {
        Vue.$toast.error('Email is required', {
            position: 'top-right'
        });
        this.$refs['email'].focus()
        flag = 0;
      }else{
    this.buttonFlag =true;
    axios({
          method: "POST",
           
          url: "/api/auth/forgot-password-admin",
          data: {
            email: this.email,
            quantum : 1,
            frontend_url : this.url,
            company : this.company,
            company_flag : this.company ? true : false
          }
        })
          .then(response => {
                this.sendFlag = 1;
                Vue.$toast.success('Please check your email for change password', {
                position: 'top-right'
                });
                this.buttonFlag =false;
                this.forgot_flag = false;
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          })
        }
},
checkFormSignup(){
  let flag = 1;
      if (!this.email) {
        Vue.$toast.error('Email is required', {
            position: 'top-right'
        });
        this.$refs['email'].focus()
        flag = 0;
      }
      if (!this.password && flag == 1) {
        Vue.$toast.error('Password is required', {
            position: 'top-right'
        });
        this.$refs['password'].focus()
        flag = 0;
      }

      if (flag == 1) {
        localStorage.setItem('temp_email',this.email)
        localStorage.setItem('temp_pass',this.password)
        localStorage.setItem('email_verified',0)
        if(this.company)
          this.$router.push('/signup/'+this.url)
        else
          this.$router.push('/signup')
      }
},
// forgotPass(){
//     this.buttonFlag =true;
//     this.isDark = false;
//     axios({
//           method: "POST",
           
//           url: "/api/auth/forgot-password",
//           data: {
//             email: this.email,
//           }
//         })
//           .then(response => {
//                 this.sendFlag = 1;
//                 Vue.$toast.success('Forgot Password Mail sent', {
//                 position: 'top-right'
//                 });
//                 this.buttonFlag =false;
//                 this.isDark = true;
//                 this.pageFlag = 'login';
//           })
//           .catch(err => {
//             Vue.$toast.error(err, {
//             position: 'top-right'
//             });
//           })
// },
gotosignup: function () {
    let self = this;
    self.$router.push('/signup');
},gotoGoogle(){
      let self = this;
      window.location.replace(process.env.VUE_APP_API_URL+'redirect/google');
  },
  gotoMs(){
      let self = this;
        this.$router.push('/redirect/microsoft');
        location.reload();
  },
  gotoYahoo(){
      let self = this;
        this.$router.push('/redirect/yahoo');
        location.reload();
  },
/**
 * checkForm
 * login the user with valid email/pass
 */
 checkForm: function(token) {
      

        axios({
          method: "POST",
           
          url: "/api/auth/login-token",
          data: {
            token: token
          },
          headers : {
                Authorization:"Bearer " + token
                }
        })
          .then(response => {
            let data = response.data;
            if (data.flag == 1) {
              let result = data.data;
              
              localStorage.setItem("token", data.token.token);
              localStorage.setItem("user_id", result.user_id);
              localStorage.setItem("email", result.email);
              localStorage.setItem("phone", result.phone_m);
              localStorage.setItem("name", result.first_name+' '+result.last_name);
              localStorage.setItem("document_flag",response.data.doc_flag);
              localStorage.setItem("agreement_flag",result.agreement_flag);
              localStorage.setItem("is_admin",0);
                localStorage.setItem("role", JSON.stringify({name : 'client'}));
            //   console.log(response.data);
              if(localStorage.getItem('temp_request_id'))
                this.$router.push("/restart-process/"+localStorage.getItem('temp_request_id'));
              if(localStorage.getItem("agreement_flag") == 1)
                this.$router.push("/dashboard");
              else
                this.$router.push("/dashboard");
              location.reload();
            } else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          });
     
    },
  }
    
};
</script>
<style>
  .check-login .v-label {
    color: white;
  }
  .check-login .v-icon {
    color: white;
  }
.input-login-slide .v-icon{
    color:  white !important;
}
.input-login-new .v-label{
  color:  white !important;
}
.input-login-new #input-6{
  color:  white !important;
}
.input-login-new #input-10{
  color:  white !important;
}

.new-login-left {
  height: 100%;
  display: table;
  position: relative;
}
.new-login-left-box {
  display: table-cell;
  vertical-align: middle;
  height: 770px;
}
.new-login-left-form {
  max-width: 550px;
  width: 90%;
  margin: 30px auto 0;
  border: 1px solid #dedede;
  box-sizing: border-box;
}
.text-label{
  font-family: Arial,Helvetica,sans-serif!important;color: rgb(123, 128, 154) !important;font-weight: 400;
}
.default-color-code{
  color:  #3498db !important;
}
.score-header-1{
  color: rgb(255, 255, 255);
  line-height: 1.2;
  font-size: 13px;
  letter-spacing: 2px;
  font-weight: 800;
  font-family: "Poppins", Sans-serif;
}
.score-header-2{
  color: rgb(255, 255, 255);
  line-height: 1.2;
  font-size: 50px;
  letter-spacing: 2px;
  font-weight: 600;
  font-family: "Poppins", Sans-serif;
}
.e-hosted-video video {
  max-width: 100%;
width: 100%;
margin: 0;
line-height: 1;
border: none;
}
.header {
  position:relative;
  text-align:center;
  background: linear-gradient(60deg, rgba(84,58,183,1) 0%, rgba(0,172,193,1) 100%);
  color:white;
}
.logo {
  width:50px;
  fill:white;
  padding-right:15px;
  display:inline-block;
  vertical-align: middle;
}

.inner-header {
  height:65vh;
  width:100%;
  margin: 0;
  padding: 0;
}

.flex { /*Flexbox for containers*/
  display: flex;
  justify-content: center;
  text-align: center;
}

.waves {
  position:relative;
  width: 100%;
  height:15vh;
  margin-bottom:-7px; /*Fix for safari gap*/
  min-height:100px;
  max-height:150px;
}

.content {
  position:relative;
  height:20vh;
  text-align:center;
  background-color: white;
}

/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
   transform: translate3d(-90px,0,0);
  }
  100% { 
    transform: translate3d(85px,0,0);
  }
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height:40px;
    min-height:40px;
  }
  .content {
    height:30vh;
  }
  h1 {
    font-size:24px;
  }
}
.text-white {
  --tw-text-opacity: 1;
  color: #fff;
  color: rgba(255,255,255,var(--tw-text-opacity));
}
.font-light {
  font-weight: 300;
}
.text-center {
  text-align: center;
}
.max-w-xl {
  max-width: 36rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}
.py-1 {
  padding-bottom: .25rem;
  padding-top: .25rem;
}
.text-milestone-600{
  color: white;
}
.font-black {
  font-weight: 900;
}
.bg-milestone-100 {
  --tw-bg-opacity: 1;
  background-color: #edf8ff;
  background-color: #3498db;
}
.rounded-full {
  border-radius: 9999px;
}
.border-2 {
  border-width: 2px;
}
.gap-4{
  justify-content: initial;
}
</style>