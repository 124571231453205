<template>
  <v-app class="bg-white">
    <!-- <vue-header-no-login></vue-header-no-login> -->
    <v-main class="auth-pages ">
            <div class="header">
              
<!--Content before waves-->
              <div class="inner-header flex" style="z-index: 10000!important;position: relative;">
              <!--Just the logo.. Don't mind this-->
              <v-row >
                <v-col cols="12">
                    <div class="mt-10">
                        <img v-if="!company && loadingFlag" :height="this.$vuetify.breakpoint.xs ? 100 : 150" class="image-logo2" src="@/assets/img/logo-top.png"> 
                                  <img v-if="company && loadingFlag &&  company.logo" :height="this.$vuetify.breakpoint.xs ? 150 : 200" class="image-logo2" :src="base_url_img+company.logo">
                                  
                                  <h1 style="font-weight: 800;font-family: inherit;">Let's collaborate on creating Slideshows.</h1>
                    </div>
              </v-col>
              <v-col cols="12">
                <p class="max-w-xl mx-auto font-light text-center text-white md:text-lg">
                  Are you prepared to have one of our producers craft a personalized slideshow for you? You're just a few clicks away from owning a unique and custom slideshow crafted by a renowned montage expert.

                  </p>
                        <div  v-if="!forgot_flag" class="flex">

                                <div class="card-padding pb-4 login-div">
                              
                                  <v-text-field
                                    label="Email"
                                    color="white"
                                    outlined
                                    rounded
                                    ref="email"
                                    v-model="email"
                                    prepend-inner-icon="fa-envelope"
                                    dense
                                    class="font-size-input input-style py-0 input-login-slide"
                                  ></v-text-field>

                                  <v-text-field
                                    label="Password"
                                    color="white "
                                    prepend-inner-icon="fa-key"
                                    ref="password"
                                    outlined
                                    rounded
                                    v-model="password"
                                    dense
                                    :type="showpassword ? 'text' : 'password'"
                                    :append-icon="showpassword ? 'fa-eye' : 'fa-eye-slash'"
                                    @click:append="showpassword = !showpassword"
                                    class="font-size-input input-style py-0 input-login-slide"
                                  ></v-text-field>
                                  <v-btn
                                    elevation="0"
                                    :ripple="false"
                                    height="43"
                                    rounded
                                    :loading="buttonFlag"
                                    class="
                                      font-weight-bold
                                      text-uppercase
                                      btn-primary
                                      
                                      py-2
                                      px-6
                                      me-2
                                      mt-1
                                      mb-2
                                      w-100
                                    "
                                    color="#D59C5A"
                                    small
                                    style="margin-top: -40px;"
                                    v-on:click="checkForm"
                                    >Log in</v-btn
                                  >
                                  <v-btn
                                    elevation="0"
                                    :ripple="false"
                                    height="43"
                                    rounded
                                    :loading="buttonFlag"
                                    class="
                                      font-weight-bold
                                      text-uppercase
                                      btn-primary
                                      
                                      py-2
                                      px-6
                                      me-2
                                      mt-1
                                      mb-2
                                      w-100
                                    "
                                    color="#4081EC"
                                    small
                                    style=""
                                    v-on:click="gotoGoogle"
                                    ><v-icon class="me-2">fab fa-google-plus-g</v-icon> Sign in with Google</v-btn
                                  >
                                  <div class="text-center">
                                    <p class="text-sm text-body font-weight-light mt-3 mb-0" style="color:black !important;">No account yet? 
                                      <a
                                        @click="$router.push('/signup')"
                                        style="color: #4CAF50;"
                                        class="
                                            text-decoration-none
                                          font-weight-bold
                                        "
                                        >Sign up</a
                                      >
                                    </p>
                                  </div>
                                  <!-- <div class="text-center">
                                    <p class="text-sm text-body font-weight-light mt-3 mb-0" style="color:white !important;">
                                      <a
                                      style="color: white;"
                                        @click="forgot_flag = true"
                                        class="
                                            text-decoration-none
                                          font-weight-bold
                                        "
                                        >Forgot Password?</a
                                      >
                                    </p>
                                  </div> -->
                                </div>
                              </div>
                        <v-card class="new-login-left-form" outlined style="background-color:transparent;" v-if="forgot_flag">
                         
                         <div class="card-padding pb-0" style="text-align:center;" >
                                  
                                  <h2 class=" mb-2 text-label " style=" font-size: 25px;font-weight: 600;">Reset Password</h2>
                                  
                                </div>
                                <div class="card-padding pb-4">
                              
                                  <v-text-field
                                    label="Email"
                                    color="#3498db"
                                    outlined
                                    rounded
                                    v-model="email"
                                    prepend-inner-icon="fa-envelope"
                                    dense
                                    class="font-size-input input-style py-0 input-login-new"
                                  ></v-text-field>                                 
                                  <v-btn
                                    elevation="0"
                                    :ripple="false"
                                    height="43"
                                    rounded
                                    :loading="buttonFlag"
                                    class="
                                      font-weight-bold
                                      text-uppercase
                                      btn-primary
                                      
                                      py-2
                                      px-6
                                      me-2
                                      mt-7
                                      mb-2
                                      w-100
                                    "
                                    color="#3498db"
                                    small
                                    style="margin-top: -40px;"
                                    v-on:click="forgotPass"
                                    >Submit</v-btn
                                  >
                                  <v-btn
                                    elevation="0"
                                    :ripple="false"
                                    height="43"
                                    rounded
                                    class="
                                      font-weight-bold
                                      text-uppercase
                                      btn-primary
                                      
                                      py-2
                                      px-6
                                      me-2
                                      mt-7
                                      mb-2
                                      w-100
                                    "
                                    color="grey"
                                    small
                                    style="margin-top: -40px;"
                                    v-on:click="forgot_flag = false"
                                    >Back to login</v-btn
                                  >
                                 
                                </div>
                        </v-card>
                        </v-col>
                        <v-col cols="3" v-if="!this.$vuetify.breakpoint.xs"></v-col>
                        <v-col cols="12" md="6" lg="6">
                          <div :style="this.$vuetify.breakpoint.xs ? 'padding:56.25% 0 0 0;position:relative;margin-top: 80px;' : 'padding:56.25% 0 0 0;position:relative;margin-top: 180px;'"><iframe :src="video_src" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Home - ScoreZoom®"></iframe></div>
                        </v-col>
                        <v-col cols="3" v-if="!this.$vuetify.breakpoint.xs"></v-col>
                        <v-col cols="12">
                          <h1 class="pb-8 flex items-center default-color-code" style="font-weight: 800;font-family: inherit;">
                                Steps to Get Started
                              </h1>
                        </v-col>
                        <v-col cols="12" class="flex">
                          <div >
                          <div class="flex gap-4"><div class="inline-block px-3 py-1 text-lg font-black border-2 rounded-full border-milestone-500 text-milestone-600 bg-milestone-100">
                            1
                          </div> <div class="text-lg font-medium text-gray-600" style="color: #4B5563;padding-top: 4px ;padding-left: 8px;">
                            Sign up or Log in to your account
                          </div></div>

                          <div class="flex items-center gap-4 mt-5"><div class="inline-block px-3 py-1 text-lg font-black border-2 rounded-full border-milestone-500 text-milestone-600 bg-milestone-100">
                            2
                          </div> <div class="text-lg font-medium text-gray-600" style="color: #4B5563;padding-top: 4px ;padding-left: 8px;">
                            Initiate a New Slideshow
                          </div></div>

                          <div class="flex items-center gap-4 mt-5"><div class="inline-block px-3 py-1 text-lg font-black border-2 rounded-full border-milestone-500 text-milestone-600 bg-milestone-100">
                            3
                          </div> <div class="text-lg font-medium text-gray-600" style="color: #4B5563;padding-top: 4px ;padding-left: 8px;">
                            Provide details about your video
                          </div></div>

                          <div class="flex items-center gap-4 mt-5"><div class="inline-block px-3 py-1 text-lg font-black border-2 rounded-full border-milestone-500 text-milestone-600 bg-milestone-100">
                            4
                          </div> <div class="text-lg font-medium text-gray-600" style="color: #4B5563;padding-top: 4px ;padding-left: 8px;">
                            Include and organize your images and music
                          </div></div>

                          <div class="flex items-center gap-4 mt-5"><div class="inline-block px-3 py-1 text-lg font-black border-2 rounded-full border-milestone-500 text-milestone-600 bg-milestone-100">
                            5
                          </div> <div class="text-lg font-medium text-gray-600" style="color: #4B5563;padding-top: 4px ;padding-left: 8px;">
                            Review and submit your files
                          </div></div>
                          </div>
                        </v-col>
                        <v-col cols="12">
                          <p class="max-w-xl mt-5 mx-auto font-light text-center  md:text-lg" style="color: #4B5563;">
                            Stay informed throughout the creation of your slideshow, as our producer will provide updates at every stage, ensuring the delivery of your custom video in just a few days.

                            </p>
                            </v-col>
                            
                            <v-col cols="12" md="12">
                          <p class="max-w-xl mx-auto font-light text-center  md:text-lg" style="color: #4B5563;">
                            Have inquiries? Explore our How It Works page or reach out to us directly to connect with a slideshow producer.

                            </p>
                            </v-col>
                            <v-col cols="12">
                              <v-btn
                      :ripple="false"
                      :elevation="0"
                      :loading="loadingSaveButton"
                      class="
                                      font-weight-bolder
                                      btn-default
                                      mb-5
                                      mt-5
                                      mr-2
                                      px-8
                                      ms-auto
                                    "
                      color="#D59C5A"
                      href="tel://(888) 716-2449"
                    >
                    (888) 716-2449
                    </v-btn><v-btn
                      :ripple="false"
                      :elevation="0"
                      style="text-transform:none"
                      :loading="loadingSaveButton"
                      class="
                                      font-weight-bolder
                                      btn-default
                                      mb-5
                                      mt-5
                                      mr-2
                                      px-8
                                      ms-auto
                                    "
                      color="#D59C5A"
                      href="mailto:support@wecreate.vip"
                    >
                    support@wecreate.vip
                    </v-btn>
                            </v-col>
                            <v-col cols="12" md="12">
                          <p class="max-w-xl mx-auto font-light text-center  md:text-lg" style="color: #4B5563;">
                            Thank you for choosing We Create Slideshows. We're thrilled to be a part of creating extraordinary videos for your life's celebrations!

                            </p>
                            </v-col>
                            <v-col cols="12">
                              <div >
                                    <img v-if="!company && loadingFlag" :height="this.$vuetify.breakpoint.xs ? 100 : 150" class="image-logo2" src="@/assets/img/logo-top.png"> 
                                              <img v-if="company && loadingFlag &&  company.logo" :height="this.$vuetify.breakpoint.xs ? 150 : 200" class="image-logo2" :src="base_url_img+company.logo">
                                              
                                              <p class="max-w-xl mx-auto font-light text-center  md:text-lg" style="color: #4B5563;">
                                                Your Team of Slideshow Experts

                            </p>
                                </div>
                          </v-col>
                    </v-row>
                    </div>
              <!--Waves Container-->
              <div>
              <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
              <defs>
              <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
              </defs>
              <g class="parallax">
              <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
              <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
              <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
              <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" />
              </g>
              </svg>
              </div>
<!--Waves end-->
</div>
<!--Header ends-->
                
                          
    </v-main>
  </v-app>
</template>
<script>
import AppBarBlurAuth from "@/components/AppBarBlurAuth";
import { FadeTransition } from "vue2-transitions";

export default {
  name: "page-layout",
  components: {
    AppBarBlurAuth,
    FadeTransition,
  },
  data() {
    return {
       checkbox: false,
       showpassword : false,
       email : '',
      password : '',
      showPassword : false,
      dialog: false,
      video_src : "",
      pageFlag: 'init',
      forgot_flag : false,
      backgroundLogo: localStorage.getItem('admin_bg'),
      settings : localStorage.getItem('settings') ? JSON.parse(localStorage.getItem('settings')) : '',
      isDark :true,
      buttonFlag   : false,
      loadingLogo : false,
      url : '',
      company : '',
      loadingFlag : false,
      base_url_img : process.env.VUE_APP_WASBI_URL
    };
  },
  beforeCreate: function () {
    if (localStorage.getItem('token')) {
            this.$router.push('/dashboard')
    }
},
created: function () {
  if(!this.settings)
    this.getSettings()
  this.video_src = 'https://player.vimeo.com/video/'+this.settings.site_video+'?h=94cc90c0f7&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479';
  this.url = window.location.origin;//this.$route.params.url ? this.$route.params.url : '';console.log(this.$route)
  if(this.url)
      this.getCompanyInfo(this.url)
  else
      this.loadingFlag = true;
},
methods: {
  getSettings() {
    let flag = 1;
    let url = process.env.VUE_APP_WASBI_URL
    var config = {headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache'}};
      axios({
          method: "POST",
          url: "/api/get-company-settings",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
        })
        .then(response => {
            let data = response.data.data;
            if(data){
               localStorage.setItem('settings', JSON.stringify(data.settings));
               this.video_src = 'https://player.vimeo.com/video/'+data.settings.site_video+'?h=94cc90c0f7&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479';
               localStorage.setItem('admin_bg', JSON.stringify(url+data.settings.backgroung_logo));
            }


         })
          .catch(err => {
            // Vue.$toast.error(err, {
            // position: 'top-right'
            // });

           });

     },
gotohome: function () {
    let self = this;
    self.$router.push({ name: "Home" });
},
getCompanyInfo(url){
  axios({
          method: "POST",
           
          url: "/api/get-company-info",
          data: {
            url: url
          }
        })
          .then(response => {
            this.company = response.data.company;
            console.log(this.company);
            this.loadingFlag = true;
            this.loadingLogo = true;
          })

},
forgotPass(){
  if (!this.email) {
        Vue.$toast.error('Email is required', {
            position: 'top-right'
        });
        this.$refs['email'].focus()
        flag = 0;
      }else{
    this.buttonFlag =true;
    axios({
          method: "POST",
           
          url: "/api/auth/forgot-password-admin",
          data: {
            email: this.email,
            quantum : 1,
            frontend_url : this.url,
            company : this.company,
            company_flag : this.company ? true : false
          }
        })
          .then(response => {
                this.sendFlag = 1;
                Vue.$toast.success('Please check your email for change password', {
                position: 'top-right'
                });
                this.buttonFlag =false;
                this.forgot_flag = false;
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          })
        }
},
checkFormSignup(){
  let flag = 1;
      if (!this.email) {
        Vue.$toast.error('Email is required', {
            position: 'top-right'
        });
        this.$refs['email'].focus()
        flag = 0;
      }
      if (!this.password && flag == 1) {
        Vue.$toast.error('Password is required', {
            position: 'top-right'
        });
        this.$refs['password'].focus()
        flag = 0;
      }

      if (flag == 1) {
        localStorage.setItem('temp_email',this.email)
        localStorage.setItem('temp_pass',this.password)
        localStorage.setItem('email_verified',0)
        if(this.company)
          this.$router.push('/signup/'+this.url)
        else
          this.$router.push('/signup')
      }
},
// forgotPass(){
//     this.buttonFlag =true;
//     this.isDark = false;
//     axios({
//           method: "POST",
           
//           url: "/api/auth/forgot-password",
//           data: {
//             email: this.email,
//           }
//         })
//           .then(response => {
//                 this.sendFlag = 1;
//                 Vue.$toast.success('Forgot Password Mail sent', {
//                 position: 'top-right'
//                 });
//                 this.buttonFlag =false;
//                 this.isDark = true;
//                 this.pageFlag = 'login';
//           })
//           .catch(err => {
//             Vue.$toast.error(err, {
//             position: 'top-right'
//             });
//           })
// },
gotosignup: function () {
    let self = this;
    self.$router.push('/signup');
},gotoGoogle(){
      let self = this;
      window.location.replace(process.env.VUE_APP_API_URL+'redirect/google');
  },
  gotoMs(){
      let self = this;
        this.$router.push('/redirect/microsoft');
        location.reload();
  },
  gotoYahoo(){
      let self = this;
        this.$router.push('/redirect/yahoo');
        location.reload();
  },
/**
 * checkForm
 * login the user with valid email/pass
 */
 checkForm: function(e) {
      var loginData = {};
      var header = {};
      let flag = 1;
      if (!this.email) {
        Vue.$toast.error('Email is required', {
            position: 'top-right'
        });
        this.$refs['email'].focus()
        flag = 0;
      }
      if (!this.password && flag == 1) {
        Vue.$toast.error('Password is required', {
            position: 'top-right'
        });
        this.$refs['password'].focus()
        flag = 0;
      }

      if (flag == 1) {
        var email = this.email;
        axios({
          method: "POST",
           
          url: "/api/auth/login",
          data: {
            email: this.email,
            password: this.password,
          }
        })
          .then(response => {
            let data = response.data;
            if (data.flag == 1) {
              let result = data.data;
              
              localStorage.setItem("token", data.token.token);
              localStorage.setItem("user_id", result.user_id);
              localStorage.setItem("email", result.email);
              localStorage.setItem("phone", result.phone_m);
              localStorage.setItem("name", result.first_name+' '+result.last_name);
              localStorage.setItem("document_flag",response.data.doc_flag);
              localStorage.setItem("agreement_flag",result.agreement_flag);
              localStorage.setItem("is_admin",0);
                localStorage.setItem("role", JSON.stringify({name : 'client'}));
            //   console.log(response.data);
              if(localStorage.getItem('temp_request_id'))
                this.$router.push("/restart-process/"+localStorage.getItem('temp_request_id'));
              if(localStorage.getItem("agreement_flag") == 1)
                this.$router.push("/dashboard");
              else
                this.$router.push("/dashboard");
              location.reload();
            } else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          });
      } else {

      }
    },
  }
    
};
</script>
<style>
  .check-login .v-label {
    color: white;
  }
  .check-login .v-icon {
    color: white;
  }
.input-login-slide .v-icon{
    color:  white !important;
}
.input-login-new .v-label{
  color:  white !important;
}
.input-login-new #input-6{
  color:  white !important;
}
.input-login-new #input-10{
  color:  white !important;
}
.login-div .font-size-input .v-label {
  color: white!important;
}
.new-login-left {
  height: 100%;
  display: table;
  position: relative;
}
.new-login-left-box {
  display: table-cell;
  vertical-align: middle;
  height: 770px;
}
.new-login-left-form {
  max-width: 550px;
  width: 90%;
  margin: 30px auto 0;
  border: 1px solid #dedede;
  box-sizing: border-box;
}
.text-label{
  font-family: Arial,Helvetica,sans-serif!important;color: rgb(123, 128, 154) !important;font-weight: 400;
}
.default-color-code{
  color:  #3498db !important;
}
.score-header-1{
  color: rgb(255, 255, 255);
  line-height: 1.2;
  font-size: 13px;
  letter-spacing: 2px;
  font-weight: 800;
  font-family: "Poppins", Sans-serif;
}
.score-header-2{
  color: rgb(255, 255, 255);
  line-height: 1.2;
  font-size: 50px;
  letter-spacing: 2px;
  font-weight: 600;
  font-family: "Poppins", Sans-serif;
}
.e-hosted-video video {
  max-width: 100%;
width: 100%;
margin: 0;
line-height: 1;
border: none;
}
.header {
  position:relative;
  text-align:center;
  background: linear-gradient(60deg, rgba(84,58,183,1) 0%, rgba(0,172,193,1) 100%);
  color:white;
}
.logo {
  width:50px;
  fill:white;
  padding-right:15px;
  display:inline-block;
  vertical-align: middle;
}

.inner-header {
  height:65vh;
  width:100%;
  margin: 0;
  padding: 0;
}

.flex { /*Flexbox for containers*/
  display: flex;
  justify-content: center;
  text-align: center;
}

.waves {
  position:relative;
  width: 100%;
  height:15vh;
  margin-bottom:-7px; /*Fix for safari gap*/
  min-height:100px;
  max-height:150px;
}

.content {
  position:relative;
  height:20vh;
  text-align:center;
  background-color: white;
}

/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
   transform: translate3d(-90px,0,0);
  }
  100% { 
    transform: translate3d(85px,0,0);
  }
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height:40px;
    min-height:40px;
  }
  .content {
    height:30vh;
  }
  h1 {
    font-size:24px;
  }
}
.text-white {
  --tw-text-opacity: 1;
  color: #fff;
  color: rgba(255,255,255,var(--tw-text-opacity));
}
.font-light {
  font-weight: 300;
}
.text-center {
  text-align: center;
}
.max-w-xl {
  max-width: 36rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}
.py-1 {
  padding-bottom: .25rem;
  padding-top: .25rem;
}
.text-milestone-600{
  color: white;
}
.font-black {
  font-weight: 900;
}
.bg-milestone-100 {
  --tw-bg-opacity: 1;
  background-color: #edf8ff;
  background-color: #3498db;
}
.rounded-full {
  border-radius: 9999px;
}
.border-2 {
  border-width: 2px;
}
.gap-4{
  justify-content: initial;
}
</style>