<template>
<v-app id="inspire">

<v-main style="padding:0px!important;margin-top: 50px;" >
    <v-container fluid style="margin-bottom:150px;" class="px-6 py-6">
        <v-row
            align="center"
            style="margin-bottom: 80px!important;"
            justify="center"
          >
            <v-col cols="12" md="10" class="mt-10">
              
            <h1 class=" mb-5" style="color: rgba(0, 0, 0, 0.6);">Choose Profile</h1>
            <v-divider></v-divider>
            </v-col>
            <v-col cols="12" md="10" class="mt-10">
              <h3 style="text-align:center;color: rgba(0, 0, 0, 0.6);" class=" mb-5">You have multiple profiles with your same email address. Choose a profile to log into. </h3>
            </v-col>
            <v-col align="center"
            justify="center" cols="12" md="10" >
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-card
                          :loading="loading"
                          width="300"
                          elevation="2"
                          height="300"
                          :style="!this.$vuetify.breakpoint.xs ? 'float:right;' : ''"
                        >

                     
                          <h1 class="mt-4 mb-4" style="color: rgba(0, 0, 0, 0.6);">Client</h1>

                          <v-divider class="mx-4"></v-divider>
                          <v-avatar color="indigo" class="mt-5 mb-3">
                                <v-icon class="material-icons-round" dark style="  font-size: 35px;">
                                  person
                                </v-icon>
                              </v-avatar>
                          <v-card-title style="justify-content: center;">Login as Client</v-card-title>

                          <v-card-actions class="mt-2">
                            <v-btn
                              class="
                                font-weight-bold
                                text-uppercase
                                btn-primary
                                
                                py-2
                                px-6
                                me-2
                                mt-7
                                mb-2
                                w-100
                              "
                              color="#4AA64E"
                              @click="loginClient"
                            >
                              Continue
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-col> 
                      <v-col cols="12" md="6">
                     <v-card
                          :loading="loading"
                          width="300"
                          elevation="2"
                          height="300"
                          :style="!this.$vuetify.breakpoint.xs ? 'float:left;' : ''"
                        >

                     
                          <h1 class="mt-4 mb-4" style="color: rgba(0, 0, 0, 0.6);">Company</h1>

                          <v-divider class="mx-4"></v-divider>
                          <v-avatar color="teal" class="mt-5 mb-3">
                                <v-icon class="material-icons-round" dark style="  font-size: 35px;">
                                  domain
                                </v-icon>
                              </v-avatar>
                          <v-card-title style="justify-content: center;">Login as Company</v-card-title>

                          <v-card-actions class="mt-2">
                            <v-btn
                              class="
          font-weight-bold
          text-uppercase
          btn-primary
          
          py-2
          px-6
          me-2
          mt-7
          mb-5
          w-100
        "
        color="#4AA64E"
                           @click="loginCompany"
                            >
                              Continue
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                        
                      </v-col> 
                  </v-row>
            </v-col>
          </v-row>
    </v-container>
    
     </v-main>
</v-app>

</template>

<script>
import { VueTelInput } from 'vue-tel-input'
export default {
components: {
      VueTelInput,
  },
  data () {

      return {
        fname : '',
        lname : '',
        email : '',
        name:'',
        phone:'',
        password : '',
        is_admin:0,
        confpassword : '',
        role : '',
        user: [],
        companyname : '',
        sector : '',
        isDark: true,
        loading:false,
        isDisable :false,
        no_of_employee : '',
        showPassword : false,
        file_flag : {
          agreement : {
                          name : '',
                          id :''
                        },
          id :{
                          name : '',
                          id :''
                        },
          bill :{
                          name : '',
                          id :''
                        },
          letter_a : {
                          name : '',
                          id :''
                        },
          letter_b : {
                          name : '',
                          id :''
                        },
        },
        phoneFlag:0,
        emailFlag:0,
        files : {
          agreement : [],
          id : [],
          bill:[],
          letter_a : [],
          letter_b : []
        },
        country :'',
        otp_email:'',
        otp_phone:'',
        company : {
          name: "",
          contact_email: "",
          contact_phone: "",
          details:"",
          email: "",
          password: "",
          password_confirmation: ""

        },
        otp_phone_input:"",
        otp_email_input:"",
        user_phone:'',
        user_email:'',
        status:0,

        tab: null,
        headers: [

          { text: '', value: 'CompanyURL' },
          { text: '', value: 'CompanyName' },

        ],
        history: [

        ],

        setting: [
        ],

        items: [
              'Admin',
              'Viewer',
      ],
      select_role : 'Admin',
       sectors: [

      ],
      employeeNumber: [
              '10-50',
              '50-100',
              'More than 100',

      ],

      }
    },

    methods: {
loginClient: function(e) {
      var loginData = {};
      var header = {};
      let flag = 1;
      this.email = localStorage.getItem('logged_in_email');
      this.password = atob(localStorage.getItem('logged_in_password'));

      if (flag == 1) {
        var email = this.email;
        axios({
          method: "POST",
           
          url: "/api/auth/login",
          data: {
            email: this.email,
            password: this.password,
            logged_in : true
          }
        })
          .then(response => {
            let data = response.data;
            if (data.flag == 1) {
              let result = data.data;
              
              localStorage.setItem("token", data.token.token);
              localStorage.setItem("user_id", result.user_id);
              localStorage.setItem("email", result.email);
              localStorage.setItem("name", result.first_name+' '+result.last_name);
              localStorage.setItem("document_flag",response.data.doc_flag);
              localStorage.setItem("agreement_flag",result.agreement_flag);
              localStorage.setItem("is_admin",0);
              localStorage.setItem('logged_in_email','')
              localStorage.setItem('logged_in_password','')
                localStorage.setItem("role", JSON.stringify({name : 'client'}));
            //   console.log(response.data);
              if(localStorage.getItem('temp_request_id'))
                this.$router.push("/restart-process/"+localStorage.getItem('temp_request_id'));
              if(localStorage.getItem("agreement_flag") == 1)
                this.$router.push("/dashboard");
              else
                this.$router.push("/dashboard");
              location.reload();
            } else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          });
      } else {

      }
    },
    loginCompany: function(e) {
      var loginData = {};
      var header = {};
      let flag = 1;
      this.email = localStorage.getItem('logged_in_email');
      this.password = atob(localStorage.getItem('logged_in_password'));
      if (flag == 1) {
        var email = this.email;
        axios({
          method: "POST",
           
          url: "/api/auth/login-admin",
          data: {
            email: this.email,
            password: this.password,
          }
        })
          .then(response => {
            let data = response.data;
            if (data.flag == 1) {
              let result = data.data;
              localStorage.setItem("token", data.token.token);
              localStorage.setItem("user_id", result.user_id);
              localStorage.setItem("first_name", result.first_name);
              localStorage.setItem("email", result.email);
              localStorage.setItem("is_admin", result.is_admin);
              localStorage.setItem("name", result.first_name+' '+result.last_name);
              localStorage.setItem("role", JSON.stringify(result.roles[0]));
              localStorage.setItem("company_name", result.company_name);
              localStorage.setItem('company_logo',result.logo && result.logo !=null && result.logo != 'null' ? process.env.VUE_APP_WASBI_URL+result.logo : '');
              localStorage.setItem("company_id", result.company_id);
              localStorage.setItem('logged_in_email','')
              if( JSON.stringify(result.roles[0]).name != 'super_admin')
                localStorage.setItem("agreement_flag", result.agreement);
              localStorage.setItem('logged_in_password','')
              if(result.agreement == 0 && result.roles[0].name != 'super_admin')
                this.$router.push("/admin/agreement");
              else
                this.$router.push("/admin/slideshows");
              location.reload();
            } else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          });
      } else {

      }
    },

 },

created() {
      console.log('created called.');
      this.getUserProfile();
    },

}
</script>

